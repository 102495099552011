import React from "react";

const RightArrow = ({ stroke = "#0B1C83", fill = "none", width="8", height="8", style }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      
    >
      <path
        d="M2.97021 6.64035L5.14355 4.46702C5.40022 4.21035 5.40022 3.79035 5.14355 3.53369L2.97021 1.36035"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        
      />

    </svg>
  );
};

export default RightArrow;
