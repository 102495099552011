import React, { useState, useEffect } from "react";
import "./styles/dash-departments.css";
import Employees from "./assets/LearningHistory";
import BackIcon from "./assets/BackIcon";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ProgressBar from "../ProgressBar";
import {
  getAllDepartmentsFn,
  getDepartmentEmployeesFn,
  getDepartmentSkillGapsFn,
  getAnalyticsDepartmentFn,
} from "../../store/actions/admin/departments";
import { useDispatch, useSelector } from "react-redux";
import Paginator from "../Paginator/Paginator";
import teams from "./assets/team";

const Departments = ({
  display,
  oneAss01,
  oneAss02,
  oneAss03,
  oneAss04,
  currentUser,
  deptId,
  removeDeptId,
}) => {
  const dispatch = useDispatch();
  const [individualDepartment, setIndividualDepartment] = useState({});

  const {
    allDepartments,
    departmentEmployees,
    departmentSkillGaps,
    totalDepartmentEmployees,
    totalNumberOfSkillGaps,
    analytics,
  } = useSelector((state) => ({
    allDepartments: state?.departments?.allDepartments,
    departmentEmployees: state.employees.departmentEmployees,
    departmentSkillGaps: state.departments.departmentSkillGaps,
    totalDepartmentEmployees: state.employees.totalDepartmentEmployees,
    totalNumberOfSkillGaps: state.departments.totalNumberOfSkillGaps,
    analytics: state.departments.analytics,
  }));

  const [viewIndividualDepartment, setViewIndividualDepartment] =
    useState(false);

  useEffect(() => {
    if (deptId) {
      const ind = allDepartments.find((e) => e._id == deptId);
      setViewIndividualDepartment(true);
      setIndividualDepartment(ind);
    } else {
      setViewIndividualDepartment(false);
    }
  }, [deptId]);

  useEffect(() => {
    dispatch(getAllDepartmentsFn(currentUser?.user?.company));
  }, [currentUser, dispatch]);

  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(totalDepartmentEmployees);
  const [tableData, setTableData] = useState([]);

  // For Missing Skills Table

  const [missingCurrPage, setMissingCurrPage] = useState(1);
  const [missingTotalPages, setMissingTotalPages] = useState(
    totalNumberOfSkillGaps
  );
  const [missingTableData, setMissingTableData] = useState([]);

  const limit = 5;

  useEffect(() => {
    const transformedDepartmentEmployees = departmentEmployees?.map((obj) => {
      if (obj.hasOwnProperty("item")) {
        const { item, ...rest } = obj;
        return { ...rest, team: item.team?.name ? item.team.name : "No team" };
      } else {
        return { ...obj, team: obj?.team?.name ? obj.team.name : "No team" };
      }
    });
    setTotalPages(Math.round(totalDepartmentEmployees / limit));
    setTableData(transformedDepartmentEmployees);
  }, [departmentEmployees, totalDepartmentEmployees]);

  const handleSearch = (search) => {
    const page = 1;

    dispatch(
      getDepartmentEmployeesFn(
        currentUser?.user?.company,
        individualDepartment?._id,
        limit,
        page,
        search
      )
    );

    setCurrPage(page);
  };

  useEffect(() => {
    setMissingTotalPages(
      Math.round(totalNumberOfSkillGaps / totalNumberOfSkillGaps)
    );
    setMissingTableData(departmentSkillGaps);
  }, [departmentSkillGaps, totalNumberOfSkillGaps]);

  const handleSkillGapsSearch = (search) => {
    const page = 1;
    dispatch(
      getDepartmentSkillGapsFn(
        currentUser?.user?.company,
        individualDepartment?._id,
        limit,
        page,
        search
      )
    );
  };

  // Analytics

  const data = [
    {
      label: "Monday",
      skill: 10,
      timeLearning: 20,
      resourceCount: 50,
      skillGap: 70,
    },
    {
      label: "Tuesday",
      skill: 30,
      timeLearning: 15,
      resourceCount: 25,
      skillGap: 45,
    },
    {
      label: "Wednesday",
      skill: 20,
      timeLearning: 90,
      resourceCount: 100,
      skillGap: 75,
    },
    {
      label: "Thursday",
      skill: 27,
      timeLearning: 39,
      resourceCount: 20,
      skillGap: 58,
    },
    {
      label: "Friday",
      skill: 18,
      timeLearning: 48,
      resourceCount: 22,
      skillGap: 25,
    },
    {
      label: "Saturday",
      skill: 29,
      timeLearning: 38,
      resourceCount: 45,
      skillGap: 65,
    },
    {
      label: "Sunday",
      skill: 35,
      timeLearning: 43,
      resourceCount: 21,
      skillGap: 25,
    },
  ];

  const learningGrid = [
    {
      mainText: "No of skills acquired",
      percentage: 60,
      fillColor: "#FF3A29",
      subText: "skills",
      image: oneAss01,
      skill: "Engineering",
      skillSubText: "Top Department",
      id: "skill",
    },

    {
      mainText: "No of skill gaps",
      percentage: 40,
      fillColor: "#FFB200",
      subText: "skill gaps",
      image: oneAss02,
      skill: "Marketing",
      skillSubText: "Top Department",
      id: "skillGap",
    },

    {
      mainText: "Learning resources consumed",
      percentage: 40,
      fillColor: "#4339F2",
      subText: "resources",
      image: oneAss03,
      skill: "Product",
      skillSubText: "Top Department",
      id: "resourceCount",
    },

    {
      mainText: "Time spent Learning",
      percentage: 20,
      fillColor: "#34B53A",
      subText: "minutes",
      image: oneAss04,
      skill: "Sales",
      skillSubText: "Top Department",
      id: "timeLearning",
    },
  ];

  const [currentPeriod, setCurrentPeriod] = useState("week");
  const [frame, setFrame] = useState(false);
  const [err, setErr] = useState(false);
  const [currentData, setCurrentData] = useState(data);
  const [learnGrid, setLearnGrid] = useState(learningGrid);
  const [skill, setSkill] = useState(true);
  const [time, setTime] = useState(true);
  const [gap, setGap] = useState(true);
  const [res, setRes] = useState(true);

  useEffect(() => {
    if (individualDepartment?._id) {
      dispatch(
        getAnalyticsDepartmentFn(
          currentPeriod,
          currentUser?.user?.company,
          individualDepartment._id,
          frame
        )
      );
    }
  }, [currentPeriod, frame, individualDepartment, currentUser, dispatch]);

  useEffect(() => {
    if (Array.isArray(analytics)) {
      if (analytics.length === 0) {
        setCurrentData([]);
      } else {
        setCurrentData(analytics);
        var lastLearning = analytics[analytics.length - 1].lastPerformance;
      }
      setErr(false);
    } else {
      setCurrentData([]);
      setErr(true);
      lastLearning = analytics;
    }
    if (lastLearning) {
      const learnGridNew = learningGrid.map((lg) => {
        lg.subText = `${Math.round(lastLearning[lg.id].value)} ${lg.subText}`;
        lg.percentage = lastLearning[lg.id].percent;
        return lg;
      });
      setLearnGrid(learnGridNew);
    }
  }, [analytics]);

  const [viewSkillGaps, setViewSkillGaps] = useState(false);

  const LearningSummaryCards = ({
    mainText,
    percentage,
    fillColor,
    subText,
    image,
    skill,
    skillSubText,
    reminders = false,
  }) => {
    return (
      <div className="individualBoxes">
        <div>
          <p>
            <strong>{mainText}</strong>
          </p>
          <p
            style={{
              color: fillColor,
            }}
          >
            {subText}
          </p>
        </div>

        <ProgressBar percentage={percentage} fillColor={fillColor} />
      </div>
    );
  };

  const rawColumns = [
    {
      name: "First Name",
    },

    {
      name: "Last Name",
    },

    {
      name: "Team",
    },

    {
      name: "Tag",
    },
  ];

  const missingSkillsColumns = [
    {
      name: "Name",
    },

    {
      name: "Role",
    },

    {
      name: "No. of employees without this skill",
    },
  ];

  const TableComponent = ({
    columns,
    totalPages,
    tableData,
    handlePaginationClick,
    search,
  }) => {
    const [searchText, setSearchText] = useState("");
    return (
      <div className="table-wrapper">
        {search ? (
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <input
              type="text"
              placeholder="Search for employees"
              value={searchText}
              className="table-search"
              onChange={(e) => setSearchText(e.target.value)}
            />

            <button
              style={{
                textAlign: "center",
                height: "32px",
                border: "none",
                outline: "none",
                backgroundColor: "#18a0fb",
                padding: "0 20px",
                borderRadius: "4px",
                color: "white",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              onClick={() => {
                handlePaginationClick(searchText);
              }}
            >
              Enter
            </button>
          </div>
        ) : null}

        <table className="table">
          <thead>
            <tr>
              {columns?.map((column, index) => {
                return <th>{column?.name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row?.firstName}</td>
                  <td>{row?.lastName}</td>
                  <td>{row?.team}</td>
                  <td>{row?.tag}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Paginator
          currentPage={currPage}
          lastPage={totalPages > currPage ? totalPages : currPage}
          onPageChange={(page) => {
            setCurrPage(page);
            dispatch(
              getDepartmentEmployeesFn(
                currentUser?.user?.company,
                individualDepartment?._id,
                limit,
                page
              )
            );
          }}
        />
      </div>
    );
  };

  const MissingSkillTableComponent = ({
    columns,
    totalPages,
    tableData,
    handlePaginationClick,
    search,
  }) => {
    const [searchText, setSearchText] = useState("");
    return (
      <div className="table-wrapper">
        {search ? (
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              type="text"
              placeholder="Search for employees"
              value={searchText}
              className="table-search"
              onChange={(e) => setSearchText(e.target.value)}
            />

            <div
              className="addButton"
              onClick={() => {
                handlePaginationClick(searchText);
              }}
            >
              Enter
            </div>
          </div>
        ) : null}

        <table className="table">
          <thead>
            <tr>
              {columns?.map((column, index) => {
                return <th>{column?.name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row?.name}</td>
                  <td>{row?.role}</td>
                  <td>{row?.count}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Paginator
          currentPage={missingCurrPage}
          lastPage={missingCurrPage}
          onPageChange={(page) => {
            setMissingCurrPage(page);
            dispatch(
              getDepartmentSkillGapsFn(
                currentUser?.user?.company,
                individualDepartment?._id,
                limit,
                page
              )
            );
          }}
        />
      </div>
    );
  };

  const TeamComponent = ({ dept, index }) => {
    const color = teams[dept.image].code;
    const Icon = teams[dept.image].icon;
    return (
      <div
        className="departmentGrid"
        key={index}
        onClick={() => {
          setViewIndividualDepartment(!viewIndividualDepartment);
          setIndividualDepartment(dept);
        }}
      >
        <div className="departmentFlex">
          <div className="departmentIcon" style={{ backgroundColor: color }}>
            <Icon />
          </div>

          <div className="departmentDetails">
            <p className="departmentName">{dept?.name}</p>
          </div>
        </div>

        <div className="departmentStaffDetails">
          <div>
            <p>No of employees</p>
            <p>{dept?.count}</p>
          </div>

          <Employees stroke="#18A0FB" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (Object.keys(individualDepartment).length !== 0) {
      dispatch(
        getDepartmentEmployeesFn(
          currentUser?.user?.company,
          individualDepartment?._id,
          limit,
          currPage
        )
      );
      dispatch(
        getDepartmentSkillGapsFn(
          currentUser?.user?.company,
          individualDepartment?._id
        )
      );
    }
  }, [currentUser, individualDepartment]);

  const Icon = ({ name }) => {
    const color = teams[name].code;
    const Icon = teams[name].icon;
    return (
      <div className="departmentIcon" style={{ backgroundColor: color }}>
        <Icon />
      </div>
    );
  };

  useEffect(() => {
    console.log("Department Employees", departmentEmployees);
    console.log("Department Skill Gaps", departmentSkillGaps);
  }, [departmentEmployees, departmentSkillGaps]);
  return (
    <>
      <div
        className={`one-home depts ${display === "departments" ? "" : "hide"}`}
        onClick={() => removeDeptId}
      >
        {!viewIndividualDepartment ? (
          <div className="departments">
            <p className="allDepartments">All Departments</p>

            {allDepartments?.length > 0 ? (
              <div className="departmentsGrid">
                {allDepartments?.map((department, index) => (
                  <TeamComponent dept={department} index={index} />
                ))}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        ) : !viewSkillGaps ? (
          <div className="main deptInfo">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                setViewIndividualDepartment(!viewIndividualDepartment)
              }
            >
              <BackIcon stroke={"#1C2533"} fill={"#1C2533"} />
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#434F61",
                }}
              >
                Back
              </p>
            </div>

            <div className="departments">
              <div className="departmentsGrid">
                <div className="departmentGrid">
                  <div className="departmentFlex">
                    <Icon name={individualDepartment?.image} />

                    <div className="departmentDetails">
                      <p className="departmentName">All employees</p>
                      <p className="departmentEmployeeDetail">
                        Across {individualDepartment?.name} department
                      </p>
                    </div>
                  </div>

                  <div className="departmentStaffDetails">
                    <div>
                      <p>{individualDepartment?.count}</p>
                    </div>

                    <Employees stroke="#18A0FB" />
                  </div>
                </div>

                <div
                  className="departmentGrid"
                  onClick={() => {
                    setViewSkillGaps(!viewSkillGaps);
                    window.scrollTo({
                      top: 0,
                    });
                  }}
                >
                  <div className="departmentFlex">
                    <Icon name="error" />

                    <div className="departmentDetails">
                      <p className="departmentName">Skill Gaps</p>
                      <p className="departmentEmployeeDetail">Top deparment</p>
                    </div>
                  </div>

                  <div className="departmentStaffDetails">
                    <div>
                      <p>{departmentSkillGaps.length}</p>
                    </div>

                    <Employees stroke="#18A0FB" />
                  </div>
                </div>
              </div>
            </div>

            <div className="learning-summary">
              <div className="learning-summary-header">
                <p>Learning Summary</p>

                <div className="side-prof">
                  <div className="switch gree">
                    <p>Skills</p>
                    <input
                      type="checkbox"
                      id="skillDept"
                      name="skill"
                      onChange={() => setSkill(!skill)}
                    />
                    <label class="ios-checkbox" for="skillDept"></label>
                  </div>
                  <div className="switch gree">
                    <p>Time</p>
                    <input
                      type="checkbox"
                      id="timeDept"
                      name="time"
                      onChange={() => setTime(!time)}
                    />
                    <label class="ios-checkbox" for="timeDept"></label>
                  </div>
                  <div className="switch gree">
                    <p>Skill gap</p>
                    <input
                      type="checkbox"
                      id="gapDept"
                      name="gap"
                      onChange={() => setGap(!gap)}
                    />
                    <label class="ios-checkbox" for="gapDept"></label>
                  </div>
                  <div className="switch gree">
                    <p>Resources</p>
                    <input
                      type="checkbox"
                      id="resDept"
                      name="res"
                      onChange={() => setRes(!res)}
                    />
                    <label class="ios-checkbox" for="resDept"></label>
                  </div>
                  <select
                    name="duration"
                    id="time-periods"
                    value={`${frame}.${currentPeriod}`}
                    onChange={(e) => {
                      setCurrentPeriod(e.target.value.split(".")[1]);
                      setFrame(JSON.parse(e.target.value.split(".")[0]));
                      setErr(false);
                    }}
                  >
                    <option value="true.week">Last Week</option>
                    <option value="false.week">This Week</option>
                    <option value="true.month">Last Month</option>
                    <option value="false.month">This Month</option>
                    <option value="true.year">Last Year</option>
                    <option value="false.year">This Year</option>
                  </select>
                </div>
              </div>
              {err && (
                <div className="notice">
                  <p>
                    No Available Data for {frame ? "last" : "this"}{" "}
                    {currentPeriod}. No learning took place{" "}
                    {frame ? "last" : "this"} {currentPeriod}
                  </p>
                </div>
              )}
              {currentData ? (
                <ResponsiveContainer width="100%" height={450}>
                  <LineChart
                    width={1000}
                    height={450}
                    data={currentData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <Tooltip />
                    {time && (
                      <Line
                        type="monotone"
                        dataKey="timeLearning"
                        stroke="#FFB200"
                        activeDot={{ r: 8 }}
                      />
                    )}
                    {skill && (
                      <Line type="monotone" dataKey="skill" stroke="#4339F2" />
                    )}
                    {res && (
                      <Line
                        type="monotone"
                        dataKey="resourceCount"
                        stroke="#FF3A29"
                      />
                    )}
                    {gap && (
                      <Line
                        type="monotone"
                        dataKey="skillGap"
                        stroke="#18A0FB"
                      />
                    )}
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                  }}
                >
                  No Data Available
                </p>
              )}

              <div className="learning-summary-grid">
                {learnGrid?.map((data, index) => {
                  return (
                    <LearningSummaryCards
                      mainText={data?.mainText}
                      fillColor={data?.fillColor}
                      percentage={data?.percentage}
                      subText={data?.subText}
                      image={data?.image}
                      skill={data?.skill}
                      skillSubText={data?.skillSubText}
                      index={index}
                    />
                  );
                })}
              </div>
            </div>

            {tableData?.length > 0 ? (
              <div className="table-background">
                <TableComponent
                  columns={rawColumns}
                  tableData={tableData}
                  totalPages={totalPages}
                  handlePaginationClick={handleSearch}
                  search={true}
                />
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="main deptInfo">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setViewSkillGaps(!viewSkillGaps);
                window.scrollTo({
                  top: 0,
                });
              }}
            >
              <BackIcon stroke={"#1C2533"} fill={"#1C2533"} />
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#434F61",
                }}
              >
                Back
              </p>
            </div>

            <div className="departments">
              <div className="departmentsGrid">
                <div className="departmentGrid">
                  <div className="departmentFlex">
                    <Icon name="error" />

                    <div className="departmentDetails">
                      <p className="departmentName">Skill Gaps</p>
                      <p className="departmentEmployeeDetail">
                        {individualDepartment?.name} Department
                      </p>
                    </div>
                  </div>

                  <div className="departmentStaffDetails">
                    <div>
                      <p>{departmentSkillGaps.length}</p>
                    </div>

                    <Employees stroke="#18A0FB" />
                  </div>
                </div>

                <div className="departmentGrid">
                  <div className="departmentFlex">
                    <Icon name="product" />

                    <div className="departmentDetails">
                      <p className="departmentName">Missing Skills</p>
                      <p className="departmentEmployeeDetail">
                        {/* Top deparment */}
                      </p>
                    </div>
                  </div>

                  <div className="departmentStaffDetails">
                    <div>
                      <p>20</p>
                    </div>

                    <Employees stroke="#18A0FB" />
                  </div>
                </div>
              </div>
            </div>

            <div className="learning-summary">
              <div className="learning-summary-header">
                <p>Skill Gaps</p>
              </div>

              <MissingSkillTableComponent
                columns={missingSkillsColumns}
                tableData={missingTableData}
                totalPages={missingTotalPages}
                handlePaginationClick={handleSkillGapsSearch}
                search={false}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Departments;
