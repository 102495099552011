

import React from "react";


const Billing = () => {
    return (
        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.25C0 4.55381 0.210714 3.88613 0.585786 3.39384C0.960859 2.90156 1.46957 2.625 2 2.625H14C14.5304 2.625 15.0391 2.90156 15.4142 3.39384C15.7893 3.88613 16 4.55381 16 5.25V15.75C16 16.4462 15.7893 17.1139 15.4142 17.6062C15.0391 18.0984 14.5304 18.375 14 18.375H2C1.46957 18.375 0.960859 18.0984 0.585786 17.6062C0.210714 17.1139 0 16.4462 0 15.75V5.25ZM2 3.9375C1.73478 3.9375 1.48043 4.07578 1.29289 4.32192C1.10536 4.56806 1 4.9019 1 5.25V6.5625H15V5.25C15 4.9019 14.8946 4.56806 14.7071 4.32192C14.5196 4.07578 14.2652 3.9375 14 3.9375H2ZM15 9.1875H1V15.75C1 16.0981 1.10536 16.4319 1.29289 16.6781C1.48043 16.9242 1.73478 17.0625 2 17.0625H14C14.2652 17.0625 14.5196 16.9242 14.7071 16.6781C14.8946 16.4319 15 16.0981 15 15.75V9.1875Z" fill="#8D9091" />
            <path d="M2 13.125C2 12.7769 2.10536 12.4431 2.29289 12.1969C2.48043 11.9508 2.73478 11.8125 3 11.8125H4C4.26522 11.8125 4.51957 11.9508 4.70711 12.1969C4.89464 12.4431 5 12.7769 5 13.125V14.4375C5 14.7856 4.89464 15.1194 4.70711 15.3656C4.51957 15.6117 4.26522 15.75 4 15.75H3C2.73478 15.75 2.48043 15.6117 2.29289 15.3656C2.10536 15.1194 2 14.7856 2 14.4375V13.125Z" fill="#8D9091" />
        </svg>

    )
};

export default Billing;