import React from "react";

const BackIcon = ({stroke = "#1C2533", fill="#434F61"}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.53265 6.04095L7.53355 6.04005L9.67355 3.90005C9.81282 3.76078 10.0467 3.85993 10.0467 4.05317V8.2065V11.9465C10.0467 12.138 9.80932 12.2354 9.67355 12.0996L6.22021 8.64628C5.86229 8.28836 5.86214 7.70528 6.21976 7.34717C6.21991 7.34702 6.22006 7.34687 6.22021 7.34672L7.53265 6.04095Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};

export default BackIcon;
