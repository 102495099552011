import axios from "axios";

export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

export function apiCall(method, path, data, config) {
  return new Promise((resolve, reject) => {
    return axios[method.toLowerCase()](`${baseUrl}${path}`, data, config)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          return reject(err.response.data);
        } else if (err.request) {
          return reject(err.request);
        }
        return reject(err.message);
      });
  });
}

export const baseUrl = "https://trail-server-dev.onrender.com";
// export const baseUrl = "http://localhost:5000";
