import { apiCall } from '../../services/api'
import { addError, removeError } from './errors'
import { ADD_LEARNING, GET_MICROSKILLS, UPDATE_LEARNING, ADD_LEARNING_PLAN, VIEW_ALL_LEARNING_PLAN, VIEW_LEARNING_PLAN, GET_SKILLS_GAP } from '../actionTypes'

export const addLearning = (learning) => ({
  type: ADD_LEARNING,
  learning,
})


export const updateLearning = (learning) => ({
  type: UPDATE_LEARNING,
  learning,
})

export const getMicroSkills = (microSkills) => ({
  type: GET_MICROSKILLS,
  microSkills,
})

export const AddLearningPlan = (learning) => ({
  type: ADD_LEARNING_PLAN,
  learning
})

export const getAllLearningPlan = (learning) => ({
  type: VIEW_ALL_LEARNING_PLAN,
  learning
})

export const getLearningPlan = (learning) => ({
  type: VIEW_LEARNING_PLAN,
  learning
})

export const GetSkillsGap = (gap) => ({
  type: GET_SKILLS_GAP,
  gap
})
export const addLearningFn = (learning) => {
  return (dispatch) => {
    return apiCall('post', '/api/v1/learning', learning)
      .then(({ data }) => {
        dispatch(addLearning(data))
        dispatch(removeError())
      })
      .catch((err) => {
        dispatch(addError(err))
      })
  }
}

export const getMicroSkillsFn = () => {
  return (dispatch) => {
    return apiCall('get', `/api/v1/skills`)
      .then(({ data }) => {
        dispatch(getMicroSkills(data))
        dispatch(removeError())
      })
      .catch((err) => {
        dispatch(addError(err))
      })
  }
}

export const updateLearningFn = (learning, id) => {
  return (dispatch) => {
    return apiCall('put', `/api/v1/learning/${id}`, learning)
      .then(({ data }) => {
        dispatch(updateLearning(data))
        dispatch(removeError())
      })
      .catch((err) => {
        dispatch(addError(err))
      })
  }
}


export const addLearningPlan = (learning) => {
  return (dispatch) => {
    return apiCall('post', '/api/v1/plan', learning)
      .then(({ data }) => {
        dispatch(AddLearningPlan(data))
        dispatch(removeError())
      })
      .catch((err) => {
        dispatch(addError(err))
      })
  }
}


export const GetAllLearningPlan = (userId = null, companyId = null) => {
  return (dispatch) => {
    let url = '/api/v1/plan';
    if (userId !== null) {
      url += '/user/' + userId;
    }

    if (companyId !== null) {
      url += `?company=${companyId}`
    }

    return apiCall('get', url)
      .then(({ data }) => {
        dispatch(getAllLearningPlan(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};


export const GetLearningPlan = (planId, userId) => {
  return (dispatch) => {
    return apiCall('get', `/api/v1/plan/${planId}?user=${userId}`)
      .then(({ data }) => {
        dispatch(getLearningPlan(data))
        dispatch(removeError())
      })
      .catch((err) => {
        dispatch(addError(err.error))
      })
  }
}

export const getSkillsGap = (userId) => {
  return (dispatch) => {
    return apiCall('get', `/api/v1/skills/gap/${userId}`)
      .then(({ data }) => {
        dispatch(GetSkillsGap(data))
        dispatch(removeError())
      })
      .catch((err) => {
        dispatch(addError(err))
      })
  }
}

