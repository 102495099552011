import { apiCall } from "../../../services/api";
import {
  GET_ALL_EMPLOYEES,
  GET_DEPARTMENT_EMPLOYEES,
  GET_TOP_EMPLOYEES,
  GET_EMPLOYEE_DETAIL,
  GET_LEARNING_PLAN_EMPLOYEES,
} from "../../actionTypes";
import { addError, removeError } from "../errors";

export const getAllEmployees = (employees, count, total) => ({
  type: GET_ALL_EMPLOYEES,
  employees,
  count,
  total,
});

export const getLearningPlanEmployees = (employees) => ({
  type: GET_LEARNING_PLAN_EMPLOYEES,
  employees,
});

export const getTopEmployees = (employees) => ({
  type: GET_TOP_EMPLOYEES,
  employees,
});

export const getEmployeeDetail = (detail) => ({
  type: GET_EMPLOYEE_DETAIL,
  detail,
});

export const getAllEmployeesFn = (companyId, limit, page, search) => {
  return (dispatch) => {
    const queryParams =
      limit || page || search
        ? `?${[
            limit && `limit=${limit}`,
            page && `page=${page}`,
            search && `search=${search}`,
          ]
            .filter(Boolean)
            .join("&")}`
        : "";
    return apiCall(
      "get",
      `/api/v1/company/${companyId}/employees${queryParams}`
    )
      .then(({ data, count, total }) => {
        if (!limit && !page) {
          dispatch(getLearningPlanEmployees(data));
        } else {
          dispatch(getAllEmployees(data, count, total));
        }
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getTopEmployeesFn = (companyId) => {
  return (dispatch) => {
    return apiCall("get", `/api/v1/company/${companyId}/employees/top`)
      .then(({ data }) => {
        dispatch(getTopEmployees(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getEmployeeDetailFn = (userId) => {
  return (dispatch) => {
    return apiCall(
      "get",
      `/api/v1/users/${userId}?select=jobPercent%20skillPercent%20tag`
    )
      .then(({ user }) => {
        dispatch(getEmployeeDetail(user));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};
