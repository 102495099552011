import React, { useEffect, useState, useRef } from "react";
// eslint-disable-next-line no-unused-vars
import styles from "./styles/dash-onboarding.css";
import onboardingImage from "../Dashboard_v1_business/assets/onboardingImage.png";
import TeamsIcon from "./assets/teamsIcon";
import DeleteTeams from "./assets/teamsDeleteButton";
import TrailLogo from "./assets/trailLogo";
import FileUpload from "./assets/fileUpload";
import InfoCircle from "./assets/infoCircle";
import { Check } from "@mui/icons-material";
import Trash from "./assets/trash";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployeesFn,
  createCompanyFn,
  getRolesFn,
} from "../../store/actions/admin/company";
import Dropdown from "../Dropdown/Dropdown";
import _ from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeError } from "../../store/actions/errors";


const Onboarding = ({ currentUser }) => {
  const dispatch = useDispatch();

  let createCompanyFormData = new FormData();

  const [steps, setSteps] = useState(1);
  const [submitStep, setSubmitStep] = useState(1);


  const back = () => {
    if (steps > 0) {
      setSteps(steps - 1);
    } else {
      setSteps(1);
    }
  };

  const next = () => {
    if (steps < 2) {
      setSteps(steps + 1);
    } else {
      setSteps(2);
    }
  };

  const AboutBusinessSchema = Yup.object().shape({
    numberOfTeams: Yup.string()
      .min(1, "Have to have at least one team")
      .required("Number Of Teams is required"),
    companySize: Yup.string()
      .min(0, "Have to have atleast one team")
      .required("Company Size is required"),
    companyName: Yup.string()
      .min(2, "Company Name is too short")
      .required("Company Name is required"),
    companyLogoName: Yup.string().required("Company Logo is required"),
    bio: Yup.string().required("A brief bio is required"),
    agreeTermsOfService: Yup.boolean()
      .required("The terms of service and privacy policy must be accepted.")
      .oneOf(
        [true],
        "The terms of service and privacy policy must be accepted."
      ),
  });

  const formik = useFormik({
    initialValues: {
      numberOfTeams: "",
      companyName: "",
      teamName: "",
      teamEmployees: "",
      teams: [],
      companyLogo: null,
      companyLogoName: "",
      companyFile: null,
      companyFileName: "",
      companySize: "",
      bio: "",
      agreeTermsOfService: false,
    },
    validationSchema: AboutBusinessSchema,
    onSubmit: (values) => {
      const { companyLogo, companyName, bio, companySize, numberOfTeams } =
        values;

      const data = {
        avatar: companyLogo,
        name: companyName,
        bio,
        employeeCount: companySize,
        teamCount: numberOfTeams,
      };

      Object.keys(data).forEach((key) =>
        createCompanyFormData.append(key, data[key])
      );

      dispatch(createCompanyFn(createCompanyFormData));

      next();
    },
    validateOnMount: true,
  });

  const formikEmployees = useFormik({
    initialValues: {
      employees: [],
      team: {},
      firstName: "",
      lastName: "",
      email: "",
      tag: "",
      job: "",
      level: "",
    },

    onSubmit: (values) => {
      const { employees } = values;
      dispatch(
        addEmployeesFn(createCompany?._id, {
          employees: employees,
        })
      );
      setSubmitStep(2);
    },
  });

  const { createCompany, employees, failedAddEmployees, roles, errorMessage } = useSelector(
    (state) => ({
      createCompany: state.company.company,
      employees: state.company.employees,
      failedAddEmployees: state.company.failedAddEmployees,
      roles: state.company.roles,
      errorMessage: state.errors
    })
  );



  const [jobRoles, setJobRoles] = useState([]);
  useEffect(() => {
    const newRoles = roles?.map((obj) => ({
      ...obj,
      label: obj.name,
      value: obj.name,
    }));

    setJobRoles(newRoles);
  }, [roles]);

  useEffect(() => {
    dispatch(getRolesFn());
  }, []);

  const showToast = (type, object) => {
    toast[type](
      <div>
        <strong></strong> {object.firstName} {object.lastName} - {object.tag} - was not added as an employee
        <br />
      </div>
    );
  };

  const handleToastInfo = (type,data) => {
    data.forEach((object, index) => {
      setTimeout(() => {
        showToast(type, object);
      }, index * 1000); // Delay in milliseconds (e.g., 1000ms = 1 second)
    });
  };

  useEffect(() => {

    if(errorMessage?.message !== null && submitStep === 1) {

      toast.error(
        <div>
          <p> There seems to be an error : {errorMessage?.message?.error}</p>
        </div>
      );
      dispatch(removeError());
      setSteps(1);
    } 

    if(errorMessage?.message !== null && submitStep === 2) {
      toast.error(
        <div>
          <p> There seems to be an error : {errorMessage?.message?.error}</p>
        </div>
      );
    
      dispatch(removeError());
    } 
  }, [errorMessage]);

  useEffect(() => {
    const handleAsyncLogic = () => {
      if (failedAddEmployees.length > 0) {
         handleToastInfo("error", failedAddEmployees);
      }
  
      if (employees.length > 0) {
        window.open('/', "_self");
      }
    };
  
    handleAsyncLogic();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, failedAddEmployees]);

  if (!currentUser?.isAuthenticated) {
    window.open("/", "_self");
  } else {
    return (
      <>
        <ToastContainer />
        <div className={`onboardingMain`}>
          <div className={`onboardingLeft`}>
            <div className={`logo`}>
              <TrailLogo className={`trailLogo`} />
              <p>One trail</p>
            </div>

            <p className={`onboardingWelcome`}>Welcome</p>

            <img src={onboardingImage} width={400} alt="Onboarding" />

            <p className={`onboardingLeftLastParagraph`}>
              One-trail precisely tracks what you learn online from all the
              websites and digital platforms you learn from. Your Coursera
              specialization, Udemy program, Domestika course, YouTube videos
              etc are all seamlessly integrated in one place.
            </p>
          </div>

          {steps === 1 ? (
            <AboutBusiness
              formik={formik}
              createCompanyFormData={createCompanyFormData}
              next={next}
            />
          ) : steps === 2 ? (
            <AboutEmployees
              back={back}
              teams={formik.values.teams}
              employeeCount={formik.values.companySize}
              formikEmployees={formikEmployees}
              createCompany={createCompany}
              roles={jobRoles}
            />
          ) : null}
        </div>
      </>
    );
  }
};

const AboutBusiness = ({ formik }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    isValid,
    handleSubmit,
    handleBlur,
  } = formik;

  const inputRef = useRef(null);

  const [logoError, setLogoError] = useState(false);

  const handleFileChange = (event) => {
    const { files } = event.target;
    if (files[0]) {
      formik.setFieldValue("companyLogoName", files[0].name);
      formik.setFieldValue("companyLogo", URL.createObjectURL(files[0]));
      setLogoError(false);
    } else {
      formik.setFieldValue("companyLogoName", "");
      formik.setFieldValue("companyLogo", null);
    }
  };

  const handleClick = () => {
    setLogoError(true);
    inputRef.current.click();
  };

  return (
    <div className={`onboardingRight`}>
      <div className={`loginDiv`}>
        <p>Already have an account?</p>
        <button className="multipleShareButton">Login</button>
      </div>

      <div className={`mainView`}>
        <p className={`mainViewText`}>
          <strong>What's your business about?</strong>
        </p>
        <p className={`mainViewSubText`}>
          We’ll use this information to confirm your business identity.
        </p>

        <form className={`formHolder`} onSubmit={handleSubmit}>
          <>
            <div className={`businessInitialDetails`}>
              <div className={`formElements`}>
                <label htmlFor="companyName" className={`formLabel`}>
                  Name of company <span>*</span>
                </label>
                <input
                  type="text"
                  className={`formInput`}
                  id="companyName"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              {errors.companyName && touched.companyName ? (
                <p
                  style={{
                    marginTop: "10px",
                    color: "red",
                  }}
                >
                  {errors.companyName}
                </p>
              ) : null}

              <div className={`formElements`}>
                <label className={`formLabel`}>
                  Upload company logo <span>*</span>
                </label>

                <div className="formInput" onClick={handleClick}>
                  <input
                    type="file"
                    className={`input-field`}
                    accept="image/*"
                    hidden
                    onChange={handleFileChange}
                    ref={inputRef}
                  />
                  {values.companyLogo ? (
                    <>
                      <img
                        src={values.companyLogo}
                        width={120}
                        height={120}
                        alt="company logo"
                      />

                      <p>
                        {_.truncate(values.companyLogoName, {
                          length: 20,
                        })}
                      </p>
                    </>
                  ) : (
                    <>
                      <FileUpload />
                      <div className="fileUploadTitles">
                        <p className="mainTitle">Click to upload logo</p>
                        <p className="subTitle">Supports .jpg, .png</p>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {errors.companyLogoName && logoError ? (
                <p
                  style={{
                    marginTop: "10px",
                    color: "red",
                  }}
                >
                  {errors.companyLogoName}
                </p>
              ) : null}

              <div className={`formElements`}>
                <label className={`formLabel`}>
                  About company <span>*</span>
                </label>
                <input
                  type="text"
                  name="bio"
                  className={`formInput`}
                  placeholder="Brief about the company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              {errors.bio && touched.bio ? (
                <p
                  style={{
                    marginTop: "10px",
                    color: "red",
                  }}
                >
                  {errors.bio}
                </p>
              ) : null}

              <div className={`formElements`}>
                <label className={`formLabel`}>
                  Company size <span>*</span>
                </label>
                <input
                  type="text"
                  name="companySize"
                  className={`formInput`}
                  placeholder="Select size"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              {errors.companySize && touched.companySize ? (
                <p
                  style={{
                    marginTop: "10px",
                    color: "red",
                  }}
                >
                  {errors.companySize}
                </p>
              ) : null}

              <div className={`formElements`}>
                <label className={`formLabel`}>
                  Number of teams <span>*</span>
                </label>
                <input
                  type="text"
                  id="numberOfTeams"
                  name="numberOfTeams"
                  className={`formInput`}
                  placeholder="Enter Number Of Teams"
                  value={values.numberOfTeams}
                  onChange={(e) => {
                    setFieldValue("numberOfTeams", e.target.value);
                  }}
                  onBlur={handleBlur}
                />
              </div>

              {errors.numberOfTeams && touched.numberOfTeams ? (
                <p
                  style={{
                    marginTop: "10px",
                    color: "red",
                  }}
                >
                  {errors.numberOfTeams}
                </p>
              ) : null}
            </div>

            {values.numberOfTeams &&
            values.numberOfTeams > values.teams.length ? (
              <div className={`teamsRegion`}>
                <p className={`teamsHeader`}>TEAMS</p>
                <div className={`formElements`}>
                  <label className={`formLabel`}>
                    Team name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`formInput`}
                    placeholder="Enter Team Name"
                    id="teamName"
                    name="teamName"
                    value={values.teamName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className={`formElements`}>
                  <label className={`formLabel`}>
                    Number of employees <span>*</span>
                  </label>
                  <input
                    type="text"
                    className={`formInput`}
                    placeholder="Enter Number Of Employees"
                    name="teamEmployees"
                    value={values.teamEmployees}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className={`formElements`}>
                  <button
                    className={`teamsAddButton`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        values.teamName !== "" &&
                        values.teamEmployees !== ""
                      ) {
                        setFieldValue("teams", [
                          ...values.teams,
                          {
                            id: values.teams.length + 1,
                            name: values.teamName,
                            noOfEmployees: Number(values.teamEmployees),
                            label: values.teamName,
                            value: values.teamName,
                          },
                        ]);

                        setFieldValue("teamName", "");
                        setFieldValue("teamEmployees", "");
                      }
                    }}
                  >
                    Add Team
                  </button>
                </div>
              </div>
            ) : null}

            {values.teams.length > 0 ? (
              <>
                <div className={`teamsContainer`}>
                  {values.teams?.map((team, index) => (
                    <div className={`noOfTeamsBox`} key={team.id}>
                      <TeamsIcon />

                      <div>
                        <p>
                          <strong>{team?.name}</strong>
                        </p>
                        <p>{team?.noOfEmployees} employees</p>
                      </div>

                      <DeleteTeams
                        className={`teamsDeleteButton`}
                        onClick={() => {
                          setFieldValue(
                            "teams",
                            values.teams.filter((team, i) => i !== index)
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>

                {values?.teams.length !== Number(values?.numberOfTeams) ? (
                  <p
                    style={{
                      marginTop: "10px",
                      color: "red",
                    }}
                  >
                    Number of teams entered does not match number of teams
                    provided.
                  </p>
                ) : null}

                {values?.teams.reduce(
                  (total, employee) => total + employee.noOfEmployees,
                  0
                ) !== Number(values?.companySize) ? (
                  <p
                    style={{
                      marginTop: "10px",
                      color: "red",
                    }}
                  >
                    Number of employees entered does not match company size
                    given.
                  </p>
                ) : null}
              </>
            ) : null}

            <div className={`OrRegion`}>
              <div className="onboardingLinksFormsDivider">
                <div></div>

                <p id="OrText">OR</p>
                <div></div>
              </div>

              <div className={`formElements`}>
                <p className={`teamsHeader`}>UPLOAD FILE</p>
                <div
                  style={{
                    marginTop: "26px",
                    display: "flex",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <label className={`formLabel`}>Upload CSV</label>

                  <InfoCircle />
                </div>

                <div
                  className="formInput"
                  onClick={() =>
                    document.querySelector(".input-field-csv").click()
                  }
                >
                  <input
                    type="file"
                    className={`input-field-csv`}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    hidden
                    onChange={({ target: { files } }) => {
                      files[0] &&
                        setFieldValue("companyFileName", files[0]?.name);
                      if (files) {
                        setFieldValue("companyFile", files[0]);
                      }
                    }}
                  />
                  {values.companyFile ? (
                    <>
                      <Check />

                      <p>
                        {_.truncate(values.companyFileName, {
                          length: 20,
                        })}
                      </p>
                    </>
                  ) : (
                    <>
                      <FileUpload />
                      <div className="fileUploadTitles">
                        <p className="mainTitle">Click to upload file</p>
                        <p className="subTitle">
                          Supports .csv, must contain specified headers
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className={`formCheckBox`}>
              <input
                type="checkbox"
                name="agreeTermsOfService"
                checked={values.agreeTermsOfService}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p>
                By checking this box, you are creating an account, and agree to
                the our <span>Terms of Service</span> and{" "}
                <span>Privacy Policy</span>.
              </p>
            </div>

            {errors.agreeTermsOfService && touched.agreeTermsOfService ? (
              <p
                style={{
                  marginTop: "10px",
                  color: "red",
                }}
              >
                {errors.agreeTermsOfService}
              </p>
            ) : null}

            <button
              className={
                isValid &&
                values?.teams.length === Number(values?.numberOfTeams) &&
                values?.teams.reduce(
                  (total, employee) => total + employee.noOfEmployees,
                  0
                ) === Number(values?.companySize)
                  ? "joinTeamButton"
                  : "disabledTeamButton"
              }
              disabled={
                isValid &&
                values?.teams.length === Number(values?.numberOfTeams) &&
                values?.teams.reduce(
                  (total, employee) => total + employee.noOfEmployees,
                  0
                ) === Number(values?.companySize)
                  ? false
                  : true
              }
              type="submit"
            >
              Next
            </button>
          </>
        </form>
      </div>
    </div>
  );
};

const AboutEmployees = ({
  teams,
  employeeCount,
  formikEmployees,
  createCompany,
  roles,
}) => {
  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    formikEmployees;

  // Dropdown
  const isMulti = false;

  const [selectedTeam, setSelectedTeam] = useState(isMulti ? [] : null);
  const [selectedLevel, setSelectedLevel] = useState(isMulti ? [] : null);

  const [selectedJobLevel, setSelectedJobLevel] = useState(isMulti ? [] : null);

  if (!createCompany?._id) {
    return (
      <div className={`onboardingRight`}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={`onboardingRight`}>
        <div className={`loginDiv`}>
          <p>Already have an account?</p>
          <button className="multipleShareButton">Login</button>
        </div>

        <div className={`mainView`}>
          <p className={`mainViewText`}>
            <strong>Who are your employees?</strong>
          </p>
          <p className={`mainViewSubText`}>
            We’ll use this information to carefully curate and set up your
            dashboard and employee dashboard.
          </p>

          <form className={`formHolder`} onSubmit={handleSubmit}>
            <>
              <div className={`businessInitialDetails`}>
                <div className={`formElements`}>
                  <label className={`formLabel`}>
                    Team <span>*</span>
                  </label>
                  <Dropdown
                    options={teams}
                    isSearchable
                    onChange={(values) => {
                      setFieldValue("team", values);
                    }}
                    selectedValue={selectedTeam}
                    setSelectedValue={setSelectedTeam}
                  />
                </div>

                <div className={`formElements`}>
                  <label className={`formLabel`}>
                    First Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className={`formInput`}
                    value={values?.firstName}
                    onChange={handleChange}
                  />
                </div>

                <div className={`formElements`}>
                  <label className={`formLabel`}>
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className={`formInput`}
                    value={values?.lastName}
                    onChange={handleChange}
                  />
                </div>

                <div className={`formElements`}>
                  <label className={`formLabel`}>
                    Email <span>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className={`formInput`}
                    value={values?.email}
                    onChange={handleChange}
                  />
                </div>

                <div className={`formElements`}>
                  <label className={`formLabel`}>
                    Job Title <span>*</span>
                  </label>
                  <Dropdown
                    options={roles}
                    isSearchable
                    onChange={(values) => {
                      setFieldValue("job", values?.id);
                      setFieldValue("tag", values?.value);
                    }}
                    selectedValue={selectedJobLevel}
                    setSelectedValue={setSelectedJobLevel}
                  />
                </div>

                <div className={`formElements`}>
                  <label className={`formLabel`}>
                    Level <span>*</span>
                  </label>
                  <Dropdown
                    options={[
                      {
                        label: "Intern",
                        value: "Intern",
                      },
                      {
                        label: "Junior",
                        value: "Junior",
                      },
                      {
                        label: "Mid",
                        value: "Mid",
                      },
                      {
                        label: "Senior",
                        value: "Senior",
                      },
                      {
                        label: "Lead",
                        value: "Lead",
                      },
                    ]}
                    onChange={(values) => {
                      setFieldValue("level", values?.value);
                    }}
                    selectedValue={selectedLevel}
                    setSelectedValue={setSelectedLevel}
                  />
                </div>

                <div className={`formElements`}>
                  <button
                    className={
                      Object.keys(values?.team).length !== 0 &&
                      values?.firstName !== "" &&
                      values?.lastName !== "" &&
                      values?.email !== "" &&
                      values?.tag !== "" &&
                      values?.level !== ""
                        ? `teamsAddButton`
                        : `disabledTeamsAddButton`
                    }
                    disabled={
                      Object.keys(values?.team).length !== 0 &&
                      values?.firstName !== "" &&
                      values?.lastName !== "" &&
                      values?.email !== "" &&
                      values?.tag !== "" &&
                      values?.level !== ""
                        ? false
                        : true
                    }
                    onClick={(e) => {
                      e.preventDefault();

                      const filteredData = values?.employees?.filter(
                        (obj) => obj.team === values?.team?.value
                      );
                      if (filteredData.length === values?.team?.noOfEmployees) {
                        alert(
                          "This team's employees are all accounted for. Kindly add employees for the remaining team."
                        );
                      } else {
                        setFieldValue("employees", [
                          ...values.employees,
                          {
                            id: values.employees.length + 1,
                            team: values?.team?.value,
                            firstName: values?.firstName,
                            lastName: values?.lastName,
                            email: values?.email,
                            tag: values?.tag,
                            level: values?.level,
                            job: values?.job,
                          },
                        ]);
                      }

                      setFieldValue("team", {});
                      setSelectedTeam(null);
                      setSelectedLevel(null);
                      setSelectedJobLevel(null);
                      setFieldValue("firstName", "");
                      setFieldValue("lastName", "");
                      setFieldValue("email", "");
                      setFieldValue("tag", "");
                      setFieldValue("job", "");
                      setFieldValue("level", "");
                    }}
                  >
                    Add Employee
                  </button>
                </div>
              </div>

              {values?.employees?.length > 0 ? (
                <div className={`employeesRegion`}>
                  <p className={`teamsHeader`}>EMPLOYEES</p>

                  {values?.employees?.map((employee, index) => (
                    <div className={`employeeDiv`} key={index}>
                      <div className={`employeeDetail`}>
                        <p className={`employeeName`}>
                          {employee?.firstName} {employee?.lastName}
                        </p>
                        <p
                          className={`employeePost`}
                        >{`${employee?.tag} , ${employee?.team}`}</p>
                      </div>

                      <Trash
                        className={`deleteEmployee`}
                        onClick={() => {
                          setFieldValue(
                            "employees",
                            values?.employees.filter(
                              (employee, i) => i !== index
                            )
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : null}

              <button
                type="submit"
                className={
                  Number(employeeCount) === values?.employees.length
                    ? `joinTeamButton`
                    : `disabledTeamButton`
                }
                disabled={
                  Number(employeeCount) === values?.employees.length
                    ? false
                    : true
                }
              >
                Finish
              </button>
            </>
          </form>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
