import { CREATE_COMPANY, ADD_EMPLOYEES, GET_ANALYTICS_COMPANY , GET_COMPANY_DETAILS, GET_SKILLS_AND_ROLES, GET_SKILLS} from "../../actionTypes";


const company = (
    state = {
        company: {},
        employees: {},
        failedAddEmployees: [],
        analytics : [],
        companyDetails: {},
        roles: [],
        skills: []

    },
    action
) => {
    switch(action.type) {
        case CREATE_COMPANY:
            return {...state, company: action.company}
        case ADD_EMPLOYEES:
            return {...state, employees: action.employees, failedAddEmployees: action.failed}
        case GET_ANALYTICS_COMPANY:
            return {...state, analytics: action.analytics}
        case GET_COMPANY_DETAILS: 
            return {...state, companyDetails: action.details}
        case GET_SKILLS_AND_ROLES:
                return {...state, roles: action.roles}
        case GET_SKILLS:
            return {...state, skills: action.skills}
        default:
            return state
    }
}

export default company;