import React, { useEffect, useState } from "react";
import TrailLogo from "../Dashboard_v1_business/assets/trailLogo";
import styles from "./styles/onboard.css";
import RightArrow from "../Dashboard_v1_business/assets/arrowRight";
import LandingPageImage from "./assets/landingPageImage.png";
import { updateUser } from "../../store/actions/users";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../Dropdown/Dropdown";
import { getRolesFn, getSkillsFn } from "../../store/actions/admin/company";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OnboardingUser = ({ currentUser }) => {
  const dispatch = useDispatch();

  const { roles, skills, error } = useSelector((state) => ({
    roles: state.company.roles,
    skills: state.company.skills,
    error: state.errors.message
  }));

  useEffect(() => {
    if(currentUser?.user?.role === 'business') {
      window.open('/onboarding', "_self");
    }

    if(currentUser?.user?.onBoarded) {
      window.open('/', "_self");
    }
  }, [currentUser]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [jobRoles, setJobRoles] = useState([]);
  const [jobSkills, setJobSkills] = useState([]);
  const [skillGroup, setSkillGroup] = useState([]);
  const [submit, setSubmit] = useState(false);

  const showToast = (type, error) => {
    toast[type](
      <div>
        <p> Oops, there's an error: </p>
        <br />

        {error}
      </div>
    );
  };

  useEffect(() => {
    if(submit === true) {
      console.log("Current User", currentUser);

      if (currentUser?.onBoarded === true) {

        setSubmit(false);
        window.open('/', "_self");
      }

      if(error) {
        setTimeout(() => {
          showToast("error", error);
        }, 1000);
       
        setSubmit(false);
      }
    }
  }, [currentUser, submit, error]);

  useEffect(() => {
    dispatch(getRolesFn());
  }, []);

  useEffect(() => {
    const newRoles = roles?.map((obj) => ({
      ...obj,
      label: obj.name,
      value: obj.name,
    }));

    setJobRoles(newRoles);
  }, [roles]);

  const [accordionContent, setAccordionContent] = useState([]);

  const [clickAccordion, setClickAccordion] = useState(
    accordionContent?.map(() => false)
  );

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  useEffect(() => {
    setJobSkills(skills);
    setAccordionContent(
      skills?.map((item) => {
        const { name, microSkills } = item;
        const panels = microSkills?.map((skill) => skill.name);

        return {
          mainText: name,
          subText: `Choose your ${name} microskills`,
          panels: panels,
        };
      })
    );
    setClickAccordion(skills?.map(() => false));
  }, [skills]);

  useEffect(() => {
    if (selectedCheckboxes.length > 0 && accordionContent.length > 0) {
          const updatedSkillGroup = accordionContent
        .filter((item) => item.panels.every((panel) => selectedCheckboxes.includes(panel)))
        .map((item) => item.mainText);
      setSkillGroup(updatedSkillGroup);
    }
  }, [selectedCheckboxes, accordionContent]);

  const [isValid, setIsValid] = useState(false);

  // Dropdown
  const isMulti = false;
  const [selectedJobLevel, setSelectedJobLevel] = useState(isMulti ? [] : null);

  // Validation 
  useEffect(() => {
    if (firstName !== '' && lastName !== '' && email !== '' && selectedJobLevel !== null && agreeToTerms !== false) {
      setIsValid(true)
    } else {
      setIsValid(false);
    }
  }, [firstName, lastName, email, selectedJobLevel, agreeToTerms]);

  const handleClick = (index) => {
    setClickAccordion((prevState) => {
      const updatedState = prevState.map((state, i) =>
        i === index ? !state : state
      );
      return updatedState;
    });
  };

  const handleCheckboxChange = (label) => {
    setSelectedCheckboxes((prevState) => {
      if (prevState.includes(label)) {
        return prevState.filter((selected) => selected !== label);
      } else {
        return [...prevState, label];
      }
    });
  };

  

  if (currentUser?.isAuthenticated === false) {
    window.open("/", "_self");
  } else if (jobRoles.length > 0) {
    return (
      <>
      <ToastContainer />
        <div className={`onboardUserMain`}>
          <img src={LandingPageImage} className={`landingImageOne`} />
          <img src={LandingPageImage} className={`landingImageTwo`} />

          <div className={`loginDiv`}>
            <p>Want to create a company?</p>
            <button
              className="multipleShareButton"
              onClick={() => {
                if (currentUser?.user?.role === "user") {
                  dispatch(
                    updateUser({
                      role: "business",
                    })
                  );
                }
              }}
            >
              Onboard Company
            </button>
          </div>

          <div className={`onboardUserContent`}>
            <div className={`logo`}>
              <TrailLogo className={`trailLogo`} />
              <p>One trail</p>
            </div>

            <p className={`welcomeMessage`}>
              Welcome {currentUser?.user?.firstName}
            </p>

            <div className={`formArea`}>
              <form
                className={`formHolder`}
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log("Submit", selectedCheckboxes, selectedJobLevel, skillGroup, agreeToTerms);

                  dispatch(updateUser({
                    firstName,
                    lastName,
                    email,
                    microSkill: selectedCheckboxes,
                    skillGroup: skillGroup,
                    tag: selectedJobLevel?.value,
                    job: selectedJobLevel?.id,
                    onBoarded: true
                  }, "onBoarded"));
                }}

              
              >
                <>
                  <div className={`businessInitialDetails`}>
                    <div className={`formElements`}>
                      <label className={`formLabel`}>
                        First Name <span>*</span>
                      </label>
                      <input type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className={`formInput`}
                      required
                      />
                    </div>

                    <div className={`formElements`}>
                      <label className={`formLabel`}>
                        Last Name <span>*</span>
                      </label>
                      <input type="text"
                       value={lastName}
                       onChange={(e) => setLastName(e.target.value)}
                  
                      className={`formInput`}
                      required
                      />
                    </div>

                    <div className={`formElements`}>
                      <label className={`formLabel`}>
                        Email <span>*</span>
                      </label>
                      <input type="email"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}
                      className={`formInput`}
                      required
                      />
                    </div>

                    <div className={`formElements`}>
                      <label className={`formLabel`}>
                        Job Title <span>*</span>
                      </label>
                      <Dropdown
                        options={jobRoles}
                        isSearchable
                        onChange={(values) => {
                          
                          dispatch(getSkillsFn(values?.id));
                        }}
                        selectedValue={selectedJobLevel}
                        setSelectedValue={setSelectedJobLevel}
                      />
                    </div>

                    {jobSkills.length > 0 ? (
                      <div className="formElements">
                        <label
                          className={`formLabel`}
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          SKILL SET <span>*</span>
                        </label>

                        {accordionContent?.map((accordion, index) => (
                          <React.Fragment key={index}>
                            <div
                              className={`accordion ${
                                clickAccordion[index] ? "active" : ""
                              }`}
                              onClick={() => handleClick(index)}
                              style={{
                                paddingBottom: "8px",
                                borderBottom: clickAccordion[index]
                                  ? "none"
                                  : "1px solid rgba(141, 144, 145, 0.2) ",
                              }}
                            >
                              <div className={`accordionContent`}>
                                <p>{accordion?.mainText}</p>
                                <p>{`${
                                  selectedCheckboxes.filter((box) =>
                                    accordion?.panels?.includes(box)
                                  ).length > 0
                                    ? _.truncate(
                                        selectedCheckboxes
                                          .filter((box) =>
                                            accordion?.panels?.includes(box)
                                          )
                                          .join(", "),
                                        "30"
                                      )
                                    : accordion?.subText
                                }`}</p>
                              </div>

                              <RightArrow
                                stroke="#18A0FB"
                                width="12"
                                height="12"
                                style={{
                                  transform: clickAccordion[index]
                                    ? "rotate(90deg)"
                                    : "",
                                }}
                              />
                            </div>
                            <div
                              className={`panel`}
                              style={{
                                display: clickAccordion[index]
                                  ? "block"
                                  : "none",
                              }}
                            >
                              {accordion?.panels?.map((panel, i) => (
                                <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <label
                                    className="panel-label"
                                    htmlFor={`${index}-${i}`}
                                  >
                                    {panel}
                                  </label>
                                  <input
                                    id={`${index}-${i}`}
                                    value={panel}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleCheckboxChange(e.target.value)
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    ) : null}

                    <div className={`formCheckBox`}>
                      <input type="checkbox" value={agreeToTerms} onChange={(e) => setAgreeToTerms(e.target.checked)} />
                      <p>
                        By checking this box, you are creating an account, and
                        agree to the our <span>Terms of Service</span> and{" "}
                        <span>Privacy Policy</span>.
                      </p>
                    </div>

                    <button
  className={submit ? "disabledTeamButton" : (isValid ? "joinTeamButton" : "disabledTeamButton")}
  disabled={!isValid || submit}
  type="submit"
  onClick={() => {
    setSubmit(true);
    console.log("Submit", selectedCheckboxes, selectedJobLevel, skillGroup, agreeToTerms);

    dispatch(updateUser({
      firstName,
      lastName,
      email,
      microSkill: selectedCheckboxes,
      skillGroup: skillGroup,
      tag: selectedJobLevel?.value,
      job: selectedJobLevel?.id,
      onBoarded: true
    }, "onBoarded"));
  }}
>
  {submit ? "Submitting..." : "Sign Up"}
</button>

                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
};

export default OnboardingUser;
