import React from "react";

const Clock = ({ stroke, fill = "none" }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8332 7.50033C12.8332 10.7203 10.2198 13.3337 6.99984 13.3337C3.77984 13.3337 1.1665 10.7203 1.1665 7.50033C1.1665 4.28033 3.77984 1.66699 6.99984 1.66699C10.2198 1.66699 12.8332 4.28033 12.8332 7.50033Z"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16418 9.35503L7.35585 8.27586C7.04085 8.08919 6.78418 7.64003 6.78418 7.27253V4.88086"
        stroke={stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Clock;
