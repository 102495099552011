import React from "react";

const Trash = ({className, onClick}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28579 3.10798C4.63033 2.97221 5.97512 2.9043 7.32005 2.9043C9.5721 2.9043 11.8301 3.01892 14.074 3.24129C14.4862 3.28213 14.7872 3.6494 14.7464 4.06159C14.7056 4.47379 14.3383 4.77482 13.9261 4.73397C11.73 4.51634 9.52134 4.4043 7.32005 4.4043C6.02535 4.4043 4.73051 4.46968 3.43543 4.6005L3.43323 4.60072L3.43323 4.60072L2.07323 4.73405C1.661 4.77447 1.29405 4.47305 1.25363 4.06081C1.21322 3.64857 1.51464 3.28162 1.92688 3.24121L3.28579 3.10798Z"
        fill="#D4266F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55336 2.56521L6.40675 3.4382C6.33815 3.84669 5.95139 4.12223 5.5429 4.05363C5.1344 3.98502 4.85887 3.59826 4.92747 3.18977L5.07413 2.31644C5.07695 2.29971 5.07992 2.28153 5.08299 2.26271C5.12703 1.99271 5.20452 1.51765 5.52543 1.15376C5.91241 0.714935 6.48487 0.583984 7.12711 0.583984H8.87378C9.52726 0.583984 10.0982 0.726884 10.4819 1.1691C10.8021 1.53811 10.8775 2.0129 10.9197 2.27902C10.9222 2.29494 10.9246 2.31011 10.927 2.32447L11.0733 3.18884C11.1424 3.59725 10.8673 3.98435 10.4589 4.05347C10.0505 4.12258 9.66341 3.84753 9.59429 3.43913L9.44687 2.56801C9.42036 2.40538 9.40307 2.30805 9.37957 2.2294C9.36573 2.18311 9.35475 2.16187 9.35023 2.15435C9.32078 2.13596 9.20343 2.08398 8.87378 2.08398H7.12711C6.80367 2.08398 6.68242 2.13111 6.64906 2.14939C6.6442 2.15869 6.6351 2.17886 6.62381 2.21642C6.5998 2.29629 6.58186 2.39598 6.55336 2.56521ZM6.6386 2.15651C6.63838 2.15636 6.64015 2.1547 6.64458 2.15198C6.64104 2.15529 6.63882 2.15665 6.6386 2.15651ZM9.35834 2.16069C9.35808 2.16087 9.35569 2.15931 9.35196 2.15546C9.35673 2.15859 9.3586 2.16051 9.35834 2.16069Z"
        fill="#D4266F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6153 5.34533C13.0286 5.37202 13.3421 5.72873 13.3154 6.14209L12.8818 12.8595L12.8805 12.8785C12.8632 13.1249 12.8429 13.4147 12.788 13.6874C12.7301 13.9748 12.6247 14.2962 12.4018 14.5865C11.9289 15.2021 11.1459 15.4171 10.1403 15.4171H5.86029C4.85472 15.4171 4.07166 15.2021 3.59882 14.5865C3.37588 14.2962 3.27044 13.9748 3.21255 13.6874C3.15763 13.4147 3.13736 13.1249 3.12012 12.8785L3.1185 12.8554L2.68518 6.14209C2.6585 5.72873 2.97196 5.37202 3.38531 5.34533C3.79866 5.31865 4.15538 5.63211 4.18206 6.04547L4.61526 12.7567C4.63432 13.0286 4.64944 13.2245 4.68302 13.3912C4.71513 13.5506 4.75469 13.6288 4.78842 13.6728C4.82891 13.7255 5.00585 13.9171 5.86029 13.9171H10.1403C10.9947 13.9171 11.1717 13.7255 11.2122 13.6728C11.2459 13.6288 11.2854 13.5506 11.3176 13.3912C11.3511 13.2245 11.3663 13.0286 11.3853 12.7567C11.3854 12.7561 11.3854 12.7554 11.3855 12.7547L11.8185 6.04547C11.8452 5.63211 12.2019 5.31865 12.6153 5.34533Z"
        fill="#D4266F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.13672 11C6.13672 10.5858 6.47251 10.25 6.88672 10.25H9.10672C9.52093 10.25 9.85672 10.5858 9.85672 11C9.85672 11.4142 9.52093 11.75 9.10672 11.75H6.88672C6.47251 11.75 6.13672 11.4142 6.13672 11Z"
        fill="#D4266F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58301 8.33398C5.58301 7.91977 5.91879 7.58398 6.33301 7.58398H9.66634C10.0806 7.58398 10.4163 7.91977 10.4163 8.33398C10.4163 8.7482 10.0806 9.08398 9.66634 9.08398H6.33301C5.91879 9.08398 5.58301 8.7482 5.58301 8.33398Z"
        fill="#D4266F"
      />
    </svg>
  );
};

export default Trash;
