import { GET_ALL_EMPLOYEES, GET_DEPARTMENT_EMPLOYEES, GET_EMPLOYEE_DETAIL, GET_TOP_EMPLOYEES, GET_LEARNING_PLAN_EMPLOYEES } from "../../actionTypes";



const employees = (
    state = {
        allEmployees: [],
        departmentEmployees: [],
        topEmployees: [],
        employeeDetail: {},
        learningPlanEmployees: [],
        total: 1,
        totalDepartmentEmployees: 1
    }, 
    action
) => {
    switch(action.type) {
        case GET_ALL_EMPLOYEES:
            return {...state, allEmployees: action.employees, total: action.total}
        case GET_DEPARTMENT_EMPLOYEES:
            return {...state, departmentEmployees: action.employees, totalDepartmentEmployees: action.total}
        case GET_TOP_EMPLOYEES:
            return {...state, topEmployees: action.employees}
        case GET_EMPLOYEE_DETAIL: 
            return {...state, employeeDetail: action.detail}
        case GET_LEARNING_PLAN_EMPLOYEES: 
            return {...state, learningPlanEmployees: action.employees}
        default:
            return state
    }
};


export default employees;
