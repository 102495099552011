export const ADD_ERROR = 'ADD_ERROR'
export const REMOVE_ERROR = 'REMOVE_ERROR'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_CURRENT_USER_RESOURCES = 'SET_CURRENT_USER_RESOURCES'
export const DELETE_CURRENT_USER_RESOURCES = 'DELETE_CURRENT_USER_RESOURCES'
export const GET_GOOGLE_URL = 'GET_GOOGLE_URL'
export const GET_USER = 'GET_USER'
export const LOAD_PATHWAY = 'LOAD_PATHWAY'
export const GET_MENTOR_HOUR_URL = 'GET_MENTOR_HOUR_URL'
export const GET_PATHWAY_FULL_URL = 'GET_PATHWAY_FULL_URL'
export const GET_PATHWAY_SUB_URL = 'GET_PATHWAY_SUB_URL'
export const LOAD_RESOURCES = 'LOAD_RESOURCES'
export const LOAD_FIELD = 'LOAD_FIELD'
export const LOAD_ONE_FIELD = 'LOAD_ONE_FIELD'
export const LOAD_MENTOR = 'LOAD_MENTOR'
export const LOAD_MENTORS = 'LOAD_MENTORS'
export const LOAD_MENTOR_MEETINGS = 'LOAD_MENTOR_MEETINGS'
export const LOAD_MENTEE_MEETINGS = 'LOAD_MENTEE_MEETINGS'
export const GET_BANK = 'GET_BANK'
export const LOAD_RESULT = 'LOAD_RESULT'
export const LOAD_TEST = 'LOAD_TEST'
export const LOAD_SECTION_RESOURCES = 'LOAD_SECTION_RESOURCES'
export const LOAD_CONGRATULATIONS = 'LOAD_CONGRATULATIONS'
export const LOAD_TEXT = 'LOAD_TEXT'
export const ADD_TODO = 'ADD_TODO'
export const UPDATE_TODO = 'UPDATE_TODO'
export const ADD_LEARNING = 'ADD_LEARNING'
export const UPDATE_LEARNING = 'UPDATE_LEARNING'
export const GET_MICROSKILLS = 'GET_MICROSKILLS'
export const GET_TAGS='GET_TAGS'
export const ADD_TAGS='ADD_TAGS'
export const DELETE_TAGS="DELETE_TAGS"
export const ADD_LEARNING_PLAN="ADD_LEARNING_PLAN"
export const VIEW_ALL_LEARNING_PLAN="VIEW_ALL_LEARNING_PLAN"
export const VIEW_LEARNING_PLAN="VIEW_LEARNING_PLAN"
export const GET_SKILLS_GAP="SKILLS_GAP"
export const GET_ANALYTICS = "GET_ANALYTICS"
export const GET_TOP_DEPARTMENTS="GET_TOP_DEPARTMENTS"
export const GET_ALL_DEPARTMENTS = "GET_ALL_DEPARTMENTS"
export const GET_DEPARTMENT_SKILLGAPS = "GET_DEPARTMENT_SKILLGAPS"
export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES"
export const GET_TOP_EMPLOYEES = "GET_TOP_EMPLOYEES"
export const GET_DEPARTMENT_EMPLOYEES = "GET_DEPARTMENT_EMPLOYEES"
export const GET_ANALYTICS_DEPARTMENT="GET_ANALYTICS_DEPARTMENT"
export const GET_LEARNING_PLAN_EMPLOYEES = "GET_LEARNING_PLAN_EMPLOYEES"
export const GET_EMPLOYEE_DETAIL="GET_EMPLOYEE_DETAIL"
export const CREATE_COMPANY = "CREATE_COMPANY"
export const ADD_EMPLOYEES = "ADD_EMPLOYEES"
export const GET_ANALYTICS_COMPANY = "GET_ANALYTICS_COMPANY"
export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS"
export const GET_SKILLS_AND_ROLES = "GET_SKILLS_AND_ROLES"
export const GET_SKILLS = "GET_SKILLS"