import React from 'react';

const MobileHead = ({nav, display, imgStr}) => {
  return (
    <div className="mobile-head">
        <div
            className="menu-op"
            onClick={nav}
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div className="name">
            {display === 'dahmen'
            ? 'Learning plan'
            : display === 'dahpah'
            ? 'Learning history'
            : display === 'ref'
            ? 'Wishlist'
            : 'Home'}
        </div>
        <div className="profile">
            <div className="profile-img">
            <img
                src={
                imgStr
                    ? imgStr
                    : 'https://pngimg.com/uploads/avatar/avatar_PNG47.png'
                }
                alt="profile pic"
            />
            </div>
        </div>
    </div>
  );
};

export default MobileHead;
