import React from "react";

const ShareIcon = ({className, onClick}) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M20.1725 0.185863C20.2607 0.274162 20.321 0.386416 20.3459 0.508682C20.3708 0.630948 20.3592 0.757836 20.3126 0.873586L12.9027 19.4001C12.8374 19.5632 12.7283 19.7052 12.5876 19.8104C12.4468 19.9155 12.2797 19.9798 12.1048 19.9961C11.9298 20.0125 11.7537 19.9802 11.5959 19.9029C11.4381 19.8256 11.3047 19.7062 11.2103 19.558L7.16348 13.1966L0.802842 9.14918C0.654259 9.05491 0.534602 8.92143 0.457075 8.76345C0.379548 8.60548 0.347165 8.42915 0.363499 8.25393C0.379834 8.07871 0.44425 7.91141 0.54964 7.77049C0.65503 7.62957 0.797298 7.52051 0.960743 7.45534L19.4849 0.0470453C19.6006 0.000448627 19.7275 -0.0111149 19.8498 0.013791C19.972 0.038697 20.0842 0.0989749 20.1725 0.187137V0.185863ZM8.43433 12.8247L11.9502 18.3494L17.9772 3.28061L8.43433 12.8247ZM17.0769 2.38021L2.01003 8.40797L7.53531 11.923L17.0782 2.38021H17.0769Z"
        fill="#D0D0D0"
      />
    </svg>
  );
};

export default ShareIcon;
