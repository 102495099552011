import { ReactComponent as Icon1 } from "./marketing.svg";
import { ReactComponent as Icon2 } from "./peep.svg";
import { ReactComponent as Icon3 } from "./product.svg";
import { ReactComponent as Icon4 } from "./design.svg";
import { ReactComponent as Icon5 } from "./engineer.svg";
import { ReactComponent as Icon6 } from "./hr.svg";
import { ReactComponent as Icon7 } from "./sales.svg";
import { ReactComponent as Icon8 } from "./ops.svg";
import { ReactComponent as Icon9 } from "./growth.svg";
import { ReactComponent as Icon10 } from "./error.svg";
// import { ReactComponent as Icon2 } from "../icon2.svg";

const teams = {
  marketing: {
    code: "#FFF2D3",
    icon: Icon1,
  },
  people: {
    code: "#D0E3FF",
    icon: Icon2,
  },
  product: {
    code: "#CFCCFF",
    icon: Icon3,
  },
  design: {
    code: "#FFEFD0",
    icon: Icon4,
  },
  engineering: {
    code: "#D3FFF7",
    icon: Icon5,
  },
  hr: {
    code: "#FFE8F6",
    icon: Icon6,
  },
  sales: {
    code: "#FFD0D0",
    icon: Icon7,
  },
  ops: {
    code: "#D3E5FF",
    icon: Icon8,
  },
  growth: {
    code: "#D0FFCC",
    icon: Icon9,
  },
  error: {
    code: "#FFD0CC",
    icon: Icon10,
  },
};

export default teams;
