import { ADD_LEARNING, GET_MICROSKILLS, UPDATE_LEARNING, ADD_LEARNING_PLAN, VIEW_ALL_LEARNING_PLAN, VIEW_LEARNING_PLAN, GET_SKILLS_GAP } from '../actionTypes'

const learning = (
  state = { new: {}, microSkills: {}, updatedLearning: {}, allLearningPlan: [], individualLearningPlan: {}, skillsGap: [], addPlan: {} },
  action
) => {
  switch (action.type) {
    case ADD_LEARNING:
      return { ...state, new: action.learning }
    case GET_MICROSKILLS:
      return { ...state, microSkills: action.microSkills }
    case UPDATE_LEARNING:
      return {...state, updatedLearning: action.learning }
    case VIEW_ALL_LEARNING_PLAN:
      return {
        ...state,
        allLearningPlan: action.learning
      }
    case VIEW_LEARNING_PLAN:
      return {
        ...state,
        individualLearningPlan: action.learning
      }
      case GET_SKILLS_GAP:
        return {
          ...state,
          skillsGap: action.gap
        }
      case ADD_LEARNING_PLAN: 
        return {
          ...state,
          addPlan: action.learning
        }
    default:
      return state
  }
}

export default learning
