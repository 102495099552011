import React, { Component } from "react";
import HomeIcon from "./assets/HomeIcon.js";
import LearningPath from "./assets/LearningPath";
import Employees from "./assets/LearningHistory";
import Departments from "./assets/Departments.js";
import Settings from "./assets/Settings.js";

import "./styles/dash-nav.css";

class OneDashnav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      page,
      display,
      nav,
      left,
      img,
      fName,
      lName,
      tutStep,
      step,
      scroll,
    } = this.props;

    return (
      <div className={`dash-navOne one ${left ? "" : "hide"}`}>
        <div className="contain">
          <div className="up">
            <div className="top">
              <div className="brand">
                <img
                  src="https://trailbucket12345.s3.us-east-2.amazonaws.com/root/Brand+Identity+(Trail)-11.png"
                  alt="Trail"
                />
              </div>
              <div className="cancel" onClick={nav}>
                <span></span>
                <span></span>
              </div>
            </div>

            <div className="dash-divider"></div>
         
            <div
              className={`${display === "hom" ? "active" : ""} ${
                display === "hom-sm" ? "active" : ""
              } nav-list`}
              onClick={(e) => page(e, "hom")}
            >
              <div className="icon">

                {display === "hom" || display === "hom-sm" ? (
                 <HomeIcon stroke="#0B1C83" />
                ) : (

                  <HomeIcon fill="#0B1C83" stroke="#f4faff" />
                )}
              </div>
              <div className="nav-item" onClick={(e) => page(e, "hom")}>
                Home
              </div>
            </div>

            <div
              className={`${display === "dahmen" ? "active" : ""} nav-list`}
              onClick={(e) => page(e, "dahmen")}
            >
              <div className="icon">

                {display === 'dahmen' ?
                <LearningPath stroke="#0B1C83" /> 
                :
                <LearningPath stroke='#f4faff' />
                }
                
              </div>
              <div className="nav-item" onClick={(e) => page(e, "dahmen")}>
                Learning Plans
              </div>
            </div>

            <div
              className={`${
                display === "departments"
                  ? "active"
                  : ""
              } nav-list`}
              onClick={(e) => {
                page(e, "departments");
                if (tutStep === 11) {
                  step();
                }
              }}
            >
              <div className="icon">
                {display === "departments"
                
                ?
                <Departments stroke="#0B1C83" />
                :
                <Departments stroke="#f4faff" />
                }
              </div>
              <div
                className="nav-item"
                onClick={(e) => {
                  page(e, "departments");
                  if (tutStep === 11) {
                    step();
                  }
                }}
              >
                Departments
              </div>
            </div>

            <div
              className={`${
                display === "dahpah" || display === "sect" || display === "path"
                  ? "active"
                  : ""
              } nav-list`}
              onClick={(e) => {
                page(e, "dahpah");
                if (tutStep === 11) {
                  step();
                }
              }}
            >
              <div className="icon">
                {display === "dahpah" || display === "sect" || display === "path"
                
                ?
                <Employees stroke="#0B1C83" />
                :
                <Employees stroke="#f4faff" />
                }
              </div>
              <div
                className="nav-item"
                onClick={(e) => {
                  page(e, "dahpah");
                  if (tutStep === 11) {
                    step();
                  }
                }}
              >
                Employees
              </div>
            </div>

            
            
         
            <div
              className={`${display === "proff" ? "active" : ""} nav-list alt`}
              onClick={(e) => {
                page(e, "proff");
                if (tutStep === 15) {
                  step();
                }
              }}
            >
              <div className="icon">
              {display === "proff"
                
                ?
                <Settings stroke="#0B1C83" />
                :
                <Settings stroke="#f4faff" />
                }
              </div>
              <div
                className="nav-item"
                onClick={(e) => {
                  page(e, "proff");
                  if (tutStep === 15) {
                    step();
                    setTimeout(() => {
                      scroll(200);
                    }, 100);
                  }
                }}
              >
                Settings
              </div>
            </div>

            <div className="dash-divider bottom-items"></div>
          </div>

          <div className="bottom">
          <div className="dash-divider"></div>
            <div className={`nav-list out`}>
              <div className="icon">
                {img ? (
                  <img className="profile-img" src={img} alt="user" />
                ) : (
                  <img
                    className="profile-img"
                    src="https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                    alt="user"
                  />
                )}
              </div>
              <div className="nav-item">
                {fName} {lName}
                <p
                style={{
                  color: "#5372EA",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "22px"
                }}
                >Admin</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OneDashnav;
