import React from "react";

const TeamsIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.5781" cy="16" r="16" fill="#FFF2D3" />
      <path
        d="M16.5782 13.5352L15.9362 14.6512C15.7922 14.8972 15.9122 15.1012 16.1942 15.1012H16.9562C17.2442 15.1012 17.3582 15.3052 17.2142 15.5512L16.5782 16.6672"
        stroke="#FFB200"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3578 19.6245V18.9285C12.9778 18.0945 11.8438 16.4685 11.8438 14.7405C11.8438 11.7705 14.5738 9.4425 17.6578 10.1145C19.0138 10.4145 20.2018 11.3145 20.8198 12.5565C22.0738 15.0765 20.7538 17.7525 18.8158 18.9225V19.6185C18.8158 19.7925 18.8818 20.1945 18.2398 20.1945H14.9338C14.2738 20.2005 14.3578 19.9425 14.3578 19.6245Z"
        stroke="#FFB200"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.478 22.0015C15.852 21.6115 17.304 21.6115 18.678 22.0015"
        stroke="#FFB200"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TeamsIcon;
