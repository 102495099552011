import React, { Component } from "react";
import HomeIcon from "./assets/HomeIcon.js";
import LearningPath from "./assets/LearningPath";
import LearningHistory from "./assets/LearningHistory";
import WishList from "./assets/WishList";
import Settings from "./assets/Settings.js";
import "./styles/dash-nav.css";
import { Analytics, Work } from "@mui/icons-material";


class OneDashnav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      page,
      display,
      nav,
      left,
      img,
      fName,
      lName,
      tutStep,
      step,
      scroll,
    } = this.props;

    return (
      <div className={`dash-navOne one ${left ? "" : "hide"}`}>
        <div className="contain">
          <div className="up">
            <div className="top">
              <div className="brand">
                <img
                  src="https://trailbucket12345.s3.us-east-2.amazonaws.com/root/Brand+Identity+(Trail)-11.png"
                  alt="Trail"
                />
              </div>
              <div className="cancel" onClick={nav}>
                <span></span>
                <span></span>
              </div>
            </div>

            <div className="dash-divider"></div>

            <div
              className={`${display === "hom" ? "active" : ""} ${
                display === "hom-sm" ? "active" : ""
              } nav-list`}
              onClick={(e) => page(e, "hom")}
            >
              <div className="icon">
                {display === "hom" || display === "hom-sm" ? (
                  <HomeIcon stroke="#0B1C83" />
                ) : (
                  <HomeIcon fill="#0B1C83" stroke="#f4faff" />
                )}
              </div>
              <div className="nav-item" onClick={(e) => page(e, "hom")}>
                Home
              </div>
            </div>
            <div
              className={`${display === "dahmen" ? "active" : ""} nav-list`}
              onClick={(e) => page(e, "dahmen")}
            >
              <div className="icon">
                {display === "dahmen" ? (
                  <LearningPath stroke="#0B1C83" />
                ) : (
                  <LearningPath stroke="#f4faff" />
                )}
              </div>
              <div className="nav-item" onClick={(e) => page(e, "dahmen")}>
                Learning Plan
              </div>
            </div>

            <div
              className={`${display === "skills" ? "active" : ""} nav-list`}
              onClick={(e) => {
                page(e, "skills");
                if (tutStep === 13) {
                  step();
                }
              }}
            >
              <div className="icon">
                {display === "skills" ? (
                  <Work   sx={{ fontSize: 25 , color: "#0B1C83"}} />     
                ) : (
                  <Work color="#F4FAFF" sx={{ fontSize: 25, color: "#F4FAFF" }}  />
                  
                )}
              </div>
              <div
                className="nav-item"
                onClick={(e) => {
                  page(e, "skills");
                  if (tutStep === 13) {
                    step();
                  }
                }}
              >
               Skills
              </div>
            </div>
  
            <div
              className={`${display === "ref" ? "active" : ""} nav-list`}
              onClick={(e) => {
                page(e, "ref");
                if (tutStep === 13) {
                  step();
                }
              }}
            >
              <div className="icon">
                {display === "ref" ? (
                  <Analytics   sx={{ fontSize: 25 , color: "#0B1C83"}} />     
                ) : (
                  <Analytics color="#F4FAFF" sx={{ fontSize: 25, color: "#F4FAFF" }}  />
                  
                )}
              </div>
              <div
                className="nav-item"
                onClick={(e) => {
                  page(e, "ref");
                  if (tutStep === 13) {
                    step();
                  }
                }}
              >
               Analytics
              </div>
            </div>
            <div
              className={`${display === "proff" ? "active" : ""} nav-list alt`}
              onClick={(e) => {
                page(e, "proff");
                if (tutStep === 15) {
                  step();
                }
              }}
            >
              <div className="icon">
                {display === "proff" ? (
                  <Settings stroke="#0B1C83" />
                ) : (
                  <Settings stroke="#f4faff" />
                )}
              </div>
              <div
                className="nav-item"
                onClick={(e) => {
                  page(e, "proff");
                  if (tutStep === 15) {
                    step();
                    setTimeout(() => {
                      scroll(200);
                    }, 100);
                  }
                }}
              >
                Settings
              </div>
            </div>

            <div className="dash-divider bottom-items"></div>
          </div>

          <div className="bottom">
            <div className="dash-divider"></div>
            <div className={`nav-list out`}>
              <div className="icon">
                {img ? (
                  <img className="profile-img" src={img} alt="user" />
                ) : (
                  <img
                    className="profile-img"
                    src="https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                    alt="user"
                  />
                )}
              </div>
              <div className="nav-item">
                {fName} {lName}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OneDashnav;
