import { combineReducers } from 'redux'
import { currentUser, currentUserResources } from './currentUser'
import errors from './errors'
import pathway from './pathway'
import authUrl from './auth'
import payment from './payment'
import resource from './resources'
import field from './field'
import meeting from './meeting'
import user from './user'
import todo from './todo'
import learning from './learning'
import analytics from './analytics'
import departments from './admin/departments'
import employees from './admin/employees'
import company from './admin/company'




const rootReducer = combineReducers({
  currentUser,
  currentUserResources,
  errors,
  authUrl,
  pathway,
  payment,
  resource,
  field,
  meeting,
  user,
  learning,
  todo,
  analytics,
  departments,
  employees,
  company
})

export default rootReducer
