import React from "react";

const FileUpload = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0L17.6725 0.0876493L19.3266 0.349638L20.9443 0.783095L22.5078 1.38327L24 2.14359L25.4046 3.05573L26.7061 4.10968L27.8903 5.29391L28.9443 6.59544L29.8564 8L30.6167 9.49221L31.2169 11.0557L31.6504 12.6734L31.9123 14.3275L32 16L31.9123 17.6725L31.6504 19.3266L31.2169 20.9443L30.6167 22.5078L29.8564 24L28.9443 25.4046L27.8903 26.7061L26.7061 27.8903L25.4046 28.9443L24 29.8564L22.5078 30.6167L20.9443 31.2169L19.3266 31.6504L17.6725 31.9123L16 32L14.3275 31.9123L12.6734 31.6504L11.0557 31.2169L9.49221 30.6167L8 29.8564L6.59544 28.9443L5.29391 27.8903L4.10968 26.7061L3.05573 25.4046L2.14359 24L1.38327 22.5078L0.783095 20.9443L0.349638 19.3266L0.0876493 17.6725L0 16L0.0876493 14.3275L0.349638 12.6734L0.783095 11.0557L1.38327 9.49221L2.14359 8L3.05573 6.59544L4.10968 5.29391L5.29391 4.10968L6.59544 3.05573L8 2.14359L9.49221 1.38327L11.0557 0.783095L12.6734 0.349638L14.3275 0.0876493L16 0Z"
        fill="#F5F7FA"
      />
      <path
        d="M21.6665 14.294H19.7398C18.1598 14.294 16.8732 13.0073 16.8732 11.4273V9.50065C16.8732 9.13398 16.5732 8.83398 16.2065 8.83398H13.3798C11.3265 8.83398 9.6665 10.1673 9.6665 12.5473V18.454C9.6665 20.834 11.3265 22.1673 13.3798 22.1673H18.6198C20.6732 22.1673 22.3332 20.834 22.3332 18.454V14.9607C22.3332 14.594 22.0332 14.294 21.6665 14.294ZM15.6865 16.5207C15.5865 16.6207 15.4598 16.6673 15.3332 16.6673C15.2065 16.6673 15.0798 16.6207 14.9798 16.5207L14.4998 16.0407V18.834C14.4998 19.1073 14.2732 19.334 13.9998 19.334C13.7265 19.334 13.4998 19.1073 13.4998 18.834V16.0407L13.0198 16.5207C12.8265 16.714 12.5065 16.714 12.3132 16.5207C12.1198 16.3273 12.1198 16.0073 12.3132 15.814L13.6465 14.4807C13.6932 14.4407 13.7398 14.4073 13.7932 14.3807C13.8065 14.374 13.8265 14.3673 13.8398 14.3607C13.8798 14.3473 13.9198 14.3407 13.9665 14.334C13.9865 14.334 13.9998 14.334 14.0198 14.334C14.0732 14.334 14.1265 14.3473 14.1798 14.3673C14.1865 14.3673 14.1865 14.3673 14.1932 14.3673C14.2465 14.3873 14.2998 14.4273 14.3398 14.4673C14.3465 14.474 14.3532 14.474 14.3532 14.4807L15.6865 15.814C15.8798 16.0073 15.8798 16.3273 15.6865 16.5207Z"
        fill="#1C2533"
      />
      <path
        d="M19.62 13.3737C20.2533 13.3803 21.1333 13.3803 21.8866 13.3803C22.2666 13.3803 22.4666 12.9337 22.2 12.667C21.24 11.7003 19.52 9.96032 18.5333 8.97365C18.26 8.70032 17.7866 8.88699 17.7866 9.26699V11.5937C17.7866 12.567 18.6133 13.3737 19.62 13.3737Z"
        fill="#1C2533"
      />
    </svg>
  );
};

export default FileUpload;
