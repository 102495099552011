import { apiCall } from "../../services/api";
import { addError, removeError } from "./errors";
import { setCurrentUser, setAuthorizationToken } from "./auth";
import {
  SET_CURRENT_USER_RESOURCES,
  DELETE_CURRENT_USER_RESOURCES,
  GET_TAGS,
  ADD_TAGS,
  DELETE_TAGS,
} from "../actionTypes";
import { LOAD_MENTORS } from "../actionTypes";
import { getUrl } from "./auth";

export const loadMentors = (mentors) => ({
  type: LOAD_MENTORS,
  mentors,
});

export const SetResources = (resources) => ({
  type: SET_CURRENT_USER_RESOURCES,
  resources,
});

export const DeleteResources = (id) => ({
  type: DELETE_CURRENT_USER_RESOURCES,
  id,
});

export const SetTags = (tags) => ({
  type: GET_TAGS,
  tags,
});

export const AddTags = (tags) => ({
  type: ADD_TAGS,
  tags,
});

export const DeleteTags = (tags) => ({
  type: DELETE_TAGS,
  tags,
});
export const updateUser = (data,params, config) => (dispatch, getState) => {
  let { currentUser } = getState();
  var id = currentUser.user.id;
  if (!id) {
    id = currentUser.user.user.id;
  }
  return apiCall("put", `/api/v1/users/${id}?select=${params}`, data, config)
    .then(({ token, ...user }) => {
      // make an admin safe feature that is updating users without setting auth headers
      if (token) {
        localStorage.setItem("jwtToken", token);
        setAuthorizationToken(token);
        dispatch(setCurrentUser(user.user));
      }
      dispatch(removeError());
    })
    .catch((err) => {
      dispatch(addError(err));
    });
};

export function getUser(id, params, action) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("get", `/api/v1/users/${id}?select=${params}`)
        .then(({ user, data }) => {
          if (action === "user") {
            if (!user) {
              
              dispatch(setCurrentUser(data));
              dispatch(removeError());
              resolve(data);
            } else {
              
              dispatch(setCurrentUser(user));
              dispatch(removeError());
              resolve(user);
            }
          }
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export const addTime = (data) => (dispatch) => {
  return apiCall("put", `/api/v1/users/freetime`, data)
    .then(({ data, google }) => {
      if (google) {
        dispatch(getUrl(data));
      } else {
        dispatch(setCurrentUser(data));
      }
      dispatch(removeError());
    })
    .catch((err) => {
      dispatch(addError(err));
    });
};

export const deleteUser = (id) => (dispatch) => {
  return apiCall("delete", `/api/v1/users/${id}`)
    .then(({ data }) => {
      dispatch(setCurrentUser(data));
      dispatch(removeError());
    })
    .catch((err) => {
      dispatch(addError(err));
    });
};

export const removeTime = (id) => (dispatch) => {
  return apiCall("delete", `/api/v1/users/freetime/${id}`)
    .then(({ data }) => {
      dispatch(setCurrentUser(data));
      dispatch(removeError());
    })
    .catch((err) => {
      dispatch(addError(err));
    });
};

export function generateCode() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("get", `/api/v1/users/referral`)
        .then(({ data }) => {
          dispatch(setCurrentUser(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function getResource(id, params = {}) {
  const queryParams = new URLSearchParams(params).toString(); // convert params object to query string

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let url = `/api/v1/learning?user=${id}`;
      if (queryParams) {
        url += `&${queryParams}`;
      }

      return apiCall("get", url)
        .then(({ data }) => {
          console.log("API Data", data);
          dispatch(SetResources(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}


export function deleteResource(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("delete", `/api/v1/learning/${id}`)
        .then(({ success, data }) => {
          if (success === true) {
            dispatch(DeleteResources(id));
            dispatch(removeError());
            resolve();
          }
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function getTags(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("get", `/api/v1/users/${id}?select=segment`)
        .then(({ user }) => {
          dispatch(SetTags(user?.segment));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function addTags(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("put", `/api/v1/users/segment`, data)
        .then(({ data }) => {
          dispatch(AddTags(data?.segment));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function deleteTags(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("post", `/api/v1/users/segment`, data)
        .then(({ data }) => {
          dispatch(DeleteTags(data?.segment));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function editAssistant(id, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("post", `/api/v1/users/assistant/${id}`, data)
        .then(({ data }) => {
          dispatch(setCurrentUser(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function markAsRead(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("put", `/api/v1/users/resource/${id}`)
        .then(({ data }) => {
          dispatch(setCurrentUser(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function pending(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("put", `/api/v1/users/currentresource/${id}`)
        .then(({ data }) => {
          dispatch(setCurrentUser(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}

export function fetchMentors() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return apiCall("get", `/api/v1/users/mentors`)
        .then(({ data }) => {
          dispatch(loadMentors(data));
          dispatch(removeError());
          resolve();
        })
        .catch((err) => {
          dispatch(addError(err));
          reject();
        });
    });
  };
}
