import React from "react";
import "./style.css";



const ProgressBar = ({ percentage, fillColor }) => {
    const style = {
      width: `${percentage}%`,
      background: fillColor,
    };
    return (
      <div className="progress-bar-container">
        <div className="progress-bar-fill" style={style}></div>
      </div>
    );
};



export default ProgressBar;


