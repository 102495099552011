import { GET_TOP_DEPARTMENTS, GET_ALL_DEPARTMENTS, GET_DEPARTMENT_SKILLGAPS, GET_ANALYTICS_DEPARTMENT } from "../../actionTypes";

const departments = (
  state = {
    topDepartments: {},
    allDepartments: [],
    departmentSkillGaps: [],
    totalNumberOfSkillGaps: 0,
    analytics: []
  },
  action
) => {
  switch(action.type) {
    case GET_TOP_DEPARTMENTS:
      return { ...state, topDepartments: action.departments };
    case GET_ALL_DEPARTMENTS:
      return { ...state, allDepartments: action.departments };
    case GET_DEPARTMENT_SKILLGAPS:
      return { ...state, departmentSkillGaps: action.gaps, totalNumberOfSkillGaps: action.count };
    case GET_ANALYTICS_DEPARTMENT:
      return {...state, analytics: action.analytics}
    default:
      return state;
  }
};

export default departments;
