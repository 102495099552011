


import React from "react";


const AboutUs = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.66699 12.8333H3.45989L3.31344 12.9798L2.16699 14.1262V4C2.16699 3.17614 2.84313 2.5 3.66699 2.5H12.3337C13.1575 2.5 13.8337 3.17614 13.8337 4V11.3333C13.8337 12.1572 13.1575 12.8333 12.3337 12.8333H3.66699Z" stroke="#8D9091"/>
<path d="M7.83301 7.16602H8.16634V9.83268H7.83301V7.16602Z" stroke="#8D9091"/>
<path d="M8.16634 4.99967C8.16634 5.09172 8.09172 5.16634 7.99967 5.16634C7.90763 5.16634 7.83301 5.09172 7.83301 4.99967C7.83301 4.90763 7.90763 4.83301 7.99967 4.83301C8.09172 4.83301 8.16634 4.90763 8.16634 4.99967Z" stroke="#8D9091"/>
</svg>
    )
};

export default AboutUs;