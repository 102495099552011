import React from "react";
import style from "./modal.css";

const  Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show
    ? `modal  displayBlock`
    : `modal displayNone`;

   const handleClick = event => {
    if (event.target.id === 'modal-overlay') {
      handleClose(false);
    }
  };

  return (
    <div className={`${showHideClassName} ` } id="modal-overlay" onClick={handleClick}>
      <section className={`modalMain`}>
        {children}
      </section>
    </div>
  );
};

export default Modal;
