import React from "react";

const HelpAndSupport = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2566_4341)">
        <path
          d="M6.99965 3.26L8.37965 6.05L8.60965 6.55L9.10965 6.625L12.1896 7.07L9.99965 9.22L9.62465 9.585L9.71465 10.085L10.2396 13.15L7.48465 11.705L6.99965 11.5L6.53465 11.745L3.77965 13.17L4.27965 10.105L4.36965 9.605L3.99965 9.22L1.78965 7.045L4.86965 6.6L5.36965 6.525L5.59965 6.025L6.99965 3.26ZM6.99965 1L4.72465 5.61L-0.360352 6.345L3.31965 9.935L2.44965 15L6.99965 12.61L11.5496 15L10.6796 9.935L14.3596 6.35L9.27465 5.61L6.99965 1Z"
          fill="#8D9091"
        />
      </g>
      <defs>
        <clipPath id="clip0_2566_4341">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelpAndSupport;
