import { LOAD_MENTORS, GET_TAGS, ADD_TAGS, DELETE_TAGS } from '../actionTypes';

const DEFAULT_STATE = { mentors: null, tags: [] };

const user = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOAD_MENTORS:
      return { mentors: action.mentors };

    case GET_TAGS:
      return {
        ...state,
        tags: action.tags
      };
    case ADD_TAGS:
      return {
        ...state,
        tags: action.tags
      }
    case DELETE_TAGS: 
      return {
        ...state,
        tags: action.tags
      }
    default:
      return state;
  }
};

export default user;
