import React from "react";

const InfoCircle = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00212 14.6576C11.6688 14.6576 14.6688 11.6576 14.6688 7.99089C14.6688 4.32422 11.6688 1.32422 8.00212 1.32422C4.33545 1.32422 1.33545 4.32422 1.33545 7.99089C1.33545 11.6576 4.33545 14.6576 8.00212 14.6576Z"
        stroke="#5F7795"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.32422V8.65755"
        stroke="#5F7795"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99463 10.6758H8.00062"
        stroke="#5F7795"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoCircle;
