import React, { Component } from "react";
// import { HashLink } from 'react-router-hash-link'
import { getUser, updateUser } from "../../store/actions/users";
import { logout } from "../../store/actions/auth";
import { connect } from "react-redux";
import { addTodoFn, updateTodoFn } from "../../store/actions/todo";
import {
  addLearningFn,
  getMicroSkillsFn,
  updateLearningFn,
} from "../../store/actions/learning";
import OneDashnav from "./OneDashnav";
import {
  oneAss15,
  oneAss14,
  oneAss17,
  oneAss13,
  oneAss06,
  oneAss01,
  oneAss02,
  oneAss03,
  oneAss04,
  oneAss05,
  oneAss23,
  oneAss07,
  oneAss08,
  oneAss09,
  oneAss19,
  oneAss21,
  oneAss22,
} from "../Copy/Copy";
import MobileHead from "./MobileHead";
import Search from "./Search";
import Tutorial from "./Tutorial";
import Home from "./Home";
import LearningPlan from "./LearningPlan";
import Employees from "./Employees";
import Wishlist from "./Wishlist";
import Profile from "./Profile";
import Departments from "./Departments";

class NewBusinessDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "hom",
      showRes: false,
      showWish: false,
      showTodo: false,
      newTodo: "",
      todo: [],
      vid: 0,
      art: 0,
      webin: 0,
      pod: 0,
      course: 0,
      error: "",
      title: "",
      type: "Article",
      wishList: false,
      tempSkill: "",
      chosenSkill: [],
      reminder: false,
      link: "",
      img: "",
      microSkill: [],
      showAdd: false,
      resources: [],
      wishlist: [],
      search: "",
      allLearning: [],
      searchQuery: [],
      reminderRes: [],
      gmail: true,
      whatsapp: true,
      showMainRes: false,
      showMainWish: false,
      video: false,
      webinar: false,
      article: false,
      podcast: false,
      courseFil: false,
      keyword: "",
      tempSkillFil: "",
      chosenSkillFil: [],
      source: "",
      username: "",
      bio: "",
      tag: "",
      email: "",
      view: false,
      load: false,
      left: false,
      mobileFilter: "",
      tutorial: false,
      tutStep: 0,
      reminderObject: {},
      trailBank: [],
      skillsBank: [],
      deptId: "",
    };
  }

  componentDidMount() {
    this.props
      .getUser(
        this.props.currentUser.user.id,
        "phoneNo%20tag%20email%20onBoarded%20company%20companyOnBoarded",
        "user"
      )
      .then(() => {
        var { resources, wishlist, reminderRes, allLearning } = this.state;

        const { currentUser } = this.props;

        const currentUserReminder = currentUser?.user?.reminder;

        this.setState({
          reminderObject: currentUserReminder,
        });

        const jobPercent = currentUser?.user?.jobPercent;
        this.setState({
          trailBank: jobPercent,
        });

        const skillPercent = currentUser?.user?.skillPercent;

        this.setState({
          skillsBank: skillPercent,
        });

        if (currentUser.user.learningResource) {
          allLearning = currentUser.user.learningResource.sort((a, b) => {
            const at = new Date(a.createdAt);
            const bt = new Date(b.createdAt);
            return bt - at;
          });
          this.setState({
            allLearning,
          });
          resources = currentUser.user.learningResource.filter((e) => {
            if (e.wishList) {
              return !e.wishList.state;
            } else {
              return false;
            }
          });
          resources.sort((a, b) => {
            const at = new Date(a.createdAt);
            const bt = new Date(b.createdAt);
            return bt - at;
          });
          wishlist = currentUser.user.learningResource.filter((e) => {
            if (e.wishList) {
              return e.wishList.state;
            } else {
              return true;
            }
          });
          wishlist.sort((a, b) => {
            const at = new Date(a.createdAt);
            const bt = new Date(b.createdAt);
            return bt - at;
          });
          reminderRes = currentUser.user.learningResource.filter((e) => {
            if (e.reminder) {
              return e.reminder.state;
            } else {
              return true;
            }
          });
          this.setState({ resources, wishlist, reminderRes, load: true });
        }
        this.props
          .getMicroSkillsFn()
          .then(() => this.setState({ microSkill: this.props.microSkills }));
        if (currentUser.user.reminder) {
          this.setState({
            gmail: currentUser.user.reminder.gmail,
            whatsapp: currentUser.user.reminder.whatsapp,
          });
        }
        this.setState({
          todo: currentUser.user.toDo,
          email: currentUser.user.email,
          tag: currentUser.user.tag,
          bio: currentUser.user.bio,
          username: currentUser.user.username,
        });
        if (!localStorage.tutorial && window.innerWidth > 1200) {
          this.setState({
            tutorial: true,
          });
        }
      });
  }

  handlePages = (e, info, deptId) => {
    const { removeError } = this.props;
    removeError();
    this.setState({
      display: info,
      mobileFilter: "",
      left: false,
      deptId,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  removeDeptId = () => {
    this.setState({
      deptId: "",
    });
  };

  backHome = () => {
    this.setState({
      display: "hom",
      close: false,
    });
  };

  logout = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.props.logout();
  };

  handleAddTodo = () => {
    this.setState({ view: true });
    this.props
      .addTodoFn({ title: this.state.newTodo })
      .then(() =>
        this.setState({
          todo: [...this.state.todo, this.props.newTodo],
          newTodo: "",
          view: false,
        })
      )
      .catch((err) => console.log(err));
  };

  handleUpdate = (id) => {
    var oldTodoo = this.state.todo;
    oldTodoo = oldTodoo.filter((e) => id !== e._id);
    this.setState({ todo: [...oldTodoo] });
    this.props
      .updateTodoFn({ done: true }, id)
      .then(() => {
        var oldTodo = this.state.todo;
        oldTodo = oldTodo.filter((e) => id !== e._id);
        this.setState({ todo: [...oldTodo, this.props.updatedTodo] });
      })
      .catch((err) => console.log(err));
  };

  handleWishToggle = (val) => {
    this.setState({ wishList: val });
  };

  handleAdd = (val) => {
    const { tempSkill, chosenSkill, tempSkillFil, chosenSkillFil } = this.state;
    if (val === "filter") {
      this.setState({ tempSkillFil: "" });
      if (!chosenSkillFil.includes(tempSkillFil) && tempSkillFil !== "") {
        this.setState({
          chosenSkillFil: [...chosenSkillFil, tempSkillFil.toLowerCase()],
        });
      }
    } else {
      this.setState({ tempSkill: "" });
      if (!chosenSkill.includes(tempSkill) && tempSkill !== "") {
        this.setState({ chosenSkill: [...chosenSkill, tempSkill] });
      }
    }
  };

  handleRemove = (i) => {
    const { chosenSkill } = this.state;
    var skil = [...chosenSkill];
    skil.splice(i, 1);
    this.setState({
      chosenSkill: [...skil],
    });
  };

  handleRemoveFil = (i) => {
    const { chosenSkillFil } = this.state;
    var skil = [...chosenSkillFil];
    skil.splice(i, 1);
    this.setState({
      chosenSkillFil: [...skil],
    });
  };

  handleForm = () => {
    const {
      title,
      link,
      img,
      chosenSkill,
      wishList,
      reminder,
      type,
      resources,
      wishlist,
      allLearning,
      reminderRes,
    } = this.state;
    let urlParts = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(link);
    this.setState({
      error: "",
      view: true,
    });

    this.props
      .addLearningFn({
        title,
        link,
        image: img,
        offeredBy: urlParts[1],
        microSkill: chosenSkill,
        wishList: { state: !wishList },
        reminder: { state: reminder },
        learningType: type,
      })
      .then(() => {
        if (wishList) {
          resources.unshift(this.props.newLearning);
          this.setState({
            resources,
          });
        } else {
          wishlist.unshift(this.props.newLearning);
          this.setState({ wishlist });
        }
        if (reminder) {
          reminderRes.unshift(this.props.newLearning);
          this.setState({
            reminderRes,
          });
        }
        this.setState({
          title: "",
          link: "",
          chosenSkill: [],
          allLearning: [this.props.newLearning, ...allLearning],
          view: false,
          showAdd: false,
        });
      })
      .catch((err) => {
        this.setState({
          view: false,
        });
      });
  };

  handleSearch = (e) => {
    var { allLearning, searchQuery } = this.state;
    this.setState({ search: e.target.value, showMainRes: true });
    searchQuery = allLearning.filter((el) => {
      if (el.title.toLowerCase().includes(e.target.value.toLowerCase())) {
        return true;
      } else if (
        el.offeredBy.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    });
    this.setState({ searchQuery });
  };

  handleUpdateReminder = (id) => {
    var oldReminder = this.state.reminderRes;
    oldReminder = oldReminder.filter((e) => id !== e._id);
    this.setState({ reminderRes: [...oldReminder] });
    this.props
      .updateLearningFn({ reminder: { state: false } }, id)
      .then(() => {
        var oldReminder = this.state.reminderRes;
        oldReminder = oldReminder.filter((e) => id !== e._id);
        this.setState({
          reminderRes: [...oldReminder],
        });
      })
      .catch((err) => console.log(err));
  };

  handleUserReminder = (rem) => {
    const { gmail, whatsapp } = this.state;
    if (rem === "gmail") {
      this.setState({ gmail: !gmail });
      this.props.updateUser({ reminder: { gmail: !gmail, whatsapp } });
    } else {
      this.setState({ whatsapp: !whatsapp });
      this.props.updateUser({ reminder: { gmail, whatsapp: !whatsapp } });
    }
  };

  onChange = (e) => {
    if (e.target.files) {
      var formData = new FormData();
      formData.append("avatar", e.target.files[0]);
      this.props.updateUser(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
      error: "",
    });
  };

  onClick = (e) => {
    e.target.parentNode.childNodes[1].click();
  };

  handleSubmit = (submit) => {
    this.setState({ view: true });

    this.props
      .updateUser(submit)
      .then(() => {
        this.setState({ view: false });
      })
      .catch(() => {});
  };

  handleScroll = (add) => {
    window.scrollTo({
      top: add,
      behavior: "smooth",
    });
  };

  handleSkip = () => {
    localStorage.setItem("tutorial", true);
    this.setState({
      tutorial: false,
      tutStep: 0,
    });
  };

  render() {
    const { currentUser } = this.props;
    var {
      display,
      showRes,
      showWish,
      showTodo,
      newTodo,
      todo,
      vid,
      webin,
      art,
      pod,
      course,
      error,
      title,
      type,
      tempSkill,
      chosenSkill,
      microSkill,
      link,
      wishList,
      showAdd,
      resources,
      wishlist,
      search,
      searchQuery,
      reminderRes,
      reminder,
      allLearning,
      gmail,
      whatsapp,
      showMainRes,
      showMainWish,
      video,
      webinar,
      article,
      podcast,
      courseFil,
      keyword,
      source,
      tempSkillFil,
      chosenSkillFil,
      email,
      tag,
      username,
      bio,
      view,
      load,
      mobileFilter,
      tutorial,
      tutStep,
      deptId,
    } = this.state;

    if (currentUser.user.picture) {
      if (currentUser.user.picture.indexOf("google") !== -1) {
        var imgStr = currentUser.user.picture;
      } else if (currentUser.user.picture.indexOf("trailbucket12345") !== -1) {
        imgStr =
          "https://dqe80zoqbuyqe.cloudfront.net" +
          currentUser.user.picture.substring(51);
      } else {
        imgStr = "";
      }
    }
    var week = { Sun: 0, Mon: 0, Tue: 0, Wed: 0, Thur: 0, Fri: 0, Sat: 0 };
    const days = Object.keys(week);
    if (currentUser.user.learningResource) {
      resources.forEach((e) => {
        if (e.learningType === "Video") {
          vid++;
        } else if (e.learningType === "Article") {
          art++;
        } else if (e.learningType === "Podcast") {
          pod++;
        } else if (e.learningType === "Webinar") {
          webin++;
        } else if (e.learningType === "Course") {
          course++;
        }
      });
      const prevSunday = new Date();
      prevSunday.setDate(prevSunday.getDate() - prevSunday.getDay());
      prevSunday.setHours(0, 0, 0, 0);
      resources.forEach((e) => {
        const date = new Date(e.createdAt);
        if (date > prevSunday) {
          days.forEach((el, i) => {
            if (date.getDay() === i) {
              week[el]++;
            }
          });
        }
      });
      days.forEach((e, i) => {
        if (week[e] > 10) {
          week[e] = 100;
        } else {
          week[e] = week[e] * 10;
        }
      });
      if (display === "hom") {
        if (!showRes && window.innerWidth > 600) {
          resources = resources.slice(0, 3);
        }
        if (!showWish && window.innerWidth > 600) {
          wishlist = wishlist.slice(0, 3);
        }
      } else {
        if (display === "dahpah") {
          var filter = false;
          let newLearnings;
          newLearnings = allLearning.filter((e) => {
            if (video) {
              filter = true;
              if (e.learningType === "Video") {
                return true;
              }
            }
            return false;
          });
          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (podcast) {
                filter = true;
                if (e.learningType === "Podcast") {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (podcast) {
                filter = true;
                if (e.learningType === "Podcast") {
                  return true;
                }
              }
              return false;
            });
          }
          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (article) {
                filter = true;
                if (e.learningType === "Article") {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (article) {
                filter = true;
                if (e.learningType === "Article") {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (webinar) {
                filter = true;
                if (e.learningType === "Webinar") {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (webinar) {
                filter = true;
                if (e.learningType === "Webinar") {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (courseFil) {
                filter = true;
                if (e.learningType === "Course") {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (courseFil) {
                filter = true;
                if (e.learningType === "Course") {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (keyword) {
                filter = true;
                if (e.title.toLowerCase().includes(keyword.toLowerCase())) {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (keyword) {
                filter = true;
                if (e.title.toLowerCase().includes(keyword.toLowerCase())) {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (source) {
                filter = true;
                if (e.offeredBy.toLowerCase().includes(source.toLowerCase())) {
                  return true;
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (source) {
                filter = true;
                if (e.offeredBy.toLowerCase().includes(source.toLowerCase())) {
                  return true;
                }
              }
              return false;
            });
          }

          if (filter) {
            newLearnings = newLearnings.filter((e) => {
              if (chosenSkillFil.length > 0) {
                filter = true;
                if (e.microSkill) {
                  var mSki = false;
                  e.microSkill.forEach((el) => {
                    if (chosenSkillFil.includes(el.toLowerCase())) {
                      mSki = true;
                      return true;
                    }
                  });
                  if (mSki) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              }
              return true;
            });
          } else {
            newLearnings = allLearning.filter((e) => {
              if (chosenSkillFil.length > 0) {
                filter = true;
                if (e.microSkill) {
                  var mSki = false;
                  e.microSkill.forEach((el) => {
                    if (chosenSkillFil.includes(el.toLowerCase())) {
                      mSki = true;
                      return true;
                    }
                  });
                  if (mSki) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
              return false;
            });
          }
          if (filter) {
            allLearning = newLearnings;
          } else if (!showMainRes && !mobileFilter) {
            allLearning = allLearning.slice(0, 6);
          } else if (mobileFilter) {
            allLearning = allLearning.filter((e) => {
              if (mobileFilter === "vid" && e.learningType === "Video") {
                return true;
              } else if (
                mobileFilter === "art" &&
                e.learningType === "Article"
              ) {
                return true;
              } else if (
                mobileFilter === "cor" &&
                e.learningType === "Course"
              ) {
                return true;
              }
              return false;
            });
          }
        }
        if (!showMainWish && !mobileFilter) {
          wishlist = wishlist.slice(0, 6);
        } else if (mobileFilter) {
          wishlist = wishlist.filter((e) => {
            if (mobileFilter === "vid" && e.learningType === "Video") {
              return true;
            } else if (mobileFilter === "art" && e.learningType === "Article") {
              return true;
            } else if (mobileFilter === "cor" && e.learningType === "Course") {
              return true;
            }
            return false;
          });
        }
      }
      todo = todo.filter((e) => !e.done);
      if (mobileFilter) {
        resources = resources.filter((e) => {
          if (mobileFilter === "vid" && e.learningType === "Video") {
            return true;
          } else if (mobileFilter === "art" && e.learningType === "Article") {
            return true;
          } else if (mobileFilter === "cor" && e.learningType === "Course") {
            return true;
          }
          return false;
        });
      }
      if (search && window.innerWidth < 600) {
        allLearning = allLearning.filter((el) => {
          if (el.title.toLowerCase().includes(search.toLowerCase())) {
            return true;
          } else if (
            el.offeredBy.toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
        resources = resources.filter((el) => {
          if (el.title.toLowerCase().includes(search.toLowerCase())) {
            return true;
          } else if (
            el.offeredBy.toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
        wishlist = wishlist.filter((el) => {
          if (el.title.toLowerCase().includes(search.toLowerCase())) {
            return true;
          } else if (
            el.offeredBy.toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
      }
    }
    let setValue = (val) => (val > 9 ? "" : "0") + val;
    let setDate = (date) => {
      const dateSet = new Date(date);
      date = dateSet.toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return date;
    };
    let capitalize = (str) =>
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    let trunk = (str, no) => (str.length > no ? `${str.slice(0, no)}...` : str);
    let ain = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
    ];
    return (
      <div className="dash-hero">
        <OneDashnav
          page={this.handlePages}
          display={display}
          left={this.state.left}
          nav={() => this.setState({ left: !this.state.left })}
          img={imgStr}
          fName={currentUser.user.firstName}
          lName={currentUser.user.lastName}
          step={() =>
            this.setState({
              tutStep: tutStep + 1,
            })
          }
          scroll={this.handleScroll}
          tutStep={tutStep}
        />
        <div className="dash-container one" onClick={this.containerClick}>
          <MobileHead
            nav={() => this.setState({ left: !this.state.left })}
            display={display}
            imgStr={imgStr}
          />
          {/* <Search
            display={display}
            tutStep={tutStep}
            search={search}
            searchFn={this.handleSearch}
            load={load}
            searchQuery={searchQuery}
            oneAss22={oneAss22}
            addResourceFn={() =>
              this.setState({
                showAdd: true,
                wishList: true,
                reminder: false,
              })
            }
            trunk={trunk}
            capitalize={capitalize}
            setDate={setDate}
            searchStopFn={() => this.setState({ search: "" })}
            tutStepOne={() =>
              this.setState({ tutStep: tutStep + 1, showAdd: true })
            }
            skipFn={this.handleSkip}
            mobileFilter={mobileFilter}
            mobileAddResourceFn={() =>
              this.setState({
                showAdd: true,
                wishList: true,
                reminder: false,
              })
            }
            setValue={setValue}
          /> */}
          {/* <Tutorial
            showAdd={showAdd}
            tutStep={tutStep}
            innerFn={() => this.setState({ showAdd: false })}
            error={error}
            title={title}
            inputFn={(event) =>
              this.setState({ [event.target.name]: event.target.value })
            }
            link={link}
            type={type}
            wishList={wishList}
            toggleOn={this.handleWishToggle.bind(this, true)}
            toggleOff={this.handleWishToggle.bind(this, false)}
            tempSkill={tempSkill}
            keyUp={(e) => {
              if (e.key === "Enter") {
                this.handleAdd();
              }
            }}
            microSkill={microSkill}
            addFn={this.handleAdd}
            chosenSkill={chosenSkill}
            closeFn={this.handleRemove}
            checkRem={(e) => {
              this.setState({ reminder: e.target.checked });
            }}
            reminder={reminder}
            view={view}
            handleForm={this.handleForm}
            ain={ain}
            tutorial={tutorial}
            oneAss14={oneAss14}
            handleSkip={this.handleSkip}
            start={() => {
              this.setState({ tutStep: tutStep + 1 });
              this.handleScroll(0);
              localStorage.setItem("tutorial", true);
            }}
            oneAss17={oneAss17}
            extensionLink={() => {
              this.setState({ tutorial: false, tutStep: 0 });
            }}
            handleBack={() =>
              this.setState({ tutStep: tutStep - 1, showAdd: false })
            }
            startOne={() => this.setState({ tutStep: tutStep + 1 })}
            backOne={() => this.setState({ tutStep: tutStep - 1 })}
            startTwo={() => {
              this.setState({ tutStep: tutStep + 1, showAdd: false });
              this.handleScroll(172);
            }}
            backTwo={() => {
              this.setState({ tutStep: tutStep - 1 });
              this.handleScroll(100);
            }}
            startThree={(e) => {
              this.setState({ tutStep: tutStep + 1 });
              this.handlePages(e, "dahpah");
              this.handleScroll(0);
            }}
            backThree={(e) => {
              this.setState({ tutStep: tutStep - 1 });
              this.handlePages(e, "dahpah");
            }}
            startFour={(e) => {
              this.setState({ tutStep: tutStep + 1 });
              this.handlePages(e, "ref");
            }}
            startFive={(e) => {
              this.setState({ tutStep: tutStep + 1 });
              this.handlePages(e, "proff");
              setTimeout(() => {
                this.handleScroll(200);
              }, 100);
            }}
          /> */}
          <Home
            display={display}
            tutStep={tutStep}
            ain={ain}
            oneAss15={oneAss15}
            currentUser={currentUser}
            page={(e) => this.handlePages(e, "hom-sm")}
            resources={resources}
            pageOne={(e) => this.handlePages(e, "dahmen")}
            reminderRes={reminderRes}
            pageTwo={(e) => this.handlePages(e, "ref")}
            wishlist={wishlist}
            pageThree={(e) => this.handlePages(e, "dahpah")}
            allLearning={allLearning}
            back={() => this.setState({ tutStep: tutStep - 1, showAdd: true })}
            handleSkip={this.handleSkip}
            start={() => {
              this.setState({ tutStep: tutStep + 1 });
              this.handleScroll(255);
            }}
            backOne={() => {
              this.setState({ tutStep: tutStep - 1 });
              this.handleScroll(172);
            }}
            startOne={() => {
              this.setState({ tutStep: tutStep + 1 });
              this.handleScroll(670);
            }}
            backTwo={() => {
              this.setState({ tutStep: tutStep - 1 });
              this.handleScroll(225);
            }}
            startTwo={() => {
              this.setState({ tutStep: tutStep + 1 });
              this.handleScroll(92);
            }}
            showMoreRes={() => this.setState({ showRes: !showRes })}
            showRes={showRes}
            load={load}
            addResFn={() =>
              this.setState({
                showAdd: true,
                wishList: true,
                reminder: false,
              })
            }
            trunk={trunk}
            capitalize={capitalize}
            setDate={setDate}
            oneAss01={oneAss01}
            oneAss02={oneAss02}
            oneAss03={oneAss03}
            oneAss04={oneAss04}
            oneAss05={oneAss05}
            oneAss06={oneAss06}
            oneAss07={oneAss07}
            oneAss08={oneAss08}
            oneAss09={oneAss09}
            oneAss19={oneAss19}
            oneAss21={oneAss21}
            oneAss22={oneAss22}
            oneAss23={oneAss23}
            setValue={setValue}
            todo={todo}
            updateTodo={this.handleUpdate}
            showTodo={showTodo}
            addTodoFn={(e) => this.setState({ newTodo: e.target.value })}
            newTodo={newTodo}
            view={view}
            handleAddTodo={this.handleAddTodo}
            showTodoFn={() => this.setState({ showTodo: true })}
            showWishFn={() => this.setState({ showWish: !showWish })}
            showWish={showWish}
            addWishFn={() =>
              this.setState({
                showAdd: true,
                wishList: false,
                reminder: false,
              })
            }
            backThree={() => {
              this.setState({ tutStep: tutStep - 1 });
              this.handleScroll(670);
            }}
            startThree={(e) => {
              this.setState({ tutStep: tutStep + 1 });
              this.handleScroll(0);
              this.handlePages(e, "dahmen");
            }}
            vid={vid}
            webin={webin}
            art={art}
            pod={pod}
            course={course}
            mobileFilter={mobileFilter}
            handlePages={this.handlePages}
          />
          <LearningPlan display={display} currentUser={currentUser} />
          <Wishlist
            ain={ain}
            display={display}
            tutStep={tutStep}
            backFn={(e) => {
              this.setState({ tutStep: tutStep - 1 });
            }}
            handleSkip={this.handleSkip}
            startFn={() => {
              this.setState({ tutStep: tutStep + 1 });
            }}
            showMainWish={showMainWish}
            showMainWishFn={() =>
              this.setState({ showMainWish: !showMainWish })
            }
            load={load}
            wishlist={wishlist}
            oneAss21={oneAss21}
            addResFn={() =>
              this.setState({
                showAdd: true,
                wishList: false,
                reminder: false,
              })
            }
            trunk={trunk}
            capitalize={capitalize}
            setDate={setDate}
            oneAss23={oneAss23}
            oneAss06={oneAss06}
            setValue={setValue}
          />
          <Employees
            display={display}
            tutStep={tutStep}
            ain={ain}
            oneAss15={oneAss15}
            currentUser={currentUser}
            page={(e) => this.handlePages(e, "hom-sm")}
            resources={resources}
            pageOne={(e) => this.handlePages(e, "dahmen")}
            reminderRes={reminderRes}
            pageTwo={(e) => this.handlePages(e, "ref")}
            wishlist={wishlist}
            pageThree={(e) => this.handlePages(e, "dahpah")}
            allLearning={allLearning}
            back={() => this.setState({ tutStep: tutStep - 1, showAdd: true })}
            handleSkip={this.handleSkip}
            start={() => {
              this.setState({ tutStep: tutStep + 1 });
              this.handleScroll(255);
            }}
            backOne={() => {
              this.setState({ tutStep: tutStep - 1 });
              this.handleScroll(172);
            }}
            startOne={() => {
              this.setState({ tutStep: tutStep + 1 });
              this.handleScroll(670);
            }}
            backTwo={() => {
              this.setState({ tutStep: tutStep - 1 });
              this.handleScroll(225);
            }}
            startTwo={() => {
              this.setState({ tutStep: tutStep + 1 });
              this.handleScroll(92);
            }}
            showMoreRes={() => this.setState({ showRes: !showRes })}
            showRes={showRes}
            load={load}
            addResFn={() =>
              this.setState({
                showAdd: true,
                wishList: true,
                reminder: false,
              })
            }
            trunk={trunk}
            capitalize={capitalize}
            setDate={setDate}
            oneAss01={oneAss01}
            oneAss02={oneAss02}
            oneAss03={oneAss03}
            oneAss04={oneAss04}
            oneAss05={oneAss05}
            oneAss06={oneAss06}
            oneAss07={oneAss07}
            oneAss08={oneAss08}
            oneAss09={oneAss09}
            oneAss19={oneAss19}
            oneAss21={oneAss21}
            oneAss22={oneAss22}
            oneAss23={oneAss23}
            setValue={setValue}
            todo={todo}
            updateTodo={this.handleUpdate}
            showTodo={showTodo}
            addTodoFn={(e) => this.setState({ newTodo: e.target.value })}
            newTodo={newTodo}
            view={view}
            handleAddTodo={this.handleAddTodo}
            showTodoFn={() => this.setState({ showTodo: true })}
            showWishFn={() => this.setState({ showWish: !showWish })}
            showWish={showWish}
            addWishFn={() =>
              this.setState({
                showAdd: true,
                wishList: false,
                reminder: false,
              })
            }
            backThree={() => {
              this.setState({ tutStep: tutStep - 1 });
              this.handleScroll(670);
            }}
            startThree={(e) => {
              this.setState({ tutStep: tutStep + 1 });
              this.handleScroll(0);
              this.handlePages(e, "dahmen");
            }}
            vid={vid}
            webin={webin}
            art={art}
            pod={pod}
            course={course}
            mobileFilter={mobileFilter}
          />

          <Departments
            display={display}
            load={load}
            allLearning={allLearning}
            oneAss19={oneAss19}
            filter={filter}
            addResourceFn={() =>
              this.setState({
                showAdd: true,
                wishList: true,
                reminder: false,
              })
            }
            trunk={trunk}
            capitalize={capitalize}
            setDate={setDate}
            showMainResFn={() => this.setState({ showMainRes: !showMainRes })}
            showMainRes={showMainRes}
            oneAss23={oneAss23}
            backFn={(e) => {
              this.setState({ tutStep: tutStep - 1 });
              this.handlePages(e, "dahmen");
            }}
            handleSkip={this.handleSkip}
            startFn={() => {
              this.setState({ tutStep: tutStep + 1 });
            }}
            handleChange={(e) =>
              this.setState({ [e.target.name]: e.target.value })
            }
            keyUpFn={(e) => {
              if (e.key === "Enter") {
                this.handleAdd("filter");
              }
            }}
            handleAdd={this.handleAdd.bind(this, "filter")}
            microSkill={microSkill}
            chosenSkillFil={chosenSkillFil}
            handleRemove={this.handleRemoveFil}
            videoFn={() => this.setState({ video: !video })}
            video={video}
            tempSkillFil={tempSkillFil}
            keyword={keyword}
            article={article}
            articleFn={() => this.setState({ article: !article })}
            courseFil={courseFil}
            courseFilFn={() => this.setState({ courseFil: !courseFil })}
            webinar={webinar}
            webinarFn={() => this.setState({ webinar: !webinar })}
            podcast={podcast}
            podcastFn={() => this.setState({ podcast: !podcast })}
            oneAss06={oneAss06}
            setValue={setValue}
            tutStep={tutStep}
            ain={ain}
            source={source}
            oneAss01={oneAss01}
            oneAss02={oneAss02}
            oneAss03={oneAss03}
            oneAss04={oneAss04}
            currentUser={currentUser}
            deptId={deptId}
            removeDeptId={this.removeDeptId}
          />
          <Profile
            display={display}
            imgStr={imgStr}
            onChange={this.onChange}
            onClick={this.onClick}
            currentUser={currentUser}
            username={username}
            bio={bio}
            tag={tag}
            email={email}
            handleSubmit={this.handleSubmit}
            view={view}
            logout={this.logout}
            tutStep={tutStep}
            tourFn={(e) => {
              this.setState({ tutorial: true, tutStep: 0 });
              this.handleScroll(0);
              this.handlePages(e, "hom");
            }}
            backFn={(e) => {
              this.setState({ tutStep: tutStep - 1 });
              this.handlePages(e, "proff");
            }}
            handleSkip={this.handleSkip}
            startFn={() => {
              this.setState({ tutStep: tutStep + 1 });
            }}
            backHome={this.backHome}
            updateUser={this.props.updateUser}
            reminder={this.state.reminderObject}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
    newTodo: state.todo.new,
    updatedTodo: state.todo.update,
    microSkills: state.learning.microSkills,
    newLearning: state.learning.new,
    updatedLearning: state.learning.updatedLearning,
  };
}

export default connect(mapStateToProps, {
  getUser,
  logout,
  addTodoFn,
  updateTodoFn,
  addLearningFn,
  getMicroSkillsFn,
  updateLearningFn,
  updateUser,
})(NewBusinessDashboard);
