import React, { useEffect, useState } from "react";
import "./styles/dash-wish.css";
import ProgressBar from "../ProgressBar";
import Clock from "../Dashboard_v1_business/assets/Clock";
import BackIcon from "../Dashboard_v1_business/assets/BackIcon";
import ProfilePicture from "../Dashboard_v1_business/assets/ProfilePicture.png";
import CircularProgressBar from "../CircularProgressBar";
import { useHistory } from "react-router-dom";

const Skillset = ({
  display,
  username,
  currentUser,
  trailBank,
  skillsBank,
  skillGaps,
  resources,
  imgStr,
}) => {
  const [expandSkillSet, setExpandSkillSet] = useState(false);
  const [jobPercent, setJobPercent] = useState(trailBank);
  const indicatorColors = ["#34B53A", "#4339F2", "#FF3A29", "#02A0FC"];
  const trackColors = ["#E2FBD7", "#DAD7FE", "#FFE5D3", "#CCF8FE"];
  const [skillsPercent, setSkillsPercent] = useState(skillsBank);
  const upcomingCourses = resources?.slice(0, 5);
  let setValue = (val) => (val > 9 ? "" : "0") + val;
  useEffect(() => {
    if (
      currentUser?.user.hasOwnProperty("jobPercent") &&
      currentUser?.user.hasOwnProperty("skillPercent")
    ) {
      setJobPercent(trailBank);
      for (let i = 0; i < jobPercent.length; i++) {
        jobPercent[i].indicatorColor =
          indicatorColors[i % indicatorColors.length];
        jobPercent[i].trackColor = trackColors[i % trackColors.length];
      }

      setSkillsPercent(skillsBank);
    }

    for (let i = 0; i < upcomingCourses.length; i++) {
      upcomingCourses[i].skill = currentUser?.user?.firstName;
      upcomingCourses[i].skillSubText = "Name of personnel";
      upcomingCourses[i].percentage = 60;
      upcomingCourses[i].fillColor =
        indicatorColors[i % indicatorColors.length];
      upcomingCourses[i].image = upcomingCourses[i].image
        ? upcomingCourses[i].image
        : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
            i + 1
          )}.png`;
    }
  }, [currentUser, trailBank, skillsBank, skillGaps, upcomingCourses]);
  const history = useHistory();
  const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show
      ? `modal  displayBlock`
      : `modal displayNone`;

    return (
      <div className={showHideClassName}>
        <section className="modalMain">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "22px",
              borderBottom: "1px solid #ccc",
            }}
          >
            <div className="backButton" onClick={() => handleClose(false)}>
              <BackIcon stroke={"#18A0FB"} fill={"#18A0FB"} />
              <p
                style={{
                  color: "#18A0FB",
                }}
              >
                Back
              </p>
            </div>

            <p
              style={{
                fontWeight: "500",
                fontSize: "18px",
              }}
            >
              Reminders
            </p>
          </div>
          {children}
        </section>
      </div>
    );
  };

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const LearningSummaryCards = ({
    mainText,
    percentage,
    fillColor,
    subText,
    image,
    skill,
    skillSubText,
    index,
    reminders = false,
  }) => {
    return (
      <div
        className="learningSummaryBoxes"
        key={index}
        style={{
          marginBottom: upcomingCourses.length - 1 !== index ? "20px" : "0",
        }}
      >
        <div>
          <p>
            <strong>{`${mainText.charAt(0).toUpperCase()}${mainText.slice(
              1
            )}`}</strong>
          </p>
          <p
            style={{
              color: "#FFB200",
            }}
          >
            {subText} minutes
          </p>
        </div>

        <ProgressBar percentage={percentage} fillColor={fillColor} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="skillsDetails">
            <img className="skillsImage" src={image} />
            <div>
              <p className="skillsName">{skill}</p>
              <p className="skillsRole">{skillSubText} </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              fontSize: "10px",
              fontWeight: "600",
            }}
          >
            <Clock stroke={reminders ? "#34B53A" : "#18A0FB"} />

            {reminders ? (
              <p
                style={{
                  color: "#34B53A",
                  cursor: "pointer",
                }}
                onClick={() => setShowModal2(true)}
              >
                Weekly via emails
              </p>
            ) : (
              <p
                style={{
                  color: "#18A0FB",
                  cursor: "pointer",
                }}
                onClick={() => setShowModal(true)}
              >
                Set reminder
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const [howRemindersParagraphs, setHowRemindersParagraph] = useState([
    {
      id: 1,
      text: "Show reminders on dashboard",
      checked: false,
    },
    {
      id: 2,
      text: "Emails",
      checked: false,
    },
    {
      id: 3,
      text: "Whatsapp",
      checked: false,
    },
    {
      id: 4,
      text: "Google Calendar",
      checked: false,
    },
  ]);

  const [howFrequentParagraphs, setHowFrequentParagraph] = useState([
    {
      id: 1,
      text: "Daily",
      checked: false,
    },
    {
      id: 2,
      text: "Bi-weekly",
      checked: false,
    },
    {
      id: 3,
      text: "Weekly",
      checked: false,
    },
    {
      id: 4,
      text: "Monthly",
      checked: false,
    },
    {
      id: 5,
      text: "Customize my reminders",
      checked: false,
    },
  ]);

  const [howRemindersParagraphs2, setHowRemindersParagraph2] = useState([
    {
      id: 1,
      text: "Show reminders on dashboard",
      checked: true,
    },
    {
      id: 2,
      text: "Emails",
      checked: true,
    },
    {
      id: 3,
      text: "Whatsapp",
      checked: true,
    },
    {
      id: 4,
      text: "Google Calendar",
      checked: false,
    },
  ]);

  const [howFrequentParagraphs2, setHowFrequentParagraph2] = useState([
    {
      id: 1,
      text: "Daily",
      checked: true,
    },
    {
      id: 2,
      text: "Bi-weekly",
      checked: true,
    },
    {
      id: 3,
      text: "Weekly",
      checked: false,
    },
    {
      id: 4,
      text: "Monthly",
      checked: false,
    },
    {
      id: 5,
      text: "Customize my reminders",
      checked: false,
    },
  ]);

  const handleToggle = (id) => {
    setHowRemindersParagraph(
      howRemindersParagraphs.map((slider) =>
        slider.id === id
          ? { ...slider, checked: !slider.checked }
          : { ...slider, checked: slider.checked }
      )
    );
  };

  const handleToggleFrequent = (id) => {
    setHowFrequentParagraph(
      howFrequentParagraphs.map((slider) =>
        slider.id === id
          ? { ...slider, checked: !slider.checked }
          : { ...slider, checked: false }
      )
    );
  };

  const handleToggle2 = (id) => {
    setHowRemindersParagraph2(
      howRemindersParagraphs2.map((slider) =>
        slider.id === id
          ? { ...slider, checked: !slider.checked }
          : { ...slider, checked: slider.checked }
      )
    );
  };

  const handleToggleFrequent2 = (id) => {
    setHowFrequentParagraph2(
      howFrequentParagraphs2.map((slider) =>
        slider.id === id
          ? { ...slider, checked: !slider.checked }
          : { ...slider, checked: false }
      )
    );
  };

  const [activePill, setActivePill] = useState("All");

  function handlePillClick(pill) {
    setActivePill(pill);
  }

  return (
    <>
      <div className={`one-home skills ${display === "skills" ? "" : "hide"}`}>
        <div className="dash-profile">
          <div className="backButton" onClick={() => history.goBack()}>
            <BackIcon />
            <p>Back</p>
          </div>

          <Modal show={showModal} handleClose={setShowModal}>
            <>
              <div className="howReminders">
                <p className="howRemindersHeader">
                  How do you want to receive your reminders?
                </p>

                {howRemindersParagraphs.map((paragraph, index) => (
                  <div className="channelAndToggle" key={paragraph.id}>
                    <p>{paragraph.text}</p>

                    <label className="toggle" for="myToggle">
                      <input
                        className={`toggle__input`}
                        checked={paragraph.checked}
                        type="checkbox"
                        id="myToggle"
                      />
                      <div
                        className={`toggle__fill `}
                        onClick={() => handleToggle(paragraph.id)}
                      ></div>
                    </label>
                  </div>
                ))}
              </div>

              <div className="howReminders">
                <p className="howRemindersHeader">
                  How frequently do you want to receive this reminder?
                </p>
                {howFrequentParagraphs.map((paragraph, index) => (
                  <div className="channelAndToggle" key={paragraph.id}>
                    <p>{paragraph.text}</p>

                    <label className="toggle" for="myToggle">
                      <input
                        className={`toggle__input`}
                        checked={paragraph.checked}
                        type="checkbox"
                        id="myToggle"
                      />
                      <div
                        className={`toggle__fill `}
                        onClick={() => handleToggleFrequent(paragraph.id)}
                      ></div>
                    </label>
                  </div>
                ))}
              </div>

              <div
                style={{
                  marginTop: "22px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div></div>
                <button
                  className="modalSaveButton"
                  onClick={() => setShowModal(false)}
                >
                  Save
                </button>
              </div>
            </>
          </Modal>

          <Modal show={showModal2} handleClose={setShowModal2}>
            <>
              <div className="howReminders">
                <p className="howRemindersHeader">
                  How do you want to receive your reminders?
                </p>

                {howRemindersParagraphs2.map((paragraph, index) => (
                  <div className="channelAndToggle" key={paragraph.id}>
                    <p>{paragraph.text}</p>

                    <label className="toggle" for="myToggle">
                      <input
                        className={`toggle__input`}
                        checked={paragraph.checked}
                        type="checkbox"
                        id="myToggle"
                      />
                      <div
                        className={`toggle__fill `}
                        onClick={() => handleToggle2(paragraph.id)}
                      ></div>
                    </label>
                  </div>
                ))}
              </div>

              <div className="howReminders">
                <p className="howRemindersHeader">
                  How frequently do you want to receive this reminder?
                </p>
                {howFrequentParagraphs2.map((paragraph, index) => (
                  <div className="channelAndToggle" key={paragraph.id}>
                    <p>{paragraph.text}</p>

                    <label className="toggle" for="myToggle">
                      <input
                        className={`toggle__input`}
                        checked={paragraph.checked}
                        type="checkbox"
                        id="myToggle"
                      />
                      <div
                        className={`toggle__fill `}
                        onClick={() => handleToggleFrequent2(paragraph.id)}
                      ></div>
                    </label>
                  </div>
                ))}
              </div>

              <div
                style={{
                  marginTop: "22px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div></div>
                <button
                  className="modalSaveButton"
                  onClick={() => setShowModal2(false)}
                >
                  Save
                </button>
              </div>
            </>
          </Modal>

          <div className="main-container">
            <div className="columnLayout">
              <div className="currentSkillSet ">
                <p className="boxHeader">Current Skill Set</p>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    className="missingSkillsDiv allToggleDiv"
                    onClick={() => handlePillClick("All")}
                  >
                    <p>All</p>
                  </div>

                  <div
                    className="missingSkillsDiv"
                    onClick={() => handlePillClick("Missing Skills")}
                  >
                    <p>Missing Skills</p>
                  </div>
                </div>
                {activePill === "All" && (
                  <div
                    className="overflowHiddenAndScroll"
                    style={{
                      height: !expandSkillSet ? "100px " : "",
                    }}
                  >
                    {skillsPercent?.map((data, index) => {
                      return (
                        <div className="currentSkillAndPercentage" key={index}>
                          <p className="currentSkill">{data?.name}</p>
                          <ProgressBar
                            percentage={(data?.value * 100) / 10}
                            fillColor={"#18A0FB"}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}

                {activePill === "Missing Skills" && (
                  <div className="overflowHiddenAndScroll">
                    {skillGaps.length > 0 ? (
                      skillGaps?.map((data, index) => {
                        return (
                          <div
                            className="currentSkillAndPercentage"
                            key={index}
                          >
                            <p className="currentSkill">{data?.name}</p>
                            <ProgressBar
                              percentage={(data?.value * 100) / 10}
                              fillColor={"#18A0FB"}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <p className="missingSkillsEmpty">
                        No Missing Skills as of now
                      </p>
                    )}
                  </div>
                )}
                <div className="currentSkillAndPercentage">
                  <p
                    className="currentSkill"
                    style={{
                      width: "90px",
                    }}
                  >
                    {""}
                  </p>

                  <div className="skillsScale">
                    {[
                      "00",
                      "01",
                      "02",
                      "03",
                      "04",
                      "05",
                      "06",
                      "07",
                      "08",
                      "09",
                      "10",
                    ].map((num, index) => (
                      <p
                        key={index}
                        style={{
                          fontWeight: "400",
                          fontSize: "10px",
                          lineHeight: "15px",
                          color: "#000000",
                          opacity: "0.4",
                        }}
                      >
                        {num}
                      </p>
                    ))}
                  </div>
                </div>

                <button
                  className="clickMeButton"
                  onClick={() => setExpandSkillSet((skill) => !skill)}
                >
                  Click Me
                  <svg
                    className={!expandSkillSet ? "arrowDownSvg" : "arrowUpSvg"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M7 10l5 5 5-5z" />
                  </svg>
                </button>
              </div>

              <div className="percentageOfSkillsAcquired">
                <p className="boxHeader">Trail Bank</p>

                <p className="employeeSkillsGained">
                  This is the percentage of skills you've acquired so far using
                  this platform.
                </p>

                <div className="circularProgress">
                  {jobPercent.map((job, index) => (
                    <div className="circleAndLabel" key={job?.jobId}>
                      <CircularProgressBar
                        progress={job?.value}
                        trackWidth={5}
                        indicatorWidth={10}
                        indicatorColor={job?.indicatorColor}
                        trackColor={job?.trackColor}
                      />

                      <div className="individualLegend">
                        <div
                          class="circle"
                          style={{
                            backgroundColor: "#34B53A",
                          }}
                        ></div>

                        <p>{job?.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="learningSummary">
              <div className="learningSummaryHeader">
                <p>Upcoming Courses</p>

                <select name="duration" id="time-periods">
                  <option value="volvo">This Week</option>
                  <option value="saab">This Month</option>
                  <option value="opel">6 Months</option>
                  <option value="audi">This Year</option>
                </select>
              </div>

              {upcomingCourses?.map((data, index) => {
                return (
                  <LearningSummaryCards
                    mainText={data?.title}
                    fillColor={data?.fillColor}
                    percentage={data?.percentage}
                    subText={data?.time}
                    image={data?.image}
                    skill={data?.skill}
                    skillSubText={data?.skillSubText}
                    index={index}
                    reminders={data?.reminders}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skillset;
