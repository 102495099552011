import React, { useState, useEffect } from "react";
import "./styles/dash-home.css";
import ProgressBar from "../ProgressBar";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ViewAll from "./assets/ViewAll";
import RightArrow from "./assets/arrowRight";
import Employees from "./assets/LearningHistory";
import { useDispatch, useSelector } from "react-redux";
import { getTopDepartmentsFn } from "../../store/actions/admin/departments";
import {
  getAnalyticsCompanyFn,
  getCompanyDetailsFn,
} from "../../store/actions/admin/company";
import { getUser, updateUser } from "../../store/actions/users";
import avatar from "./assets/avatar.svg";
import teams from "./assets/team";

const Home = ({
  display,
  currentUser,
  oneAss01,
  oneAss02,
  oneAss03,
  oneAss04,
  handlePages,
}) => {
  const data = [
    {
      label: "Monday",
      skill: 10,
      timeLearning: 20,
      resourceCount: 50,
      skillGap: 70,
    },
    {
      label: "Tuesday",
      skill: 30,
      timeLearning: 15,
      resourceCount: 25,
      skillGap: 45,
    },
    {
      label: "Wednesday",
      skill: 20,
      timeLearning: 90,
      resourceCount: 100,
      skillGap: 75,
    },
    {
      label: "Thursday",
      skill: 27,
      timeLearning: 39,
      resourceCount: 20,
      skillGap: 58,
    },
    {
      label: "Friday",
      skill: 18,
      timeLearning: 48,
      resourceCount: 22,
      skillGap: 25,
    },
    {
      label: "Saturday",
      skill: 29,
      timeLearning: 38,
      resourceCount: 45,
      skillGap: 65,
    },
    {
      label: "Sunday",
      skill: 35,
      timeLearning: 43,
      resourceCount: 21,
      skillGap: 25,
    },
  ];

  const learningGrid = [
    {
      mainText: "No of skills acquired",
      percentage: 60,
      fillColor: "#FF3A29",
      subText: "skills",
      image: oneAss01,
      skill: "Engineering",
      skillSubText: "Top Department",
      id: "skill",
    },

    {
      mainText: "No of skill gaps",
      percentage: 40,
      fillColor: "#FFB200",
      subText: "skill gaps",
      image: oneAss02,
      skill: "Marketing",
      skillSubText: "Top Department",
      id: "skillGap",
    },

    {
      mainText: "Learning resources consumed",
      percentage: 40,
      fillColor: "#4339F2",
      subText: "resources",
      image: oneAss03,
      skill: "Product",
      skillSubText: "Top Department",
      id: "resourceCount",
    },

    {
      mainText: "Time spent Learning",
      percentage: 20,
      fillColor: "#34B53A",
      subText: "minutes",
      image: oneAss04,
      skill: "Sales",
      skillSubText: "Top Department",
      id: "timeLearning",
    },
  ];

  const [currentPeriod, setCurrentPeriod] = useState("week");
  const [frame, setFrame] = useState(false);
  const [err, setErr] = useState(false);
  const [currentData, setCurrentData] = useState(data);
  const [learnGrid, setLearnGrid] = useState(learningGrid);

  const { topDepartments, analytics, businessUser, companyDetails } =
    useSelector((state) => ({
      topDepartments: state?.departments?.topDepartments,
      analytics: state?.company?.analytics,
      businessUser: state?.currentUser,
      companyDetails: state?.company?.companyDetails,
    }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(currentUser?.user?.id, "company", "user"));
  }, []);

  useEffect(() => {
    if (businessUser?.user?.company) {
      dispatch(getTopDepartmentsFn(businessUser?.user?.company));
      dispatch(getCompanyDetailsFn(businessUser?.user?.company));
    }
  }, [businessUser, dispatch]);

  useEffect(() => {
    if (businessUser?.user?.company) {
      dispatch(
        getAnalyticsCompanyFn(currentPeriod, businessUser?.user?.company, frame)
      );
    }
  }, [currentPeriod, frame, businessUser, dispatch]);

  useEffect(() => {
    if (Array.isArray(analytics)) {
      if (analytics.length === 0) {
        setCurrentData([]);
      } else {
        setCurrentData(analytics);
        var lastLearning = analytics[analytics.length - 1].lastPerformance;
      }
      setErr(false);
    } else {
      setCurrentData([]);
      setErr(true);
      lastLearning = analytics;
    }
    if (lastLearning) {
      const learnGridNew = learningGrid.map((lg) => {
        lg.subText = `${Math.round(lastLearning[lg.id].value)} ${lg.subText}`;
        lg.percentage = lastLearning[lg.id].percent;
        return lg;
      });
      setLearnGrid(learnGridNew);
    }
  }, [analytics]);

  const ThreeDotsButton = ({ onClick }) => {
    return (
      <button className="three-dots-button" onClick={onClick}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </button>
    );
  };

  const LearningSummaryCards = ({
    mainText,
    percentage,
    fillColor,
    subText,
    index,
  }) => {
    if (percentage < 1) {
      percentage = 1;
    }
    return (
      <div className="individualBoxes" key={index}>
        <div>
          <p>
            <strong>{mainText}</strong>
          </p>
          <p
            style={{
              color: fillColor,
            }}
          >
            {subText}
          </p>
        </div>

        <ProgressBar percentage={percentage} fillColor={fillColor} />
      </div>
    );
  };

  const TeamComponent = ({ dept, sub, name, count, major, click, deptId }) => {
    const color = teams[dept].code;
    const Icon = teams[dept].icon;
    return (
      <div
        className={`departmentGrid ${major ? "maj" : ""}`}
        onClick={(e) => click && handlePages(e, click, deptId && deptId)}
      >
        <div className="departmentFlex">
          <div className="departmentIcon" style={{ backgroundColor: color }}>
            <Icon />
          </div>

          <div className="departmentDetails">
            <p className="departmentName">{name}</p>
            <p className="departmentEmployeeDetail">{sub}</p>

            <div className="departmentStaffDetails">
              <div>
                <p>{count}</p>
              </div>

              <Employees stroke="#18A0FB" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [switcher, setSwitcher] = useState(false);
  const [skill, setSkill] = useState(true);
  const [time, setTime] = useState(true);
  const [gap, setGap] = useState(true);
  const [res, setRes] = useState(true);

  const handleSwitch = (e) => {
    setSwitcher(true);
    dispatch(updateUser({ role: "user" }));
  };

  return (
    <div className="one-home">
      <div
        className={`dashHome ${display === ("hom" || "hom-sm") ? "" : "hide"}`}
      >
        <style jsx global>
          {`
            * {
              margin: 0;
              padding: 0;
             
            }

            body {
              box-sizing: 'border-box';
              background-color: '#f4faff
            }
          `}
        </style>
        {switcher && (
          <div className="switcher-con">
            <div className="con">
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        )}
        <div className="home-header">
          <div className="top">
            <h1>Hello Admin</h1>
            <p>Here is a summary of all employees at the company</p>
          </div>
          <div className="bot">
            <div className="up">
              <div className="img">
                <img src={avatar} alt="" />
              </div>
              <div className="cont">
                <div className="name">L. Bafunto</div>
                <div className="status">Admin</div>
              </div>
            </div>
            <div className="down">
              <div className="switch">
                <input
                  type="checkbox"
                  id="role"
                  name="role"
                  onChange={handleSwitch}
                />
                <label class="ios-checkbox" for="role"></label>
              </div>
              <p>Personal Dashboard</p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="topPerforming main">All Departments</p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              marginBottom: "15px",
              cursor: "pointer",
            }}
            onClick={(e) => handlePages(e, "departments")}
          >
            <ViewAll />
            <p
              style={{
                fontWeight: 500,
                fontSize: "12px",
                color: "#18A0FB",
              }}
            >
              View All
            </p>
            <RightArrow stroke={"#18A0FB"} />
          </div>
        </div>

        {Object.keys(companyDetails).length !== 0 &&
        Object.keys(topDepartments).length !== 0 ? (
          <div className="topEmployees">
            <TeamComponent
              name="All employees"
              count={companyDetails?.employeeCount}
              sub="Across all departments"
              dept="people"
              major
              click="dahpah"
            />

            <TeamComponent
              name="Departments"
              count={companyDetails?.teamCount}
              sub="No of departments"
              dept="marketing"
              click="departments"
            />

            <TeamComponent
              name={topDepartments?.name}
              count={topDepartments?.count}
              sub="TOP DEPARTMENT"
              dept={topDepartments?.image}
              click="departments"
              deptId={topDepartments?.deptId}
            />
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}

        <p className="topPerforming">All Employees</p>

        <div className="learning-summary">
          <div className="learning-summary-header">
            <p>Learning Summary</p>

            <div className="side-prof">
              <div className="switch gree">
                <p>Skills</p>
                <input
                  type="checkbox"
                  id="skill"
                  name="skill"
                  onChange={() => setSkill(!skill)}
                />
                <label class="ios-checkbox" for="skill"></label>
              </div>
              <div className="switch gree">
                <p>Time</p>
                <input
                  type="checkbox"
                  id="time"
                  name="time"
                  onChange={() => setTime(!time)}
                />
                <label class="ios-checkbox" for="time"></label>
              </div>
              <div className="switch gree">
                <p>Skill gap</p>
                <input
                  type="checkbox"
                  id="gap"
                  name="gap"
                  onChange={() => setGap(!gap)}
                />
                <label class="ios-checkbox" for="gap"></label>
              </div>
              <div className="switch gree">
                <p>Resources</p>
                <input
                  type="checkbox"
                  id="res"
                  name="res"
                  onChange={() => setRes(!res)}
                />
                <label class="ios-checkbox" for="res"></label>
              </div>
              <select
                name="duration"
                id="time-periods"
                value={`${frame}.${currentPeriod}`}
                onChange={(e) => {
                  setCurrentPeriod(e.target.value.split(".")[1]);
                  setFrame(JSON.parse(e.target.value.split(".")[0]));
                  setErr(false);
                }}
              >
                <option value="true.week">Last Week</option>
                <option value="false.week">This Week</option>
                <option value="true.month">Last Month</option>
                <option value="false.month">This Month</option>
                <option value="true.year">Last Year</option>
                <option value="false.year">This Year</option>
              </select>
            </div>
          </div>
          {err && (
            <div className="notice">
              <p>
                No Available Data for {frame ? "last" : "this"} {currentPeriod}.
                No learning took place {frame ? "last" : "this"} {currentPeriod}
              </p>
            </div>
          )}

          {currentData ? (
            <ResponsiveContainer width="100%" height={450}>
              <LineChart
                width={1000}
                height={450}
                data={currentData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip />
                {time && (
                  <Line
                    type="monotone"
                    dataKey="timeLearning"
                    stroke="#FFB200"
                    activeDot={{ r: 8 }}
                  />
                )}
                {skill && (
                  <Line type="monotone" dataKey="skill" stroke="#4339F2" />
                )}
                {res && (
                  <Line
                    type="monotone"
                    dataKey="resourceCount"
                    stroke="#FF3A29"
                  />
                )}
                {gap && (
                  <Line type="monotone" dataKey="skillGap" stroke="#18A0FB" />
                )}
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <p
              style={{
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              No Data Available
            </p>
          )}
          <div className="learning-summary-grid">
            {learnGrid?.map((data, index) => {
              return (
                <LearningSummaryCards
                  mainText={data?.mainText}
                  fillColor={data?.fillColor}
                  percentage={data?.percentage}
                  subText={data?.subText}
                  skillSubText={data?.skillSubText}
                  index={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
