import React from "react";

const Preferences = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.46 10.9133L15.22 10.2467L14.5533 9.09333L13.5867 9.42C13.3733 9.24 13.1333 9.1 12.8667 9L12.6667 8H11.3333L11.1333 8.99333C10.8667 9.09333 10.6267 9.23333 10.4133 9.41333L9.44667 9.08667L8.78 10.24L9.54 10.9067C9.48667 11.24 9.48667 11.4133 9.54 11.7467L8.78 12.4133L9.44667 13.5667L10.4133 13.24C10.6267 13.42 10.8667 13.56 11.1333 13.66L11.3333 14.6667H12.6667L12.8667 13.6733C13.1333 13.5733 13.3733 13.4333 13.5867 13.2533L14.5533 13.58L15.22 12.4267L14.46 11.76C14.5133 11.42 14.5133 11.2467 14.46 10.9133ZM12 12.6667C11.2667 12.6667 10.6667 12.0667 10.6667 11.3333C10.6667 10.6 11.2667 10 12 10C12.7333 10 13.3333 10.6 13.3333 11.3333C13.3333 12.0667 12.7333 12.6667 12 12.6667ZM12.6667 2.66667V6.66667H11.3333V4H10V8H8.66667V3.33333H4.66667V12.6667H8V14H2V12.6667H3.33333V2H10V2.66667H12.6667ZM8 8.66667H6.66667V7.33333H8V8.66667Z"
        fill="#8D9091"
      />
    </svg>
  );
};

export default Preferences;
