import React from 'react'; 
const LearningPath = ({stroke, fill='none'}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M10.05 2.53028L4.03002 6.46028C2.10002 7.72028 2.10002 10.5403 4.03002 11.8003L10.05 15.7303C11.13 16.4403 12.91 16.4403 13.99 15.7303L19.98 11.8003C21.9 10.5403 21.9 7.73028 19.98 6.47028L13.99 2.54028C12.91 1.82028 11.13 1.82028 10.05 2.53028Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.63012 13.0801L5.62012 17.7701C5.62012 19.0401 6.60012 20.4001 7.80012 20.8001L10.9901 21.8601C11.5401 22.0401 12.4501 22.0401 13.0101 21.8601L16.2001 20.8001C17.4001 20.4001 18.3801 19.0401 18.3801 17.7701V13.1301" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.3999 15V9" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}

export default LearningPath;