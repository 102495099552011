import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const LearningHistory = ({
  display,
  load,
  allLearning,
  oneAss19,
  filter,
  addResourceFn,
  trunk,
  capitalize,
  setDate,
  showMainRes,
  showMainResFn,
  oneAss23,
  backFn,
  handleSkip,
  startFn,
  handleChange,
  keyUpFn,
  handleAdd,
  microSkill,
  chosenSkillFil,
  handleRemove,
  videoFn,
  video,
  tempSkillFil,
  keyword,
  article,
  articleFn,
  courseFil,
  courseFilFn,
  webinar,
  webinarFn,
  podcast,
  podcastFn,
  oneAss06,
  setValue,
  tutStep,
  ain,
  source,
}) => {
  return (
    <>
      <div className={`home-sm ${display === "dahpah" ? "" : "hide"}`}>
        <div className="card-container">
          {!load ? (
            <>
              <div className="card">
                <div className="date load flicker"></div>
                <div className="img load flicker"></div>
                <div className="band">
                  <div className="title load flicker"></div>
                  <div className="info">
                    <div className="mskill load flicker"></div>
                    <div className="mskill load flicker"></div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="date load flicker"></div>
                <div className="img load flicker"></div>
                <div className="band">
                  <div className="title load flicker"></div>
                  <div className="info">
                    <div className="mskill load flicker"></div>
                    <div className="mskill load flicker"></div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="date load flicker"></div>
                <div className="img load flicker"></div>
                <div className="band">
                  <div className="title load flicker"></div>
                  <div className="info">
                    <div className="mskill load flicker"></div>
                    <div className="mskill load flicker"></div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="date load flicker"></div>
                <div className="img load flicker"></div>
                <div className="band">
                  <div className="title load flicker"></div>
                  <div className="info">
                    <div className="mskill load flicker"></div>
                    <div className="mskill load flicker"></div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="date load flicker"></div>
                <div className="img load flicker"></div>
                <div className="band">
                  <div className="title load flicker"></div>
                  <div className="info">
                    <div className="mskill load flicker"></div>
                    <div className="mskill load flicker"></div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="date load flicker"></div>
                <div className="img load flicker"></div>
                <div className="band">
                  <div className="title load flicker"></div>
                  <div className="info">
                    <div className="mskill load flicker"></div>
                    <div className="mskill load flicker"></div>
                  </div>
                </div>
              </div>
            </>
          ) : allLearning.length === 0 ? (
            <div className="inner">
              <div className="img">
                <img src={oneAss19} alt="Trail welcome" />
              </div>
              <p>
                {filter
                  ? "Sorry, we could not find a resource that fits those filters."
                  : "We haven’t picked anything yet. You can add a resource here manually."}
              </p>
              <div className="addResource" onClick={addResourceFn}>
                <div className="iconcontainer">
                  <span></span>
                  <span></span>
                </div>
                <p>Add a course</p>
              </div>
            </div>
          ) : (
            allLearning.map((e, i) => (
              <a
                target="_blank"
                rel="noreferrer"
                href={e.link}
                className={`card ${
                  e.wishList ? (e.wishList.state ? "light" : "") : "light"
                }`}
                key={i}
              >
                <div className="img">
                  <img
                    src={
                      e.image
                        ? e.image
                        : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                            i + 1
                          )}.png`
                    }
                    alt="course-cover"
                  />
                </div>
                <div className="band">
                  <div className="title">{trunk(capitalize(e.title), 100)}</div>
                  <div className="info">
                    <div className="mskill">
                      {e.microSkill.length > 0
                        ? trunk(capitalize(e.microSkill[0]), 17)
                        : trunk(capitalize(e.offeredBy), 17)}
                    </div>
                    <div className="mskill">{setDate(e.createdAt)}</div>
                  </div>
                </div>
              </a>
            ))
          )}
        </div>
        {allLearning.length > 0 && (
          <div className="load-more" onClick={showMainResFn}>
            {showMainRes ? "Show less" : "Show more"}
          </div>
        )}
      </div>
      <div className={`one-home ${display === "dahpah" ? "" : "hide"}`}>
        <div className="main">
          <div className="progress">
            <div className="top">
              <p>Learning History</p>
              <div className="btn" onClick={showMainResFn}>
                {showMainRes ? "Hide all" : "View all"}
              </div>
            </div>
            <div className="card-container">
              {!load ? (
                <>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : allLearning.length === 0 ? (
                <div className="inner">
                  <div className="img">
                    <img src={oneAss19} alt="Trail welcome" />
                  </div>
                  <p>
                    {filter
                      ? "Sorry, we could not find a resource that fits those filters."
                      : "We haven’t picked anything yet. You can add a resource here manually."}
                  </p>
                  <div className="addResource" onClick={addResourceFn}>
                    <div className="iconcontainer">
                      <span></span>
                      <span></span>
                    </div>
                    <p>Add a course</p>
                  </div>
                </div>
              ) : (
                allLearning.map((e, i) => (
                  <div
                    className={`card ${
                      e.wishList ? (e.wishList.state ? "light" : "") : "light"
                    }`}
                    key={i}
                  >
                    <div className="img">
                      <img
                        src={
                          e.image
                            ? e.image
                            : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                i + 1
                              )}.png`
                        }
                        alt="course-cover"
                      />
                    </div>
                    <div className="band">
                      <div className="title">
                        {trunk(capitalize(e.title), 100)}
                        <div className="space"></div>
                        <a
                          className="btn"
                          target="_blank"
                          rel="noreferrer"
                          href={e.link}
                        >
                          <div className="arrow">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </a>
                      </div>
                      <div className="info">
                        <div className="mskill">
                          {e.microSkill.length > 0
                            ? trunk(capitalize(e.microSkill[0]), 17)
                            : trunk(capitalize(e.offeredBy), 17)}
                        </div>
                        <div className="date">{setDate(e.createdAt)}</div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="skill-graph">
            <div className="title">
              Trail Bank <span>(coming soon)</span>
            </div>
            <div className="container">
              <div className="img">
                <img src={oneAss23} alt="Trail Bank" />
              </div>
              <div className="content">
                <h2>Find out where your skills place you!</h2>
                <p>
                  Trail Bank stores your micro-skills and uses them to determine
                  where you stand in the job market.
                </p>
                <div className="btn">Coming soon</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`side ${tutStep === 12 ? ain[tutStep] : ""}`}>
          {tutStep === 12 && (
            <>
              <div className="highlight twelve"></div>
              <div className="inner-tut">
                <div className="back" onClick={backFn}>
                  <div className="icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  Go back
                </div>
                <h3>Searching for something? Sort through your archive</h3>
                <p>
                  Use the filter system to quickly access whatever resource it
                  is you are looking for in your learning history.
                </p>
                <div className="btn-group">
                  <div className="skip" onClick={handleSkip}>
                    Skip all
                  </div>
                  <div className="start" onClick={startFn}>
                    Next
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="filter">
            <div className="title">Filters</div>
            <div className="input">
              <label htmlFor="keyword">Keyword</label>
              <input
                type="text"
                htmlFor="keyword"
                placeholder="course title"
                value={keyword}
                name="keyword"
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <label htmlFor="microskill">Micro skill</label>
              <div className="slic">
                <input
                  type="search"
                  id="skill-option-fi"
                  list="skill-option-fill"
                  placeholder="Type in a microskill"
                  value={tempSkillFil}
                  name="tempSkillFil"
                  onChange={handleChange}
                  onKeyUp={keyUpFn}
                />
                <div className="bottom">
                  <AddOutlinedIcon onClick={handleAdd} />
                </div>

                <datalist id="skill-option-fill">
                  {microSkill.map((skill, i) => (
                    <option value={skill.name} key={i}>
                      {skill.name}
                    </option>
                  ))}
                </datalist>
              </div>
            </div>
            <div className="skills">
              {chosenSkillFil.map((el, i) => (
                <div className="item" key={i}>
                  {el}
                  <div class="cross" onClick={handleRemove.bind(this, i)}>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              ))}
            </div>
            <div className="type">
              <label>Content Type</label>
              <div className="check">
                <div className="tab">
                  <input
                    type="checkbox"
                    name="video"
                    id="video"
                    checked={video}
                    onChange={videoFn}
                  />
                  <p>Video</p>
                </div>
                <div className="tab">
                  <input
                    type="checkbox"
                    name="article"
                    id="article"
                    checked={article}
                    onChange={articleFn}
                  />
                  <p>Article</p>
                </div>
                <div className="tab">
                  <input
                    type="checkbox"
                    name="course"
                    id="course"
                    checked={courseFil}
                    onChange={courseFilFn}
                  />
                  <p>Courses</p>
                </div>
                <div className="tab">
                  <input
                    type="checkbox"
                    name="webinar"
                    id="webinar"
                    checked={webinar}
                    onChange={webinarFn}
                  />
                  <p>Webinar</p>
                </div>
                <div className="tab">
                  <input
                    type="checkbox"
                    name="podcasts"
                    id="podcasts"
                    checked={podcast}
                    onChange={podcastFn}
                  />
                  <p>Podcasts</p>
                </div>
              </div>
            </div>
            <div className="input">
              <label htmlFor="source">Source</label>
              <input
                type="text"
                htmlFor="source"
                placeholder="Youtube udemy"
                name="source"
                value={source}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="marketing-card">
            <img src={oneAss06} alt="Reach Trail" />
            <h3>Get Help</h3>
            <p>Having any issues?</p>
            <p>
              Or just want to chat with us - <span>That's totally fine.</span>
            </p>
            <a href="mailto:hello@trailng.com?subject=We%20want%20to%20hear%20from%20you!">
              <div className="btn">Contact us</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LearningHistory;
