import React, { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Dropdown from "../Dropdown/Dropdown";


const Tutorial = ({
  showAdd,
  tutStep,
  innerFn,
  error,
  title,
  time,
  inputFn,
  type,
  wishList,
  toggleOn,
  toggleOff,
  tempSkill,
  keyUp,
  microSkill,
  addFn,
  chosenSkill,
  closeFn,
  checkRem,
  reminder,
  view,
  handleForm,
  ain,
  tutorial,
  oneAss14,
  oneAss17,
  handleSkip,
  start,
  extensionLink,
  handleBack,
  startOne,
  backOne,
  startTwo,
  backTwo,
  startThree,
  backThree,
  startFour,
  startFive,
  link,
  tags,
  handleAddTagForAddResource
}) => {
  const options = tags.map(str => {
    return {
      value: str,
      label: str.charAt(0).toUpperCase() + str.slice(1)
    };
  });

  const isMulti = true;
  
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);


  return (
    <>
      <div
        className={`outer-pop ${showAdd ? "" : "hide"} ${
          tutStep === 2 || tutStep === 3 ? "tutsie" : ""
        }`}
      >
        <div className="inner" onClick={innerFn}></div>
        <div className="container addModal">
          <div className="cancel-btn" onClick={innerFn}>
            <CloseOutlinedIcon />
          </div>
          <h2>Add resource to one Trail and track your learning</h2>

          {error && <div className="error">{error}</div>}
          <div className="form-container">
            <div className="item">
              <p>Title</p>
              <input
                type="text"
                value={title}
                name="title"
                placeholder="Add a title"
                onChange={inputFn}
              />
            </div>
            <div className="item">
              <p>URL link</p>
              <input
                type="text"
                value={link}
                name="link"
                placeholder="Add a link"
                onChange={inputFn}
              />
            </div>

            <div className="item">
              <p>Time (in minutes)</p>
              <input
                type="number"
                value={time}
                name="time"
                placeholder="Add Time In Minutes"
                onChange={inputFn}
              />
            </div>

            <div className="item">
              <p>Type</p>
              <div className="input">
                <div className="up">
                  <div className="type">
                    <select
                      name="type"
                      id="type-option"
                      value={type}
                      onChange={inputFn}
                    >
                      <option value="Article">Article</option>
                      <option value="Course">Course</option>
                      <option value="Video">Video</option>
                      <option value="Webinar">Webinar</option>
                      <option value="Podcast">Podcast</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <p>Tags</p>
              <Dropdown
                isSearchable
                isMulti
                placeHolder="Select..."
                options={options}
                onChange={(value) => {
                  handleAddTagForAddResource(value?.map(option => option.value))
                }}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />  
            </div>
            <div className="item">
              <p className="quest">
                Micro skill <HelpOutlineOutlinedIcon />
              </p>
              <div className="input">
                <div className="top">
                  <input
                    type="search"
                    id="skil-search"
                    list="skill-option"
                    placeholder="Type in a Micro skill"
                    value={tempSkill}
                    name="tempSkill"
                    onChange={inputFn}
                    onKeyUp={keyUp}
                  />
                  <datalist id="skill-option">
                    {microSkill.map((skill, i) => (
                      <option value={skill.name} key={i}>
                        {skill.name}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="bottom">
                  <AddOutlinedIcon onClick={addFn} />
                </div>
              </div>
            </div>
            <div className="skillset item">
              {chosenSkill.map((el, i) => (
                <div className="skill-item" key={i}>
                  <span>{el}</span>
                  <CloseOutlinedIcon onClick={closeFn.bind(this, i)} />
                </div>
              ))}
            </div>
            <div className="reminder  item">
              <p>Click here to get a reminder</p>
              <input type="checkbox" onChange={checkRem} checked={reminder} />
            </div>

            <div className="btn">
              <div
                className={`submit ${view ? "btn-load" : ""}`}
                onClick={ () => {
                  handleForm();
                  setTimeout(() => {
                    setSelectedValue([]);
                  }, 5000);
                  
                }}
              >
                <span className="btn_text">Submit</span>
              </div>
              <div className="cancel" onClick={innerFn}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`tutorial ${ain[tutStep]} ${tutorial ? "" : "hide"}`}>
        <div className={`popup ${tutStep !== 0 ? "hide" : ""}`}>
          <div className="containing">
            <div className="img">
              <img src={oneAss14} alt="" />
            </div>
            <div className="content">
              <h1>Welcome to the organized side of learning.</h1>
              <p>
                You have a learning personal assistant waiting to be assigned to
                you. Just quickly take a look around your learning dashboard to
                get a hang of how things work for you here.
              </p>
            </div>
          </div>
          <div className="btn-groups">
            <div className="skip" onClick={handleSkip}>
              Skip all
            </div>
            <div className="start" onClick={start}>
              Start the tour
            </div>
          </div>
        </div>
        {tutStep === 17 && (
          <div className="popup">
            <div className="containing">
              <div className="img">
                <img src={oneAss17} alt="" />
              </div>
              <div className="content">
                <h1>
                  You are finally set! Start your journey of organized learning
                  now.
                </h1>
                <p>
                  You seem to know your way around your learning dashboard now,
                  it’s time to show you around the extension to see how it works
                  for you.
                </p>
              </div>
            </div>
            <div className="btn-groups">
              <div className="skip" onClick={handleSkip}>
                Cancel
              </div>
              {localStorage.getItem("trail-extension") ? (
                <a
                  className="start"
                  href="https://www.youtube.com/watch?v=k2MFJEmnrj4"
                  target="_blank"
                  rel="noreferrer"
                  onClick={extensionLink}
                >
                  Go to extension
                </a>
              ) : (
                <a
                  className="start"
                  href="https://bit.ly/3SjOoWZ"
                  target="_blank"
                  rel="noreferrer"
                  onClick={extensionLink}
                >
                  Download Extension
                </a>
              )}
            </div>
          </div>
        )}
        <div className="background-float"></div>
        {tutStep === 2 && (
          <div className="inner-tut">
            <div className="back" onClick={handleBack}>
              <div className="icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
              Go back
            </div>
            <h3>Fill up the form to add resource to your learning board</h3>
            <p>
              Fill the form and the resource will be added to your learning
              board. Don’t forget to tick the reminder button if you want to get
              weekly reminders on the resource you are learning.
            </p>
            <div className="btn-group">
              <div className="skip" onClick={handleSkip}>
                Skip all
              </div>
              <div className="start" onClick={startOne}>
                Next
              </div>
            </div>
          </div>
        )}
        {tutStep === 3 && (
          <div className="inner-tut">
            <div className="back" onClick={backOne}>
              <div className="icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
              Go back
            </div>
            <h3>Add Microskills for better analysis</h3>
            <p>
              Microskills are tiny skills gained from learning. Microskills help
              the system properly track and analyse your learning. You can add
              more than one microskill. click the button to add or press enter.
            </p>
            <div className="btn-group">
              <div className="skip" onClick={handleSkip}>
                Skip all
              </div>
              <div className="start" onClick={startTwo}>
                Next
              </div>
            </div>
          </div>
        )}
        {tutStep === 11 && (
          <div className="inner-tut">
            <div className="back" onClick={backTwo}>
              <div className="icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
              Go back
            </div>
            <h3>Go through all you have learned in the past</h3>
            <p>
              This section is the archive of all the learning resources you have
              consumed in the past, all organized and visualized in one place.
            </p>
            <div className="btn-group">
              <div className="skip" onClick={handleSkip}>
                Skip all
              </div>
              <div className="start" onClick={startThree}>
                Next
              </div>
            </div>
          </div>
        )}
        {tutStep === 13 && (
          <div className="inner-tut">
            <div className="back" onClick={backThree}>
              <div className="icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
              Go back
            </div>
            <h3>
              This section houses all the learning resources in your wishlist
            </h3>
            <p>
              When you save courses and other learning resources to your
              wishlist, you get to see them here.
            </p>
            <div className="btn-group">
              <div className="skip" onClick={handleSkip}>
                Skip all
              </div>
              <div className="start" onClick={startFour}>
                Next
              </div>
            </div>
          </div>
        )}
        {tutStep === 15 && (
          <div className="inner-tut">
            <div className="back" onClick={backOne}>
              <div className="icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
              Go back
            </div>
            <h3>Go here to personalize your dashboard</h3>
            <p>
              If you are looking to create/edit your profile, here is the place
              to go, look no further.
            </p>
            <div className="btn-group">
              <div className="skip" onClick={handleSkip}>
                Skip all
              </div>
              <div className="start" onClick={startFive}>
                Next
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Tutorial;
