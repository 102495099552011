import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { scrollToY } from '../services/scroll';

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ball: 0,
      max: 2,
      reset: '',
    };
  }

  handleSwipe = (e) => {
    clearInterval(this.state.reset);
    const ele = document.getElementById('revca');
    const widd = document.getElementById('revs').offsetWidth;
    const maxwidd = ele.scrollWidth;

    var se = setInterval(() => {
      var pos = ele.scrollLeft;
      ele.classList.remove('snap');
      if (pos === maxwidd - widd) {
        scrollToY(0, 1000, ele);
      } else {
        scrollToY(pos + widd, 1000, ele);
      }
      setTimeout(() => {
        ele.classList.add('snap');
      }, 1000);
    }, 10000);
    this.setState({
      reset: se,
    });
    setTimeout(se, 10000);
    var pos = e.target.parentNode.childNodes[1].scrollLeft;
    const wid = e.target.parentNode.childNodes[1].childNodes[0].offsetWidth;
    const el = e.target.parentNode.childNodes[1];
    const maxwid = e.target.parentNode.childNodes[1].scrollWidth;
    const { ball, max } = this.state;
    e.target.parentNode.childNodes[1].classList.remove('snap');
    if (e.target.childNodes[0].classList[1] === 'fa-chevron-left') {
      if (ball === 0) {
        scrollToY(maxwid - wid, 1000, el);
        pos = maxwid - wid;
      } else {
        scrollToY(pos - wid, 1000, el);
        pos -= wid;
      }
    } else if (e.target.childNodes[0].classList[1] === 'fa-chevron-right') {
      if (ball === max) {
        scrollToY(0, 1000, el);
        pos = 0;
      } else {
        scrollToY(pos + wid, 1000, el);
        pos += wid;
      }
    }
    if (pos > maxwid - wid) {
      pos = maxwid - wid;
    } else if (pos < 0) {
      pos = 0;
    }
    setTimeout(() => {
      e.target.parentNode.childNodes[1].classList.add('snap');
    }, 1000);
    const bal = Math.round(pos / wid);
    this.setState({
      ball: bal,
    });
  };

  handleScroll = (e) => {
    clearInterval(this.state.reset);
    const ele = document.getElementById('revca');
    const widd = document.getElementById('revs').offsetWidth;
    const maxwidd = ele.scrollWidth;

    var se = setInterval(() => {
      var pos = ele.scrollLeft;
      ele.classList.remove('snap');
      if (pos === maxwidd - widd) {
        scrollToY(0, 1000, ele);
      } else {
        scrollToY(pos + widd, 1000, ele);
      }
      setTimeout(() => {
        ele.classList.add('snap');
      }, 1000);
    }, 10000);
    this.setState({
      reset: se,
    });
    setTimeout(se, 10000);
    var pos = e.target.scrollLeft;
    const wid = e.target.childNodes[0].offsetWidth;
    const ball = Math.round(pos / wid);
    this.setState({
      ball,
    });
  };

  handleBall = (e) => {
    clearInterval(this.state.reset);
    const ele = document.getElementById('revca');
    const widd = document.getElementById('revs').offsetWidth;
    const maxwidd = ele.scrollWidth;

    var se = setInterval(() => {
      var pos = ele.scrollLeft;
      ele.classList.remove('snap');
      if (pos === maxwidd - widd) {
        scrollToY(0, 1000, ele);
      } else {
        scrollToY(pos + widd, 1000, ele);
      }
      setTimeout(() => {
        ele.classList.add('snap');
      }, 1000);
    }, 10000);
    this.setState({
      reset: se,
    });
    setTimeout(se, 10000);
    const el = e.target.parentNode.parentNode.childNodes[1];
    const wid =
      e.target.parentNode.parentNode.childNodes[1].childNodes[0].offsetWidth;
    const pos = parseInt(e.target.id.split('.')[1]) * wid;
    e.target.parentNode.parentNode.childNodes[1].classList.remove('snap');
    scrollToY(pos, 1000, el);
    setTimeout(() => {
      e.target.parentNode.parentNode.childNodes[1].classList.add('snap');
    }, 1000);
    const ball = parseInt(e.target.id.split('.')[1]);
    this.setState({
      ball,
    });
  };

  componentDidMount() {
    const el = document.getElementById('revca');
    const wid = document.getElementById('revs').offsetWidth;
    const maxwid = el.scrollWidth;

    var se = setInterval(() => {
      var pos = el.scrollLeft;
      el.classList.remove('snap');
      if (pos === maxwid - wid) {
        scrollToY(0, 1000, el);
      } else {
        scrollToY(pos + wid, 1000, el);
      }
      setTimeout(() => {
        el.classList.add('snap');
      }, 1000);
    }, 10000);
    this.setState({
      reset: se,
      max: this.props.img.length - 1,
    });
    setTimeout(se, 10000);
  }

  render() {
    const { ball } = this.state;
    const { alt, img, text, simg } = this.props;
    return (
      <div className="upcoming-info review">
        <div className="icon" onClick={this.handleSwipe}>
          <i className="fas fa-chevron-left"></i>
        </div>
        <div className="carousel snap" onScroll={this.handleScroll} id="revca">
          <div className={`container ${img[0] ? '' : 'hide'}`} id="revs">
            <div className="img">
              {alt && <div className="head">Our Mission</div>}
              <img alt="Trail Social" src={img[0]} className="bg" />
              <img alt="Trail Social" src={simg[0]} className="sm" />
            </div>
            <div className="content">
              {alt && <div className="head">Our Mission</div>}
              <p className={`${alt ? 'mar' : ''}`}>{text[0]}</p>
              {!alt && <div className="title">- {text[1]}</div>}
            </div>
          </div>
          <div className={`container ${img[1] ? '' : 'hide'}`}>
            <div className="img">
              {alt && <div className="head">Our Mission</div>}
              <img alt="Trail Social" src={img[1]} className="bg" />
              <img alt="Trail Social" src={simg[1]} className="sm" />
            </div>
            <div className="content">
              {alt && <div className="head">Our Mission</div>}
              <p className={`${alt ? 'mar' : ''}`}>{text[2]}</p>
              {!alt && <div className="title">- {text[3]}</div>}
            </div>
          </div>
          <div className={`container ${img[2] ? '' : 'hide'}`}>
            <div className="img">
              {alt && <div className="head">Our Vision</div>}
              <img alt="Trail Social" src={img[2]} className="bg" />
              <img alt="Trail Social" src={simg[2]} className="sm" />
            </div>
            <div className="content">
              {alt && <div className="head">Our Vision</div>}
              <p className={`${alt ? 'mar' : ''}`}>{text[4]}</p>
              {!alt && <div className="title">- {text[5]}</div>}
            </div>
          </div>
        </div>
        <div className="icon" onClick={this.handleSwipe}>
          <i className="fas fa-chevron-right"></i>
        </div>
        <div className="bar">
          <span
            className={`${ball === 0 ? 'fill' : ''} ${img[0] ? '' : 'hide'}`}
            id="ball.0"
            onClick={this.handleBall}
          ></span>
          <span
            className={`${ball === 1 ? 'fill' : ''} ${img[1] ? '' : 'hide'}`}
            id="ball.1"
            onClick={this.handleBall}
          ></span>
          <span
            className={`${ball === 2 ? 'fill' : ''} ${img[2] ? '' : 'hide'}`}
            id="ball.2"
            onClick={this.handleBall}
          ></span>
        </div>
      </div>
    );
  }
}

export default Reviews;
