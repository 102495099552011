import React from "react";

const ArchiveIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67106 8.63704C8.49024 8.63704 8.31682 8.70887 8.18896 8.83673C8.0611 8.96459 7.98927 9.13801 7.98927 9.31883C7.98927 9.49965 8.0611 9.67307 8.18896 9.80093C8.31682 9.92879 8.49024 10.0006 8.67106 10.0006H12.7618C12.9427 10.0006 13.1161 9.92879 13.2439 9.80093C13.3718 9.67307 13.4436 9.49965 13.4436 9.31883C13.4436 9.13801 13.3718 8.96459 13.2439 8.83673C13.1161 8.70887 12.9427 8.63704 12.7618 8.63704H8.67106ZM0.716797 2.04636C0.716797 1.50389 0.932292 0.983639 1.31588 0.600055C1.69946 0.216472 2.21971 0.000976563 2.76218 0.000976562H18.6707C19.2132 0.000976562 19.7334 0.216472 20.117 0.600055C20.5006 0.983639 20.7161 1.50389 20.7161 2.04636V3.86448C20.7161 4.57354 20.3552 5.19807 19.807 5.56533V15.6822C19.807 16.8275 19.3521 17.9258 18.5423 18.7356C17.7325 19.5453 16.6342 20.0003 15.489 20.0003H5.94389C4.79867 20.0003 3.70037 19.5453 2.89058 18.7356C2.08079 17.9258 1.62586 16.8275 1.62586 15.6822V5.56533C1.34603 5.37856 1.11665 5.12559 0.95807 4.82888C0.799488 4.53217 0.716612 4.2009 0.716797 3.86448V2.04636ZM2.98944 5.90986V15.6822C2.98944 16.4658 3.30072 17.2173 3.85478 17.7714C4.40885 18.3254 5.16032 18.6367 5.94389 18.6367H15.489C16.2726 18.6367 17.024 18.3254 17.5781 17.7714C18.1322 17.2173 18.4434 16.4658 18.4434 15.6822V5.90986H2.98944ZM2.76218 1.36457C2.58136 1.36457 2.40794 1.4364 2.28008 1.56426C2.15222 1.69212 2.08039 1.86554 2.08039 2.04636V3.86448C2.08039 4.24083 2.38583 4.54627 2.76218 4.54627H18.6707C18.8515 4.54627 19.025 4.47444 19.1528 4.34658C19.2807 4.21872 19.3525 4.0453 19.3525 3.86448V2.04636C19.3525 1.86554 19.2807 1.69212 19.1528 1.56426C19.025 1.4364 18.8515 1.36457 18.6707 1.36457H2.76218Z"
        fill="#D0D0D0"
      />
    </svg>
  );
};

export default ArchiveIcon;
