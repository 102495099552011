import React, { useState, useEffect } from "react";

import "./styles/dash-profile.css";

import BackIcon from "../Dashboard_v1_business/assets/BackIcon";

import { ArrowRight } from "@mui/icons-material";
import Shield from "./assets/Shield";
import AboutUs from "./assets/AboutUs";
import Billing from "./assets/Billing";
import Preferences from "./assets/Preferences";
import HelpAndSupport from "./assets/HelpAndSupport";
import Modal from "../Modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../store/actions/users";



const EmployeeDetail = ({ profilePic, name, role }) => {
  return (
    <div className="employeeDetails">
      <img className="employeeImage" src={profilePic} />
      <div>
        <p className="employeeName">{name}</p>
        <p className="employeeRole">{role}</p>
      </div>
    </div>
  );
};

const Profile = ({
  display,
  imgStr,
  onChange,
  onClick,
  currentUser,
  username,
  bio,
  tag,
  email,
  view,
  handleSubmit,
  logout,
  tutStep = { tutStep },
  tourFn,
  backFn,
  handleSkip,
  startFn,
  backHome,
  updateUser,
  reminder
}) => {
  const [name, setName] = useState(currentUser?.user?.firstName);

  const [phoneNumber, setPhoneNumber] = useState(currentUser?.user?.phoneNo);

  const [occupation, setOccupation] = useState(tag);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch =  useDispatch();

  useEffect(() => {
    if (
      currentUser?.user.hasOwnProperty("tag") &&
      reminder && reminder !== 'null' && reminder !== 'undefined'
    ) {
      setName(currentUser?.user?.firstName);
      setPhoneNumber(currentUser?.user?.phoneNo);
      setOccupation(currentUser?.user?.tag);
      setHowRemindersParagraph([
        {
          id: 1,
          text: "Dashboard",
          checked: reminder?.dashboard ,
        },
        {
          id: 2,
          text: "Email",
          checked: reminder?.email,
        },
        {
          id: 3,
          text: "Whatsapp",
          checked: reminder?.whatsapp ,
        },
        {
          id: 4,
          text: "Google Calendar",
          checked: reminder?.google,
        },
      ])
      
      setTimeout(setLoading(true), 3000);
    }
  }, [currentUser, reminder]);

  const settingsData = [
    {
      id: crypto.randomUUID(),
      mainText: "Your Profile",
      subtext: "Details about your company information",
      svg: <Shield />,
    },

    {
      id: crypto.randomUUID(),
      mainText: "Preferences",
      subtext: "Details about your company information",
      svg: <Preferences />,
    },

    {
      id: crypto.randomUUID(),
      mainText: "Billing",
      subtext: "Details about your company information",
      svg: <Billing />,
    },
    {
      id: crypto.randomUUID(),
      mainText: "Help And Support",
      subtext: "Details about your company information",
      svg: <HelpAndSupport />,
    },
    {
      id: crypto.randomUUID(),
      mainText: "About Us",
      subtext: "Details about your company information",
      svg: <AboutUs />,
    },
  ];

  const [menuIdToBeDisplayed, setMenuIdToBeDisplayed] = useState(null);

  const findIdAndPosition = (id) => {
    for (let i = 0; i < settingsData.length; i++) {
      if (settingsData[i].id === id) {
        return { position: i, found: true };
      }
    }
    return { found: false };
  };

  const [howRemindersParagraphs, setHowRemindersParagraph] = useState([
    {
      id: 1,
      text: "Dashboard",
      checked: reminder?.dashboard,
    },
    {
      id: 2,
      text: "Email",
      checked: reminder?.email,
    },
    {
      id: 3,
      text: "Whatsapp",
      checked: reminder?.whatsapp,
    },
    {
      id: 4,
      text: "Google Calendar",
      checked: reminder?.google,
    },
  ]);

  const handleToggle = (id) => {
    let data = howRemindersParagraphs.map((slider) =>
      slider.id === id
        ? { ...slider, checked: !slider.checked }
        : { ...slider, checked: slider.checked }
    );

    setHowRemindersParagraph(data);

    const payload = {
      reminder: {},
    };

    data.forEach((item) => {
      switch (item.text) {
        case "Dashboard":
          payload.reminder.dashboard = item.checked;
          break;
        case "Email":
          payload.reminder.email = item.checked;
          break;
        case "Whatsapp":
          payload.reminder.whatsapp = item.checked;
          break;
        case "Google Calendar":
          payload.reminder.google = item.checked;
          break;
        default:
          break;
      }
    });
    updateUser(payload, "phoneNo%20tag%20email");
  };

  if (!loading) {
    return (
      <div className={`profile-page ${display === "proff" ? "" : "hide"}`}>
          <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
      </div>
    );
  } else {
    return (
      <div className={`profile-page ${display === "proff" ? "" : "hide"}`}>
        <div className="backButton" onClick={backHome}>
          <BackIcon stroke={"#434F61"} fill={"#434F61"} />
          <p
            style={{
              color: "#434F61",
            }}
          >
            Back
          </p>
        </div>

        <EmployeeDetail
          profilePic={
            imgStr
              ? imgStr
              : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
          }
          name={
            currentUser?.user?.firstName + " " + currentUser?.user?.lastName
          }
          role={occupation}
        />

        <div className={"profile-page-body"}>
          <div className={"settingsDiv"}>
            <p className={"settingsMain"}>Settings</p>

            {settingsData?.map((data, index) => {
              return data?.mainText === "Billing" &&
                currentUser?.user?.role === "user" ? null : (
                <div
                  className={`settingMenuItem ${
                    index === menuIdToBeDisplayed ? "activeMenu" : ""
                  }`}
                  key={data?.id}
                  onClick={() => {
                    if (index === 4) {
                      window.open("https://www.trailng.com/about", "_blank");
                    } else if (index === 3) {
                      window.open("mailto:" + "support@trailng.com", "_blank");
                    } else {
                      const menuToBeDisplayed = findIdAndPosition(data?.id);
                      setMenuIdToBeDisplayed(menuToBeDisplayed?.position);
                    }
                  }}
                >
                  <div className={"settingMainDetail"}>
                    {data?.svg}

                    <div className={"settingMainText"}>
                      <p>{data?.mainText}</p>
                      <p>{data?.subtext}</p>
                    </div>
                  </div>
                  <ArrowRight />
                </div>
              );
            })}
          </div>

          {menuIdToBeDisplayed !== null ? (
            <div className={"menuItemDiv"}>
              {menuIdToBeDisplayed === 0 ? (
                <>
                  <div className={"menuItemHeader"}>
                    <div
                      className="backButton"
                      onClick={() => setMenuIdToBeDisplayed(null)}
                    >
                      <BackIcon stroke={"#18A0FB"} fill={"#18A0FB"} />
                      <p
                        style={{
                          color: "#18A0FB",
                          fontSize: "12px",
                        }}
                      >
                        Back
                      </p>
                    </div>

                    <p className="menuItem">Your Profile</p>
                  </div>

                  <div className={"editProfile"}>
                    <div className="img">
                      <img
                        src={
                          imgStr
                            ? imgStr
                            : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                        }
                        alt="Trail user"
                        className="employeeImage"
                      />

                      <p
                        onClick={() =>
                          document.querySelector(".profile-image-field").click()
                        }
                      >
                        Edit
                      </p>
                      <input
                        type="file"
                        className={`profile-image-field`}
                        accept="image/*"
                        hidden
                        onChange={({ target: { files } }) => {
                          let formData = new FormData();

                          formData.append("avatar", files[0]);

                          updateUser(formData, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          });
                        }}
                      />
                    </div>

                    <div className={`addLearningPlanModal`}>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <div className="addLearningPlanFormElements">
                          <label>Name</label>
                          <input
                            type="text"
                            placeholder="Enter Name "
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        <div className="addLearningPlanFormElements">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            placeholder="Enter Phone Number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>

                        <div className="addLearningPlanFormElements">
                          <label>Email Address</label>
                          <input
                            type="email"
                            placeholder="Enter Email Address"
                            value={email}
                            readOnly
                          />
                        </div>

                        <div className="addLearningPlanFormElements">
                          <label>Occupation</label>
                          <input
                            type="text"
                            placeholder="Enter Occupation"
                            value={occupation}
                            onChange={(e) => setOccupation(e.target.value)}
                          />
                        </div>

                        <div
                          className={`addButton`}
                          onClick={(event) => {
                            event.preventDefault();
                            handleSubmit(
                              {
                                username: name,

                                tag: occupation,
                                phoneNo: phoneNumber,
                               
                              },
                              "reminder"
                            );
                          }}
                        >
                          <button className={`${view ? "btn-load" : ""}`}>
                            {view ? "" : "Save"}
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className={`logOutDiv`}>
                      <p className={`logOutMainText`}>Logout</p>
                      <p className={`logOutSubText`}>Want to log out?</p>

                      <div className={`logOutButton`} onClick={logout}>
                        <button>Logout</button>
                      </div>
                    </div>

                    <Modal show={showDeleteModal} handleClose={setShowDeleteModal}>
                      <>
                          <div className="deleteAccountModal">

                            <p className="areYouSureTitle">Are you sure?</p>

                            <div className="areYouSureButtons">
                              <button onClick={() => setShowDeleteModal(false)}>No</button>
                              <button
                              onClick={() => {
                                dispatch(deleteUser(currentUser?.user?.id))
                              }}
                              >Yes</button>
                            </div>
                          </div>
                      </>
                    </Modal>

                    <div className={`logOutDiv`}>
                      <p className={`logOutMainText`}>Delete Account</p>
                      <p className={`logOutSubText`}>
                        Delete your account and all it's data. This action is
                        irreversible.{" "}
                      </p>

                      <div className={`logOutButton`} 
                      onClick={() => setShowDeleteModal(true)}
                      >
                        <button>Delete Account</button>
                      </div>
                    </div>
                  </div>
                </>
              ) : menuIdToBeDisplayed === 1 ? (
                <>
                  <div className={"menuItemHeader"}>
                    <div
                      className="backButton"
                      onClick={() => setMenuIdToBeDisplayed(null)}
                    >
                      <BackIcon stroke={"#18A0FB"} fill={"#18A0FB"} />
                      <p
                        style={{
                          color: "#18A0FB",
                          fontSize: "12px",
                        }}
                      >
                        Back
                      </p>
                    </div>

                    <p className="menuItem">Reminders</p>
                  </div>

                  <div className="howReminders">
                    <p className="howRemindersHeader">
                      How do you want to receive your reminders?
                    </p>

                    {howRemindersParagraphs.map((paragraph, index) => (
                      <div className="channelAndToggle" key={paragraph.id}>
                        <p>{paragraph.text}</p>

                        <label className="toggle" for="myToggle">
                          <input
                            className={`toggle__input`}
                            checked={paragraph.checked}
                            type="checkbox"
                            id="myToggle"
                          />
                          <div
                            className={`toggle__fill `}
                            onClick={() => handleToggle(paragraph.id)}
                          ></div>
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
};

export default Profile;
