import React from "react";

const TagIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.36154 5.15245C7.36154 5.73808 7.1289 6.29972 6.7148 6.71382C6.3007 7.12792 5.73905 7.36056 5.15342 7.36056C4.5678 7.36056 4.00616 7.12792 3.59205 6.71382C3.17795 6.29972 2.94531 5.73808 2.94531 5.15245C2.94531 4.56682 3.17795 4.00518 3.59205 3.59108C4.00616 3.17698 4.5678 2.94434 5.15342 2.94434C5.73905 2.94434 6.3007 3.17698 6.7148 3.59108C7.1289 4.00518 7.36154 4.56682 7.36154 5.15245ZM5.88946 5.15245C5.88946 4.95724 5.81192 4.77003 5.67388 4.63199C5.53585 4.49396 5.34863 4.41641 5.15342 4.41641C4.95822 4.41641 4.771 4.49396 4.63297 4.63199C4.49493 4.77003 4.41739 4.95724 4.41739 5.15245C4.41739 5.34766 4.49493 5.53487 4.63297 5.67291C4.771 5.81094 4.95822 5.88849 5.15342 5.88849C5.34863 5.88849 5.53585 5.81094 5.67388 5.67291C5.81192 5.53487 5.88946 5.34766 5.88946 5.15245Z"
        fill="#D0D0D0"
      />
      <path
        d="M1.47208 0H8.22301C8.6134 8.33745e-05 8.98777 0.155231 9.26377 0.431318L19.5683 10.7358C19.8443 11.0119 19.9993 11.3863 19.9993 11.7766C19.9993 12.1669 19.8443 12.5413 19.5683 12.8174L12.8174 19.5683C12.5413 19.8443 12.1669 19.9993 11.7766 19.9993C11.3863 19.9993 11.0119 19.8443 10.7358 19.5683L0.431318 9.26377C0.155231 8.98777 8.33745e-05 8.6134 0 8.22301V1.47208C0 1.08166 0.155093 0.707228 0.431161 0.431161C0.707228 0.155093 1.08166 0 1.47208 0ZM1.47208 8.22301L11.7766 18.5275L18.5275 11.7766L8.22301 1.47208H1.47208V8.22301Z"
        fill="#D0D0D0"
      />
    </svg>
  );
};

export default TagIcon;
