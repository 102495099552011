import React, { useState, useEffect } from "react";
import "./styles/dash-home.css";
import ProgressBar from "../ProgressBar";
import Profile from "./ProfileScreen";
import {
  getTopEmployeesFn,
  getAllEmployeesFn,
} from "../../store/actions/admin/employees";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../store/actions/users";
import Paginator from "../Paginator/Paginator";
import avatar from "./assets/avatar.svg";

const Employees = ({ display, currentUser }) => {
  const dispatch = useDispatch();
  const { topEmployees, allEmployees, businessUser, employeesCount } =
    useSelector((state) => ({
      topEmployees: state?.employees?.topEmployees,
      allEmployees: state?.employees?.allEmployees,
      employeesCount: state?.employees?.total,
      businessUser: state?.currentUser,
    }));
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(employeesCount);
  const [tableData, setTableData] = useState([]);

  const limit = 5;

  const ThreeDotsButton = ({ onClick }) => {
    return (
      <button className="three-dots-button" onClick={onClick}>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </button>
    );
  };

  const EmployeeCard = ({ userId, profilePic, name, role, skills, index }) => {
    return (
      <div
        className="employeeCard"
        key={index}
        onClick={() => {
          setActivePage(2);
          window.scrollTo({
            top: 0,
          });
          setEmployeeId(userId);
        }}
      >
        <div className="employeeProfile">
          <div className="employeeDetails">
            <img className="employeeImage" src={profilePic} />
            <div>
              <p className="employeeName">{name}</p>
              <p className="employeeRole">{role}</p>
            </div>
          </div>

          <ThreeDotsButton />
        </div>

        <div className="employeeSkills">
          {skills?.map((skill, index) => {
            return (
              <div key={index}>
                <div className="skillAndPercentage">
                  <p>{skill?.name}</p>
                  <p
                    style={{
                      color: `${skill?.fillColor}`,
                    }}
                  >
                    {skill?.percentage}%
                  </p>
                </div>
                <ProgressBar
                  percentage={skill?.percentage}
                  fillColor={skill?.fillColor}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const rawColumns = [
    {
      name: "First Name",
    },

    {
      name: "Last Name",
    },

    {
      name: "Team",
    },

    {
      name: "Role",
    },
  ];

  const handleSearch = (search) => {
    const page = 1;

    dispatch(
      getAllEmployeesFn(currentUser?.user?.company, limit, page, search)
    );

    setCurrPage(page);
  };

  const TableComponent = ({
    columns,
    totalPages,
    tableData,
    handlePaginationClick,
    search,
  }) => {
    const [searchText, setSearchText] = useState("");
    return (
      <div className="table-wrapper">
        {search ? (
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <input
              type="text"
              placeholder="Search for employees"
              value={searchText}
              className="table-search"
              onChange={(e) => setSearchText(e.target.value)}
            />

            <button
              style={{
                textAlign: "center",
                height: "32px",
                border: "none",
                outline: "none",
                backgroundColor: "#18a0fb",
                padding: "0 20px",
                borderRadius: "4px",
                color: "white",
                cursor: "pointer",
                marginBottom: "10px",
              }}
              onClick={() => {
                handlePaginationClick(searchText);
              }}
            >
              Enter
            </button>
          </div>
        ) : null}

        <table className="table">
          <thead>
            <tr>
              {columns?.map((column, index) => {
                return <th>{column?.name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((row, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => {
                    setActivePage(2);
                    window.scrollTo({
                      top: 0,
                    });
                    setEmployeeId(row._id);
                  }}
                >
                  <td>{row?.firstName}</td>
                  <td>{row?.lastName}</td>
                  <td>{row?.team}</td>
                  <td>{row?.tag}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Paginator
          currentPage={currPage}
          lastPage={totalPages > currPage ? totalPages : currPage}
          onPageChange={(page) => {
            console.log("Page", page);
            setCurrPage(page);
            dispatch(
              getAllEmployeesFn(businessUser?.user?.company, limit, page)
            );
          }}
        />
      </div>
    );
  };

  const [activePage, setActivePage] = useState(1);
  const [employeeId, setEmployeeId] = useState("");

  useEffect(() => {
    dispatch(getUser(currentUser?.user?.id, "company", "user"));
  }, []);

  useEffect(() => {
    if (businessUser?.user?.company) {
      dispatch(getAllEmployeesFn(businessUser?.user?.company, limit, currPage));
      dispatch(getTopEmployeesFn(businessUser?.user?.company));
    }
  }, [businessUser, dispatch]);

  useEffect(() => {
    const transformedEmployees = allEmployees?.map((obj) => {
      if (obj.hasOwnProperty("item")) {
        const { item, ...rest } = obj;
        return { ...rest, team: item.team?.name ? item.team.name : "No team" };
      } else {
        return { ...obj, team: obj?.team?.name ? obj.team.name : "No team" };
      }
    });
    setTotalPages(Math.round(employeesCount / limit));
    setTableData(transformedEmployees);
  }, [employeesCount, allEmployees]);

  return (
    <div className="one-home">
      {activePage === 1 ? (
        <div className={`dashHome ${display === "dahpah" ? "" : "hide"}`}>
          <style jsx global>
            {`
            * {
              margin: 0;
              padding: 0;
             
            }

            body {
              box-sizing: 'border-box';
              background-color: '#f4faff
            }
          `}
          </style>

          <p className="topPerforming">Top Performing Employees</p>

          {topEmployees.length > 0 ? (
            <div className="topEmployees">
              {topEmployees?.map((employee, index) => (
                <EmployeeCard
                  userId={employee.id}
                  profilePic={
                    /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/i.test(
                      employee?.picture
                    )
                      ? employee?.picture
                      : avatar
                  }
                  name={`${employee?.firstName} ${employee?.lastName}`}
                  role={employee?.team?.name ? employee.team.name : "No team"}
                  skills={[
                    {
                      name: "Skill",
                      percentage: Math.round(employee?.skill?.percent)
                        ? Math.round(employee.skill.percent)
                        : 0,
                      fillColor:
                        index === 0
                          ? "#FF3A29"
                          : index === 1
                          ? "#34B53A"
                          : "#4339F2",
                    },

                    {
                      name: "Time Learning",
                      percentage: Math.round(employee?.timeLearning?.percent)
                        ? Math.round(employee.timeLearning.percent)
                        : 0,
                      fillColor:
                        index === 0
                          ? "#FF3A29"
                          : index === 1
                          ? "#34B53A"
                          : "#4339F2",
                    },

                    {
                      name: "Skill Gap",
                      percentage: Math.round(employee?.skillGap?.percent)
                        ? Math.round(employee.skillGap.percent)
                        : 0,
                      fillColor:
                        index === 0
                          ? "#FF3A29"
                          : index === 1
                          ? "#34B53A"
                          : "#4339F2",
                    },
                  ]}
                  index={index}
                />
              ))}
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}

          <p className="topPerforming">All Employees</p>
          {tableData?.length > 0 ? (
            <div className="table-background">
              <TableComponent
                columns={rawColumns}
                tableData={tableData}
                totalPages={totalPages}
                handlePaginationClick={handleSearch}
                search={true}
              />
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </div>
      ) : display === "dahpah" && activePage === 2 ? (
        <Profile
          goBack={setActivePage}
          userId={employeeId}
          refreshId={setEmployeeId}
        />
      ) : null}
    </div>
  );
};

export default Employees;
