import "./style.css";

const CircularProgressBar = (props) => {
  let {
    size = 126,
    progress = 0,
    trackWidth = 10,
    trackColor = "transparent",
    indicatorWidth = 10,
    indicatorColor = `#34B53A`,
    indicatorCap = `round`,
    labelColor = `#333`,
  } = props;

  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <>
      <div className="svg-pi-wrapper" style={{ width: size, height: size }}>
        <svg className="svg-pi" style={{ width: size, height: size }}>
          <circle
            className="svg-pi-track"
            cx={center}
            cy={center}
            fill={trackColor}
            r={radius}
            strokeWidth={trackWidth}
          />
          <circle
            className={`svg-pi-indicator`}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>

        <div className="svg-pi-label" style={{ color: labelColor }}>
          <span className="svg-pi-label__progress">
            {`${progress > 100 ? 100 : progress}%`}
          </span>
        </div>
      </div>
    </>
  );
};

export default CircularProgressBar;
