import React, {useState, useEffect} from "react";
import BackIcon from "./assets/BackIcon";
import { ArrowRight } from "@mui/icons-material";
import Shield from "../Dashbord_v1_user/assets/Shield";
import AboutUs from "../Dashbord_v1_user/assets/AboutUs";
import Billing from "../Dashbord_v1_user/assets/Billing";
import Preferences from "../Dashbord_v1_user/assets/Preferences";
import HelpAndSupport from "../Dashbord_v1_user/assets/HelpAndSupport";
import "../Dashbord_v1_user/styles/dash-profile.css";
import Modal from "../Modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../store/actions/users";

const EmployeeDetail = ({ profilePic, name, role }) => {
  return (
    <div className="employeeDetails">
      <img className="employeeImage" src={profilePic} />
      <div>
        <p className="employeeName">{name}</p>
        <p className="employeeRole">{role}</p>
      </div>
    </div>
  );
};

const Profile = ({
  display,
  imgStr,
  onChange,
  onClick,
  currentUser,
  username,
  bio,
  tag,
  email,
  view,
  handleSubmit,
  logout,
  tutStep = { tutStep },
  tourFn,
  backFn,
  handleSkip,
  startFn,
  backHome, 
  updateUser, 
  reminder
}) => {
  const [name, setName] = useState(currentUser?.user?.firstName);

  const [phoneNumber, setPhoneNumber] = useState(currentUser?.user?.phoneNo);

  const [occupation, setOccupation] = useState(tag);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch =  useDispatch();

  useEffect(() => {
    if (
      currentUser?.user.hasOwnProperty("tag") &&
      currentUser?.user.hasOwnProperty("phoneNo") &&
      currentUser?.user.hasOwnProperty("reminder")
    ) {
      setName(currentUser?.user?.firstName);
      setPhoneNumber(currentUser?.user?.phoneNo);
      setOccupation(currentUser?.user?.tag);   
      setTimeout(setLoading(true), 3000);
    }
    setLoading(true);
  }, [currentUser]);
  const settingsData = [
    {
      id: crypto.randomUUID(),
      mainText: "Your Profile",
      subtext: "Details about your company information",
      svg: <Shield />,
    },

    // {
    //   id: crypto.randomUUID(),
    //   mainText: "Billing",
    //   subtext: "Details about your company information",
    //   svg: <Billing />,
    // },
    {
      id: crypto.randomUUID(),
      mainText: "Help And Support",
      subtext: "Details about your company information",
      svg: <HelpAndSupport />,
    },
    {
      id: crypto.randomUUID(),
      mainText: "About Us",
      subtext: "Details about your company information",
      svg: <AboutUs />,
    },
  ];

  const [menuIdToBeDisplayed, setMenuIdToBeDisplayed] = useState(null);

  const findIdAndPosition = (id) => {
    for (let i = 0; i < settingsData.length; i++) {
      if (settingsData[i].id === id) {
        return { position: i, found: true };
      }
    }
    return { found: false };
  };


  if (!loading) {
    return (
      <div className={`profile-page ${display === "proff" ? "" : "hide"}`}>
        <p>Loading</p>
      </div>
    );
  } else {
    return (
      <div className={`profile-page ${display === "proff" ? "" : "hide"}`}>
        <div className="backButton" onClick={backHome}>
          <BackIcon stroke={"#434F61"} fill={"#434F61"} />
          <p
            style={{
              color: "#434F61",
            }}
          >
            Back
          </p>
        </div>

        <EmployeeDetail
          profilePic={
            imgStr
              ? imgStr
              : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
          }
          name={
            currentUser?.user?.firstName + " " + currentUser?.user?.lastName
          }
          role={tag}
        />

        <div className={"profile-page-body"}>
          <div className={"settingsDiv"}>
            <p className={"settingsMain"}>Settings</p>

            {settingsData?.map((data, index) => {
              return data?.mainText === "Billing" &&
                currentUser?.user?.role === "user" ? null : (
                <div
                  className={`settingMenuItem ${
                    index === menuIdToBeDisplayed ? "activeMenu" : ""
                  }`}
                  key={data?.id}
                  onClick={() => {
                    if (index === 1) {
                      window.open("https://www.trailng.com/about", "_blank");
                    } else if (index === 2) {
                      window.open("mailto:" + "support@trailng.com", "_blank");
                    } else {
                      const menuToBeDisplayed = findIdAndPosition(data?.id);
                      setMenuIdToBeDisplayed(menuToBeDisplayed?.position);
                    }
                  }}
                >
                  <div className={"settingMainDetail"}>
                    {data?.svg}

                    <div className={"settingMainText"}>
                      <p>{data?.mainText}</p>
                      <p>{data?.subtext}</p>
                    </div>
                  </div>
                  <ArrowRight />
                </div>
              );
            })}
          </div>

          {menuIdToBeDisplayed !== null ? (
            <div className={"menuItemDiv"}>
              {menuIdToBeDisplayed === 0 ? (
                <>
                  <div className={"menuItemHeader"}>
                    <div
                      className="backButton"
                      onClick={() => setMenuIdToBeDisplayed(null)}
                    >
                      <BackIcon stroke={"#18A0FB"} fill={"#18A0FB"} />
                      <p
                        style={{
                          color: "#18A0FB",
                          fontSize: "12px",
                        }}
                      >
                        Back
                      </p>
                    </div>

                    <p className="menuItem">Your Profile</p>
                  </div>

                  <div className={"editProfile"}>
                    <div className="img">
                      <img
                        src={
                          imgStr
                            ? imgStr
                            : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                        }
                        alt="Trail user"
                        className="employeeImage"
                      />

                      <p
                        onClick={() =>
                          document.querySelector(".profile-image-field").click()
                        }
                      >
                        Edit
                      </p>
                      <input
                        type="file"
                        className={`profile-image-field`}
                        accept="image/*"
                        hidden
                        onChange={({ target: { files } }) => {
                          let formData = new FormData();

                          formData.append("avatar", files[0]);

                          updateUser(formData, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          });
                        }}
                      />
                    </div>

                    <div className={`addLearningPlanModal`}>
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <div className="addLearningPlanFormElements">
                          <label>Name</label>
                          <input
                            type="text"
                            placeholder="Enter Name "
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        <div className="addLearningPlanFormElements">
                          <label>Phone Number</label>
                          <input
                            type="text"
                            placeholder="Enter Phone Number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>

                        <div className="addLearningPlanFormElements">
                          <label>Email Address</label>
                          <input
                            type="email"
                            placeholder="Enter Email Address"
                            value={email}
                            readOnly
                          />
                        </div>

                        <div className="addLearningPlanFormElements">
                          <label>Occupation</label>
                          <input
                            type="text"
                            placeholder="Enter Occupation"
                            value={occupation}
                            onChange={(e) => setOccupation(e.target.value)}
                          />
                        </div>

                        <div
                          className={`addButton`}
                          onClick={(event) => {
                            event.preventDefault();
                            handleSubmit(
                              {
                                username: name,

                                tag: occupation,
                                phoneNo: phoneNumber,
                               
                              },
                              "reminder"
                            );
                          }}
                        >
                          <button className={`${view ? "btn-load" : ""}`}>
                            {view ? "" : "Save"}
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className={`logOutDiv`}>
                      <p className={`logOutMainText`}>Logout</p>
                      <p className={`logOutSubText`}>Want to log out?</p>

                      <div className={`logOutButton`} onClick={logout}>
                        <button>Logout</button>
                      </div>
                    </div>
                    <Modal show={showDeleteModal} handleClose={setShowDeleteModal}>
                      <>
                          <div className="deleteAccountModal">

                            <p className="areYouSureTitle">Are you sure?</p>

                            <div className="areYouSureButtons">
                              <button onClick={() => setShowDeleteModal(false)}>No</button>
                              <button
                              onClick={() => {
                                dispatch(deleteUser(currentUser?.user?.id))
                              }}
                              >Yes</button>
                            </div>
                          </div>
                      </>
                    </Modal>
                    <div className={`logOutDiv`}>
                      <p className={`logOutMainText`}>Delete Account</p>
                      <p className={`logOutSubText`}>
                        Delete your account and all it's data. This action is
                        irreversible.{" "}
                      </p>

                      <div className={`logOutButton`} onClick={() => setShowDeleteModal(true)}>
                        <button>Delete Account</button>
                      </div>
                    </div>
                  </div>
                </>
              ) :
               null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
};

export default Profile;
