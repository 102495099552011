import React from "react";

const Twitter = ({className, onClick}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2782_8992)">
        <path
          d="M18.375 0H5.625C2.5184 0 0 2.5184 0 5.625V18.375C0 21.4816 2.5184 24 5.625 24H18.375C21.4816 24 24 21.4816 24 18.375V5.625C24 2.5184 21.4816 0 18.375 0Z"
          fill="white"
        />
        <path
          d="M18.375 0H5.625C2.5184 0 0 2.5184 0 5.625V18.375C0 21.4816 2.5184 24 5.625 24H18.375C21.4816 24 24 21.4816 24 18.375V5.625C24 2.5184 21.4816 0 18.375 0Z"
          fill="#1D9BF0"
        />
        <path
          d="M18.7099 8.56978C18.7202 8.71856 18.7202 8.86734 18.7202 9.01753C18.7202 13.5922 15.2376 18.8683 8.86941 18.8683V18.8655C6.98814 18.8682 5.14585 18.3294 3.5625 17.3135C3.83606 17.3464 4.11094 17.3628 4.38656 17.3634C5.94578 17.3647 7.46014 16.8417 8.68631 15.8785C7.96387 15.8649 7.26376 15.6258 6.68385 15.1947C6.10394 14.7637 5.67323 14.1622 5.45194 13.4743C5.97063 13.5743 6.50537 13.5539 7.01494 13.4147C5.39981 13.0883 4.23778 11.6692 4.23778 10.0211V9.97725C4.71927 10.2454 5.25814 10.394 5.80903 10.4106C4.28784 9.39384 3.81891 7.37006 4.73747 5.78775C5.60665 6.85734 6.69112 7.73212 7.92042 8.35528C9.14972 8.97843 10.4964 9.33602 11.8729 9.40481C11.7356 8.81403 11.7559 8.19758 11.9317 7.61709C12.1074 7.03661 12.4325 6.51245 12.8744 6.09703C14.2688 4.78621 16.4619 4.85343 17.7728 6.24712C18.5481 6.09405 19.2916 5.80981 19.9713 5.40665C19.7128 6.20827 19.172 6.88872 18.4493 7.3214C19.1357 7.24054 19.8059 7.05683 20.4375 6.77643C19.9729 7.47224 19.3878 8.07948 18.7099 8.56978Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2782_8992">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter;
