import React, { Component } from "react";
import NewLanding from "./NewLanding";
import NewDashboard from "./Dashbord_v1_user/NewDashboard";
import NewBusinessDashboard from "./Dashboard_v1_business/NewDashboard";
import { configureStore } from "../store";
import { setAuthorizationToken, setCurrentUser } from "../store/actions/auth";
import jwtDecode from "jwt-decode";
import EnterpriseLanding from "./Enterprise/EnterpriseLandingPage";
import OnboardingUser from "./Dashbord_v1_user/Onboard";
import Onboarding from "./Dashboard_v1_business/Onboard";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyOnBoarded: this.props.currentUser.user.companyOnBoarded
        ? this.props.currentUser.user.companyOnBoarded
        : false,
      load: false,
    };
  }

  componentDidMount() {
    if (!this.props.currentUser.isAuthenticated) {
      const query = this.props.location.search;
      if (query.includes("code=")) {
        this.props
          .onAuth(query)
          .then(() => {})
          .catch(() => {
            this.props.history.push("/signin");
            return;
          });
      } else if (query.includes("refCode=")) {
        localStorage.setItem(
          "referral",
          this.props.location.search.split("=")[1]
        );
      } else if (query.includes("token=")) {
        const store = configureStore();
        const token = this.props.location.search.split("=")[1];
        try {
          const decode = jwtDecode(token);
          localStorage.setItem("jwtToken", token);
          setAuthorizationToken(token);
          if (decode.exp * 1000 < Date.now()) {
            localStorage.clear();
            setAuthorizationToken(false);
            store.dispatch(setCurrentUser({}));
            window.location.href = "/signin";
          } else {
            store.dispatch(setCurrentUser({ ...jwtDecode(token) }));
            window.location.href = "/";
          }
        } catch (e) {
          store.dispatch(setCurrentUser({}));
        }
      }
    } else {
      this.props
        .fetchUser(this.props.currentUser.user.id, "companyOnBoarded", "user")
        .then((result) => {
          this.setState({
            companyOnBoarded: result.companyOnBoarded,
            load: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    const { currentUser } = this.props;
    if (!currentUser.isAuthenticated) {
      return <EnterpriseLanding {...this.props} />;
    } else if (this.state.load) {
      if (currentUser.user.role === "user") {
        if (currentUser.user.onBoarded) {
          return (
            <div>
              <NewDashboard currentUser={currentUser} {...this.props} />
            </div>
          );
        } else {
          return (
            <div>
              <OnboardingUser currentUser={currentUser} {...this.props} />
            </div>
          );
        }
      } else {
        if (this.state.companyOnBoarded || currentUser.user.companyOnBoarded) {
          return (
            <div>
              <NewBusinessDashboard currentUser={currentUser} {...this.props} />
            </div>
          );
        } else {
          return (
            <div>
              <Onboarding currentUser={currentUser} {...this.props} />
            </div>
          );
        }
      }
    } else {
      return null;
    }
  }
}

export default Homepage;
