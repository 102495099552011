import React, { useState, useEffect } from "react";
import TagIcon from "./assets/TagIcon";
import ShareIcon from "./assets/ShareIcon";
import DeleteIcon from "./assets/DeleteIcon";
import ArchiveIcon from "./assets/ArchiveIcon";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackIcon from "../Dashboard_v1_business/assets/BackIcon";

const Msg = ({ closeToast, toastProps, text }) => (
  <div>
    <p className="toastTitle">
      <strong>{text}!</strong>
    </p>
  </div>
);

const notify = ({ text }) =>
  toast(<Msg text={text} />, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    type: "success",
    limit: 1,
  });

const Home = ({
  display,
  tutStep,
  ain,
  resources,
  back,
  handleSkip,
  start,
  backOne,
  startOne,
  backTwo,
  startTwo,
  load,
  addResFn,
  trunk,
  capitalize,
  setDate,
  oneAss19,
  setValue,
  backThree,
  startThree,
  search,
  pill,
  pillFn,
  deleteResource,
  handleDeleteResource,
  tags,
  loadTags,
  handleAddTags,
  handleDeleteTag
}) => {
  const [showModal, setShowModal] = useState(false);
  const [newTag, setNewTag] = useState(" ");
  


  const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show
      ? `modal  displayBlock`
      : `modal displayNone`;

    return (
      <div className={showHideClassName}>
        <section className="modalMain">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "22px",
              borderBottom: "1px solid #ccc",
            }}
          >
            <div className="backButton" onClick={() => handleClose(false)}>
              <BackIcon stroke={"#18A0FB"} fill={"#18A0FB"} />
              <p
                style={{
                  color: "#18A0FB",
                }}
              >
                Back
              </p>
            </div>

            <p
              style={{
                fontWeight: "500",
                fontSize: "18px",
              }}
            >
              Add a section
            </p>
          </div>
          {children}
        </section>
      </div>
    );
  };

  function MyComponent({data}) {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div
            style={{ position: 'relative' }}
            className={`item tagItem ${
              pill.includes(data) ? "active" : ""
            }`}
            onClick={pillFn.bind(this, data)}
         
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered && (
              <div
         
                style={{
                  position: "absolute",
                  top: -10,
                  right: -5,
                  padding: "2px",
                  color: "#18a0fb",
                  cursor: "pointer",
                }}
                onClick={() => { 
                  handleDeleteTag([`${data}`]);
                  pillFn("all");
              
              }}
              >
                X
              </div>
            )}

            {data}
          </div>
    
    );
  }
  


  return (
    <>
      <ToastContainer />

      <Modal show={showModal} handleClose={setShowModal}>
        <>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <form
            key={"addSectionForm"}
              onSubmit={(event) => {
                event.preventDefault();
                handleAddTags(newTag);
                setNewTag("");
                setTimeout(() => {
                  setShowModal(false);
                }, 1000);
              }}
            >
              <div className="addLearningPlanFormElements">
                <label>Want to add a section?</label>
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) =>
                    setNewTag(e.target.value)
                  }
                  autoFocus="autoFocus"
                />
              </div>

              <div
                className="multipleShare"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button className="multipleShareButton">Create</button>
              </div>
            </form>
          </div>
        </>
      </Modal>
      <div className={`one-home ${display === "hom" ? "" : "hide"} `}>
        <div
          className={`progress ${search ? "hide" : ""}${
            tutStep > 3 && tutStep < 7 ? ain[tutStep] : ""
          }`}
        >
          <div className="filter-tab">
            <div
              className={`item ${pill.includes("all") ? "active" : ""}`}
              onClick={pillFn.bind(this, "all")}
            >
              All
            </div>

            {loadTags ? (
              <>
                {tags?.map((tag, index) => {
                  
                  return (
                   <MyComponent key={index} data={tag} />
                  );
                })}
              </>
            ) : null}
            <div
              className="item"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Create New Section
            </div>
          </div>
          <motion.div layout className="card-container">
            <AnimatePresence>
              {!load ? (
                <>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="date load flicker"></div>
                    <div className="img load flicker"></div>
                    <div className="band">
                      <div className="title load flicker"></div>
                      <div className="info">
                        <div className="mskill load flicker"></div>
                        <div className="mskill load flicker"></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : resources.length === 0 ? (
                <div className="inner">
                  <div className="img">
                    <img src={oneAss19} alt="Trail welcome" />
                  </div>
                  <p>
                    We haven’t picked anything yet. You can add a resource here
                    manually.
                  </p>
                  <div className="addResource" onClick={addResFn}>
                    <div className="iconcontainer">
                      <span></span>
                      <span></span>
                    </div>
                    <p>Add a course</p>
                  </div>
                </div>
              ) : (
                resources.map((e, i) => (
                  <motion.a
                    layout
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    key={i}
                    className="card"
                  >
                    <div className={"resourceCard"}>
                      <a target="_blank" rel="noreferrer" href={e.link}>
                        <div className="img">
                          <img
                            src={
                              e.image
                                ? e.image
                                : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                                    i + 1
                                  )}.png`
                            }
                            alt="course-cover"
                          />
                        </div>
                        <div className="band">
                          <div className="a-bout">
                            <div className="title">
                              {trunk(capitalize(e.title), 40)}
                            </div>
                            <div className="subtitle">
                              {trunk(capitalize(e.offeredBy), 20)}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="info">
                      <TagIcon />
                      <ShareIcon
                        className={"resourceCrud"}
                        onClick={() => {
                          navigator.clipboard.writeText(e.link);
                          notify({ text: "Resource copied successfully" });
                        }}
                      />
                      <ArchiveIcon />
                      <DeleteIcon
                        className={"resourceCrud"}
                        onClick={() => {
                          deleteResource(e._id);
                          handleDeleteResource(i);
                          notify({ text: "Resource deleted successfully" });
                        }}
                      />
                    </div>
                  </motion.a>
                ))
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Home;
