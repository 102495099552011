import { GET_ANALYTICS } from "../actionTypes";


const analytics = (
    state = {
        analytics: []
    },
    action
) => {
    switch(action.type) {
        case GET_ANALYTICS:
            return {analytics: action.analytics}
        default:
            return state
    }
}

export default analytics;