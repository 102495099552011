import React from "react";

const TrailLogo = ({className}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3 3.13672L19 19.1367M16.3333 3.13672H3V16.4701"
        stroke="white"
        strokeWidth="5.32374"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrailLogo;
