import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "./ShareLearningPage.css";
import HeroBG from "./assets/shareLearningPageHeroBG.png";
import Woman from "./assets/woman.png";
import Binoculars from "./assets/binoculars.png";
import TrailFooter from "../Enterprise/trailFooter.png";
import Instagram from "../Enterprise/instagram.png";
import Linkedin from "../Enterprise/linkedin.png";
import Twitter from "../Enterprise/twitter.png";
import TikTok from "../Enterprise/tiktok.png";
import Visualize from "./assets/visualize.png";
import VisualizeLaptop from "./assets/visualizeLaptop.png";
import VisualizePhone from "./assets/visualizeIPhone.png";
import ProgressBar from "../ProgressBar";
import ProfilePicture from "../Dashboard_v1_business/assets/ProfilePicture.png";
import { GetLearningPlan } from "../../store/actions/learning";

const ShareLearningPage = () => {
  const {learningPlan, error} = useSelector(
    (state) => ({ learningPlan: state.learning.individualLearningPlan, error: state.errors.message })
  );
  const dispatch = useDispatch();

  const userId = new URLSearchParams(window?.location?.search).get("user");
  const name = new URLSearchParams(window?.location?.search).get("name");
  const slug = window?.location?.pathname.split("/learning/")[1];

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    if (slug && userId) {
      dispatch(GetLearningPlan(slug, userId));
    }
  }, []);

  useEffect(() => {
    if(Object.keys(learningPlan).length != 0) {
        setLoading(false);
    }

    if (error) {
      setLoading(false);
      setErrorMessage(true);
    }
  }, [learningPlan, error]);

  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
  };

  const truncate = (str) => {
    return str.length > 30 ? str.substring(0, 30) + "..." : str;
  };

  return (
    <div className="shareLearningPageContainer">
      <nav className="navbar">
        <div>
          <img src={TrailFooter} alt="Logo" className="brandTitle" />
        </div>

        <button
          class={`hamburger hamburger--squeeze ${menu ? "active" : ""}`}
          type="button"
          onClick={handleMenu}
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>

        <div className={`navbarLinks ${menu ? "active" : ""}`}>
          <ul>
            <li>
              <a href="/"> Home </a>
            </li>

            <li>
              <a href="/about"> About Us</a>
            </li>

            <li>
              <a href="/signin">Login</a>
            </li>

            <button
              className="demo-button"
              onClick={() => {
                window.open("/signup", "_self");
              }}
            >
              Sign up
            </button>
          </ul>
        </div>
      </nav>

      <div className="shareLearningHero">
        <div className="shareLearningHeroText">
          <p>Get all of your learning organized and managed in one place.</p>

          <button
            className="demo-button"
            onClick={() => {
              window.open("/signup", "_self");
            }}
          >
            Sign Up For Free
          </button>
        </div>

        <div className="shareLearningHeroImage">
          <img src={HeroBG} alt="Hero background" />

          <img src={Woman} alt="Stock woman image" className="womanImage" />

          <div className="stayFocusedImage">
            <img src={Binoculars} height={"45px"} width={"80px"} />

            <p>
              <strong>Stay focused and organized!</strong>
            </p>
          </div>
        </div>
      </div>

      {Object.keys(learningPlan).length === 0 && loading? (
        <div
        style={{
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "20px"
        }}
        >
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        </div>
      ) : !loading && errorMessage ? (
        <div
        style={{
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "20px"
        }}
        >

          <p>Error: {error}</p>

          </div>
      ) : (
        <>
    
        <div
        className="shareLearningPlanTitle"
        >

          <p
          >{name? name: "" }'s Learning Plan</p>

          <button className="createYours"
              onClick={() => {
                window.open("/signin", "_self");
              }}
              >
                  Create Yours Now
              </button>

        </div>
        <div className={`learningPlanGrid individualPlan`}>
          {learningPlan?.resources.map((plan, index) => (
            <div
              className={`individualLearningPlan`}
              key={index}
              onClick={() => window.open(plan?.link, "_blank").focus()}
            >
              <div className={`courseAndView`}>
                <p>
                  {`${index + 1}.`} {truncate(plan?.title)}
                </p>

                <span className="timeSpent">{plan?.time} minutes</span>
              </div>

              <ProgressBar percentage={plan?.progress} fillColor={"#FFB200"} />

              {"user" === "user" ? null : (
                <div className="skillsDetails">
                  <img className="skillsImage" src={ProfilePicture} />
                  <div>
                    <p className="skillsName">Utiva</p>
                    <p className="skillsRole">Name of personnel</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px"
          }}
        >
              <button className="createYours"
              onClick={() => {
                window.open("/signin", "_self");
              }}
              >
                  Create Yours Now
              </button>
        </div>
        </>
      )}

      <div className="lastGrid">
        <div className="gridContent">
          <p className="gridSubtitle">
            Visualize all the things you learn in real time, from all the
            different learning sources.
          </p>
          <p className="gridDescription">
            A lot of learning goes unmanaged and unnoticed and as a result,
            unrewarded; one-trail prevents this by letting you ‘see’ your
            learning.
          </p>

          <img
            src={VisualizeLaptop}
            className="gridFullView laptop"
            alt="Visualize team growth"
          />
        </div>

        <div className="lastGridElement2">
          <img
            src={VisualizePhone}
            className="gridFullView phone"
            alt="Visualize team growth"
          />
        </div>

        <img
          src={Visualize}
          className="gridImage"
          alt="Visualize team growth"
        />
      </div>

      <div className="enterpriseFooter">
        <div className="bottomNav">
          <img src={TrailFooter} alt="logo" className="footerImg" />

          <p className="boostBusiness">
            Boost your business with One Trail: enhance employee development and
            increase revenue
          </p>

          <div className="contactUs">
            <p className="contactHeader">
              <strong>CONTACT</strong>
            </p>

            <a
              href="mailto:hello@trailng.com?subject=We%20are%20excited%20to%hear%20from%20you"
              target="_blank"
              rel="noreferrer"
            >
              <p className="email">Email: hello@trailng.com</p>
            </a>

            <a href="tel:+2348146770822">
              <p className="number">Phone: +234 814 677 0822</p>
            </a>
          </div>
        </div>

        <div className="divider"></div>

        <div className="socialsBox">
          <p className="trademark">© 2023 Trail Ltd. All Rights Reserved</p>

          <div className="socialMediaIcons">
            <a
              href="https://instagram.com/trail.ng"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Instagram} alt="Instagram logo" />
            </a>

            <a
              href="https://www.linkedin.com/company/trailng"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Linkedin} alt="Linkedin logo" />
            </a>

            <a
              href="https://www.tiktok.com/@trail.africa"
              target="_blank"
              rel="noreferrer"
            >
              <img src={TikTok} alt="TikTok logo" />
            </a>

            <a
              href="https://twitter.com/trail_ng?s=20"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Twitter} alt="Twitter logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLearningPage;
