import { apiCall } from "../../services/api";
import { GET_ANALYTICS } from "../actionTypes";
import { addError, removeError } from "./errors";


export const getAnalytics = (analytics) => ({
    type: GET_ANALYTICS,
    analytics
});


export const getAnalyticsFn = (period, frame = false) => {
    return (dispatch) => {
        return apiCall('get', `/api/v1/users/stats/${period}${frame ? '?frame=last': ''}`)
        .then(({data}) => {
            dispatch(getAnalytics(data))
            dispatch(removeError())
        })
        .catch((err) => {
            dispatch(addError(err))
        })
    }
};