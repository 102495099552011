import React, { useState, useEffect } from "react";
import ProgressBar from "../ProgressBar";
import "./styles/dash-profile.css";
import BackIcon from "./assets/BackIcon";
import ProfilePicture from "./assets/ProfilePicture.png";
import CircularProgressBar from "../CircularProgressBar";
import { getEmployeeDetailFn } from "../../store/actions/admin/employees";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

const EmployeeDetail = ({ profilePic, name, role }) => {
  return (
    <div className="employeeDetails">
      <img className="employeeImage" src={profilePic} />
      <div>
        <p className="employeeName">{name}</p>
        <p className="employeeRole">{role}</p>
      </div>
    </div>
  );
};

const skillsetDummyData = [
  {
    name: "Product Designer",
    percentage: 60,
  },
  {
    name: "Development",
    percentage: 40,
  },
  {
    name: "Soft Skill",
    percentage: 20,
  },
  {
    name: "Hard Skills",
    percentage: 80,
  },
  {
    name: "Management",
    percentage: 30,
  },
];

const Profile = ({ goBack, userId, refreshId }) => {
  const [loading, setLoading] = useState(true);
  const [jobPercent, setJobPercent] = useState([]);
  const indicatorColors = ["#34B53A", "#4339F2", "#FF3A29", "#02A0FC"];
  const trackColors = ["#E2FBD7", "#DAD7FE", "#FFE5D3", "#CCF8FE"];

  const dispatch = useDispatch();

  const { employeeDetail } = useSelector((state) => ({
    employeeDetail: state.employees.employeeDetail,
  }));

  const [containerHeight, setContainerHeight] = useState(165);
  const [dragging, setDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [stopY, setStopY] = useState(0);

  const handleMouseDown = (e) => {
    setDragging(true);
    console.log("true");
    setStartY(e.clientY);
  };

  const handleMouseUp = (e) => {
    setDragging(false);
    setStopY(e.clientY);
    console.log("false");
  };

  const handleClick = (e) => {
    if (startY === stopY) {
      setContainerHeight(containerHeight + 100);
      setStopY(e.clientY + 100);
      setStartY(e.clientY + 100);
    }
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const deltaY = e.clientY - startY;
      const add = deltaY / 20;
      console.log(add);
      const newHeight = containerHeight + add;

      if (newHeight >= 100) {
        setContainerHeight(newHeight);
      } else {
        setContainerHeight(100);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(employeeDetail).length !== 0 &&
      userId !== "" &&
      employeeDetail?.id === userId
    ) {
      const jobPercentage = employeeDetail?.jobPercent;
      for (let i = 0; i < jobPercentage.length; i++) {
        jobPercentage[i].indicatorColor =
          indicatorColors[i % indicatorColors.length];
        jobPercentage[i].trackColor = trackColors[i % trackColors.length];
      }
      setJobPercent(jobPercentage);
      setLoading(false);
    }
  }, [employeeDetail]);

  useEffect(() => {
    if (userId) {
      dispatch(getEmployeeDetailFn(userId));
    }
  }, [userId]);

  if (loading === true) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="dash-profile" onMouseUp={handleMouseUp}>
        <div
          className="backButton"
          onClick={() => {
            goBack(1);
            refreshId("");
          }}
        >
          <BackIcon />
          <p>Back</p>
        </div>
        <EmployeeDetail
          profilePic={
            /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/i.test(
              employeeDetail?.picture
            )
              ? employeeDetail?.picture
              : ProfilePicture
          }
          name={`${_.capitalize(employeeDetail?.firstName)} ${_.capitalize(
            employeeDetail?.lastName
          )}`}
          role={`${_.capitalize(employeeDetail?.tag)}`}
        />

        <div
          className="main-container"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <div className="columnLayout biz">
            <div className="currentSkillSet">
              <p className="boxHeader">Current Skill Set</p>
              {employeeDetail?.skillPercent.length > 0 ? (
                <>
                  <div
                    className="skillContainer"
                    style={{
                      overflow: "scroll",
                      height: `${containerHeight}px`,
                    }}
                    onMouseUp={handleMouseUp}
                  >
                    {employeeDetail?.skillPercent?.map((data, index) => {
                      return (
                        <div className="currentSkillAndPercentage" key={index}>
                          <p className="currentSkill">{data?.name}</p>
                          <ProgressBar
                            percentage={data?.percentage}
                            fillColor={"#18A0FB"}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className="dragIcon"
                    style={{ cursor: "ns-resize", userSelect: "none" }}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onClick={handleClick}
                  >
                    <BackIcon />
                  </div>
                  <div className="scale">
                    <span>00</span>
                    <span>01</span>
                    <span>02</span>
                    <span>03</span>
                    <span>04</span>
                    <span>05</span>
                    <span>06</span>
                    <span>07</span>
                    <span>08</span>
                    <span>09</span>
                    <span>10</span>
                  </div>
                </>
              ) : (
                <p
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  This user currently has no recorded skillset{" "}
                </p>
              )}
            </div>

            <div className="percentageOfSkillsAcquired">
              <p className="boxHeader">Percentage Of Skills Acquired</p>

              <p className="employeeSkillsGained">
                This is the percentage of skills you've acquired so far using
                this platform.
              </p>

              {jobPercent?.length > 0 ? (
                <div className="circularProgress">
                  {jobPercent?.map((job, index) => (
                    <div className="circleAndLabel" key={job?.jobId}>
                      <CircularProgressBar
                        progress={job?.value}
                        trackWidth={5}
                        indicatorWidth={10}
                        indicatorColor={job?.indicatorColor}
                        trackColor={job?.trackColor}
                      />

                      <div className="individualLegend">
                        <div
                          class="circle"
                          style={{
                            backgroundColor: "#34B53A",
                          }}
                        ></div>

                        <p>{job?.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p
                  style={{
                    marginTop: "20px",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  This user currently has no recorded skillset{" "}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
