import React from "react";

const Wishlist = ({
  display,
  tutStep,
  ain,
  handleSkip,
  startFn,
  backFn,
  showMainWish,
  showMainWishFn,
  load,
  wishlist,
  oneAss21,
  addResFn,
  trunk,
  capitalize,
  setDate,
  oneAss23,
  oneAss06,
  setValue,
}) => {
  return (
    <>
      <div className={`one-home wish ${display === "ref" ? "" : "hide"}`}>
        <div className={`main ${tutStep === 14 ? ain[tutStep] : ""}`}>
        
         </div>
         </div>
    </>
  );
};

export default Wishlist;
