import { SET_CURRENT_USER, SET_CURRENT_USER_RESOURCES , DELETE_CURRENT_USER_RESOURCES} from '../actionTypes';

const DEFAULT_STATE = {
  isAuthenticated: false, // be true, when logged in
  user: {}, // all the user information when logged in
};

const currentUser = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        isAuthenticated: !!Object.keys(action.user).length,
        user: action.user,
      };
    default:
      return state;
  }
};

const currentUserResources = (state={
  resources: []
}, action) => {
  switch(action.type) {
    case SET_CURRENT_USER_RESOURCES: 
    return {
      resources: action.resources
    };

    case DELETE_CURRENT_USER_RESOURCES:
      return {
        resources: state.resources.filter(({ _id }) => _id !== action.id)
      }

    default:
      return state;

  }
}

export { currentUser, currentUserResources };
