import React from "react";

const Shield = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.44305 1.991C4.52853 2.23685 3.61994 2.50426 2.71805 2.793C2.63643 2.81819 2.56357 2.8659 2.50785 2.93065C2.45213 2.99539 2.4158 3.07454 2.40305 3.159C1.87005 7.056 3.10005 9.9 4.56705 11.773C5.30305 12.713 6.10005 13.409 6.76405 13.866C7.09705 14.094 7.39005 14.26 7.62105 14.366C7.73705 14.419 7.83105 14.455 7.90305 14.476C7.93479 14.4862 7.9672 14.4942 8.00005 14.5C8.00705 14.499 8.03805 14.495 8.09705 14.477C8.16905 14.455 8.26305 14.419 8.37905 14.366C8.60905 14.26 8.90405 14.094 9.23605 13.866C10.0687 13.2818 10.8092 12.5763 11.433 11.773C12.9 9.9 14.13 7.056 13.597 3.159C13.5843 3.07454 13.548 2.99539 13.4922 2.93065C13.4365 2.8659 13.3637 2.81819 13.282 2.793C12.656 2.593 11.6 2.267 10.557 1.991C9.49105 1.71 8.51005 1.5 8.00005 1.5C7.49005 1.5 6.51005 1.71 5.44305 1.991ZM5.18705 1.025C6.23005 0.749 7.33705 0.5 8.00005 0.5C8.66205 0.5 9.77005 0.749 10.813 1.025C11.7433 1.27477 12.6676 1.54652 13.585 1.84C14.113 2.008 14.511 2.463 14.588 3.024C15.161 7.221 13.832 10.331 12.221 12.389C11.5345 13.2722 10.7195 14.0475 9.80305 14.689C9.48439 14.9122 9.14754 15.1082 8.79605 15.275C8.52605 15.399 8.23805 15.5 8.00005 15.5C7.76205 15.5 7.47405 15.399 7.20405 15.275C6.85248 15.1084 6.51561 14.9123 6.19705 14.689C5.28099 14.0474 4.46627 13.2721 3.78005 12.389C2.16705 10.331 0.839046 7.221 1.41205 3.024C1.45036 2.75207 1.56489 2.49655 1.7424 2.28701C1.9199 2.07748 2.15312 1.92249 2.41505 1.84C3.33249 1.54652 4.25675 1.27478 5.18705 1.025Z"
        fill="#8D9091"
      />
    </svg>
  );
};

export default Shield;
