import { apiCall } from "../../../services/api";
import {
  GET_TOP_DEPARTMENTS,
  GET_ALL_DEPARTMENTS,
  GET_DEPARTMENT_SKILLGAPS,
  GET_DEPARTMENT_EMPLOYEES,
  GET_ANALYTICS_DEPARTMENT,
} from "../../actionTypes";
import { addError, removeError } from "../errors";

export const getTopDepartments = (departments) => ({
  type: GET_TOP_DEPARTMENTS,
  departments,
});

export const getAllDepartments = (departments) => ({
  type: GET_ALL_DEPARTMENTS,
  departments,
});

export const getDepartmentSkillGaps = (gaps, total) => ({
  type: GET_DEPARTMENT_SKILLGAPS,
  gaps,
  total,
});

export const getDepartmentEmployees = (employees, total) => ({
  type: GET_DEPARTMENT_EMPLOYEES,
  employees,
  total,
});

export const getAnalyticsForDepartment = (analytics) => ({
  type: GET_ANALYTICS_DEPARTMENT,
  analytics,
});

export const getTopDepartmentsFn = (companyId) => {
  return (dispatch) => {
    return apiCall("get", `/api/v1/company/${companyId}/department/top`)
      .then(({ data }) => {
        dispatch(getTopDepartments(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getAllDepartmentsFn = (companyId) => {
  return (dispatch) => {
    return apiCall("get", `/api/v1/company/${companyId}/department`)
      .then(({ data }) => {
        dispatch(getAllDepartments(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getDepartmentSkillGapsFn = (companyId, teamId) => {
  return (dispatch) => {
    return apiCall(
      "get",
      `/api/v1/skills/gap/department/${companyId}?team=${teamId}`
    )
      .then(({ data, total }) => {
        dispatch(getDepartmentSkillGaps(data, total));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getDepartmentEmployeesFn = (
  companyId,
  teamId,
  limit,
  page,
  search
) => {
  return (dispatch) => {
    const queryParams =
      limit || page || search
        ? `?${[
            limit && `limit=${limit}`,
            page && `page=${page}`,
            search && `search=${search}`,
          ]
            .filter(Boolean)
            .join("&")}`
        : "";
    const teamQueryParam = teamId ? `&team=${teamId}` : "";
    return apiCall(
      "get",
      `/api/v1/company/${companyId}/employees${queryParams}${teamQueryParam}`
    )
      .then(({ data, total }) => {
        dispatch(getDepartmentEmployees(data, total));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getAnalyticsDepartmentFn = (
  period,
  companyId,
  teamId,
  frame = false
) => {
  return (dispatch) => {
    return apiCall(
      "get",
      `/api/v1/Company/${companyId}/stats/${period}?team=${teamId}${
        frame ? `&frame=last` : ""
      }`
    )
      .then(({ data }) => {
        dispatch(getAnalyticsForDepartment(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};
