import { apiCall } from "../../../services/api";
import {
  CREATE_COMPANY,
  ADD_EMPLOYEES,
  GET_ANALYTICS_COMPANY,
  GET_COMPANY_DETAILS,
  GET_SKILLS_AND_ROLES,
  GET_SKILLS,
} from "../../actionTypes";
import { addError, removeError } from "../errors";

export const createCompany = (company) => ({
  type: CREATE_COMPANY,
  company,
});

export const addEmployees = (employees, failed) => ({
  type: ADD_EMPLOYEES,
  employees,
  failed,
});

export const getAnalyticsForCompany = (analytics) => ({
  type: GET_ANALYTICS_COMPANY,
  analytics,
});

export const getCompanyDetails = (details) => ({
  type: GET_COMPANY_DETAILS,
  details,
});

export const getRoles = (roles) => ({
  type: GET_SKILLS_AND_ROLES,
  roles,
});

export const getSkills = (skills) => ({
  type: GET_SKILLS,
  skills,
});

export const createCompanyFn = (companyInfo) => {
  return (dispatch) => {
    return apiCall("post", "/api/v1/company", companyInfo, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(({ data }) => {
        dispatch(createCompany(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const addEmployeesFn = (companyId, employees) => {
  return (dispatch) => {
    return apiCall("post", `/api/v1/company/${companyId}/employees`, employees)
      .then(({ data, failed }) => {
        dispatch(addEmployees(data, failed));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getAnalyticsCompanyFn = (period, companyId, frame = false) => {
  return (dispatch) => {
    return apiCall(
      "get",
      `/api/v1/Company/${companyId}/stats/${period}${
        frame ? "?frame=last" : ""
      }`
    )
      .then(({ data }) => {
        dispatch(getAnalyticsForCompany(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getCompanyDetailsFn = (companyId) => {
  return (dispatch) => {
    return apiCall("get", `/api/v1/company/${companyId}`)
      .then(({ data }) => {
        dispatch(getCompanyDetails(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getRolesFn = () => {
  return (dispatch) => {
    return apiCall("get", `/api/v1/skills/role`)
      .then(({ data }) => {
        dispatch(getRoles(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};

export const getSkillsFn = (jobId) => {
  return (dispatch) => {
    return apiCall("get", `/api/v1/skills/group/role/${jobId}`)
      .then(({ data }) => {
        dispatch(getSkills(data));
        dispatch(removeError());
      })
      .catch((err) => {
        dispatch(addError(err));
      });
  };
};
