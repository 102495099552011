import React, {useEffect, useState} from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import "./styles/dash-wish.css";
import ProgressBar from "../ProgressBar";
import Clock from "../Dashboard_v1_business/assets/Clock";
import {   oneAss01,
  oneAss02,
  oneAss03,
  oneAss04, } from "../Copy/Copy";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsFn } from "../../store/actions/analytics";

const Analytics = ({
  display,
  img,
  currentUser

}) => {
  const {analytics} = useSelector((state) => ({
    analytics: state.analytics.analytics
  }));

    const data = [
    {
      label: 'Monday',
      skill: 10,
      timeLearning: 20,
      resourceCount: 50,
      skillGap: 70
   
    },
    {
      label: 'Tuesday',
      skill: 30,
      timeLearning: 15,
      resourceCount: 25,
      skillGap: 45
    },
    {
      label: 'Wednesday',
      skill: 20,
      timeLearning: 90,
      resourceCount: 100,
      skillGap: 75
    },
    {
      label: 'Thursday',
      skill: 27,
      timeLearning: 39,
      resourceCount: 20,
      skillGap: 58
    },
    {
      label: 'Friday',
      skill: 18,
      timeLearning: 48,
      resourceCount: 22,
      skillGap: 25
    },
    {
      label: 'Saturday',
      skill: 29,
      timeLearning: 38,
      resourceCount: 45,
      skillGap: 65
    },
    {
      label: 'Sunday',
      skill: 35,
      timeLearning: 43,
      resourceCount: 21,
      skillGap: 25
    },
  ];

  const [currentPeriod, setCurrentPeriod] = useState("week");

  const [currentData, setCurrentData] = useState(data)
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if(currentUser?.user?.hasOwnProperty("tag") && currentUser?.user?.firstName && currentUser?.user?.lastName) {
            
      setTimeout(setLoading(false), 3000);
    } 
  }, [currentUser]);

  useEffect(() => {
    dispatch(getAnalyticsFn(currentPeriod, true));
  }, [currentPeriod]);


  const [learningGridDummyData, setLearningGridDummyData] = useState(
    [
      {
        mainText: "Skill",
        percentage: 60,
        fillColor: "#FF3A29",
        subText: "23 skills",
        image: img,
        skill: `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
        skillSubText: `${currentUser?.user?.tag}`,
      },
  
      {
        mainText: "Skill Gap",
        percentage: 40,
        fillColor: "#FFB200",
        subText: "13 assessments",
        image: img,
        skill: `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
        skillSubText: `${currentUser?.user?.tag}`,
      },
  
      {
        mainText: "Resource Count",
        percentage: 40,
        fillColor: "#4339F2",
        subText: "100 minutes",
        image: img,
        skill: `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
        skillSubText: `${currentUser?.user?.tag}`,
      },
  
      {
        mainText: "Time Spent Learning",
        percentage: 20,
        fillColor: "#34B53A",
        subText: "70%",
        image: img,
        skill: `${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`,
        skillSubText: `${currentUser?.user?.tag}`,
      },
    ]
  );

  useEffect(() => {
    const last = analytics[analytics.length - 1];
    setCurrentData(analytics);
    const updatedData = learningGridDummyData?.map((item) => {
      // Update the values based on the corresponding object properties
      if (item.mainText === "Skill") {
        item.percentage = 
        isNaN( Math.round(
          (last?.lastPerformance?.skill.value / last?.lastPerformance?.skill.percent) * 100
        ) ) ? 0 : Math.round(
          (last?.lastPerformance?.skill.value / last?.lastPerformance?.skill.percent) * 100
        )
      } else if (item.mainText === "Skill Gap") {
        item.percentage = isNaN( Math.round(
          (last?.lastPerformance?.skillGap.value / last?.lastPerformance?.skillGap.percent) * 100
        ) ) ? 0 : Math.round(
          (last?.lastPerformance?.skillGap.value / last?.lastPerformance?.skillGap.percent) * 100
        ) ;
      } else if (item.mainText === "Resource Count") {
        item.percentage = isNaN( Math.round(
          (last?.lastPerformance?.resourceCount.value / last?.lastPerformance?.resourceCount.percent) * 100
        ) ) ? 0 : Math.round(
          (last?.lastPerformance?.resourceCount.value / last?.lastPerformance?.resourceCount.percent) * 100
        ) ;
      } else if (item.mainText === "Time Spent Learning") {
        item.percentage = isNaN( Math.round(
          (last?.lastPerformance?.timeLearning.value / last?.lastPerformance?.timeLearning.percent) * 100
        ) ) ? 0 : Math.round(
          (last?.lastPerformance?.timeLearning.value / last?.lastPerformance?.timeLearning.percent) * 100
        ) ;
      }
      
      return item;
    });
    
    setLearningGridDummyData(updatedData);
    
  }, [analytics]);



  const LearningSummaryCards = ({
    mainText,
    percentage,
    fillColor,
    image,
    skill,
    skillSubText,
    reminders = false
  }) => {
    return (
      <div className="individualBoxes">
        <div>
          <p
          className="mainText"
          >
            <strong>{mainText}</strong>
          </p>
          <p
            style={{
              color: fillColor,
            }}
          >
            {`${percentage.toString()} %` }
          </p>
        </div>

        <ProgressBar percentage={percentage} fillColor={fillColor} />
        <div
        style={{
          display: "flex",
          justifyContent: "space-between",

        }}
        >


        <div className="skillsDetails">
          <img className="skillsImage" src={image} />
          <div>
            <p className="skillsName">{skill}</p>
            <p className="skillsRole">{skillSubText}</p>
          </div>
        </div>

        {
          reminders ?
           <div>
              <Clock stroke={fillColor} />
            </div> : null
        }

        </div>
      </div>
    );
  };

  if(loading) {
    return (
      <div className={`one-home wish ${display === "ref" ? "" : "hide"}`}>
          <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
      </div>
    );
  }
  
  return (
    <>
      <div className={`one-home wish ${display === "ref" ? "" : "hide"}`}>

      <div className="learning-summary">
          <div className="learning-summary-header">
            <p>Learning Summary</p>

            <select  value={currentPeriod} onChange={(e) => {
              setCurrentPeriod(e.target.value)
              } } name="duration" id="time-periods">
              <option value='week'>This Week</option>
              <option value='month'>This Month</option>
              <option value='year'>This Year</option>
            </select>
          </div>

          <ResponsiveContainer width="100%" height={450}>
         <LineChart
          width={1000}
          height={450}
          data={currentData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip />
          
          <Line type="monotone" dataKey="timeLearning" stroke="#FFB200" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="skill" stroke="#4339F2" />
          <Line type="monotone" dataKey="resourceCount" stroke="#FF3A29" />
          <Line type="monotone" dataKey="skillGap" stroke="#18A0FB" />
          
        </LineChart>
      </ResponsiveContainer>

        <div className="learning-summary-grid">
            {learningGridDummyData?.map((data, index) => {
              return (
                <LearningSummaryCards
                  mainText={data?.mainText}
                  fillColor={data?.fillColor}
                  percentage={data?.percentage}
                  subText={data?.subText}
                  image={data?.image}
                  skill={data?.skill}
                  skillSubText={data?.skillSubText}
                  key={index}
                />
              );
            })}
          </div>
        

        </div>

      
         </div>
    </>
  );
};

export default Analytics;
