import React from 'react';
import PropTypes from 'prop-types';
import "./Paginator.css"

const Paginator = ({ currentPage, lastPage, onPageChange, className }) => {

  return (
    <div className={"table-paginator " + className}>
      {<button disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>Prev</button>}
      <React.Fragment>
        {currentPage !== 1 && <button onClick={() => onPageChange(1)}>1</button>}

        {currentPage - 3 > 1 && <p>...</p>}

        {currentPage - 2 > 1 && <button onClick={() => onPageChange(currentPage - 2)}>{currentPage - 2}</button>}
        {currentPage - 1 > 1 && <button onClick={() => onPageChange(currentPage - 1)}>{currentPage - 1}</button>}

        <button className="current">{currentPage}</button>

        {currentPage + 1 < lastPage && <button onClick={() => onPageChange(currentPage + 1)}>{currentPage + 1}</button>}
        {currentPage + 2 < lastPage && <button onClick={() => onPageChange(currentPage + 2)}>{currentPage + 2}</button>}

        {currentPage + 3 < lastPage && <p disabled>...</p>}

        {currentPage !== lastPage && <button onClick={() => onPageChange(lastPage)}>{lastPage}</button>}
      </React.Fragment>
      <button disabled={currentPage === lastPage} onClick={() => onPageChange(currentPage + 1)}>Next</button>
    </div>
  );
};

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired
};

export default Paginator;
