import React from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "./assets/SearchIcon";

const Search = ({
  display,
  tutStep,
  search,
  searchFn,
  searchQuery,
  oneAss22,
  addResourceFn,
  trunk,
  capitalize,
  setDate,
  searchStopFn,
  tutStepOne,
  skipFn,
  mobileFilter,
  mobileAddResourceFn,
  load,
  setValue,
  imgStr,
}) => {
  return (
    <div
      className={`search ${display === "hom" ? "sm-hide" : "hide"} ${
        tutStep === 1 ? "tutsie" : ""
      }`}
    >
      <div className="up">
        <input
          type="text"
          placeholder="search by name or where the resource is from"
          value={search}
          onChange={searchFn}
        />
        <div className="label">
          {search ? (
            <CloseOutlinedIcon onClick={searchStopFn} />
          ) : (
            <SearchIcon />
          )}
        </div>
        <div className={`results-tab progress ${search ? "" : "hide"}`}>
          <div className="card-container">
            {!load ? (
              <>
                <div className="card">
                  <div className="date load flicker"></div>
                  <div className="img load flicker"></div>
                  <div className="band">
                    <div className="title load flicker"></div>
                    <div className="info">
                      <div className="mskill load flicker"></div>
                      <div className="mskill load flicker"></div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="date load flicker"></div>
                  <div className="img load flicker"></div>
                  <div className="band">
                    <div className="title load flicker"></div>
                    <div className="info">
                      <div className="mskill load flicker"></div>
                      <div className="mskill load flicker"></div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="date load flicker"></div>
                  <div className="img load flicker"></div>
                  <div className="band">
                    <div className="title load flicker"></div>
                    <div className="info">
                      <div className="mskill load flicker"></div>
                      <div className="mskill load flicker"></div>
                    </div>
                  </div>
                </div>
              </>
            ) : searchQuery.length === 0 ? (
              <div className="inner">
                <div className="img">
                  <img src={oneAss22} alt="Trail welcome" />
                </div>
                <p>
                  We couldn't find a that resource. You can add a resource here
                  manually
                </p>
                <div className="addResource" onClick={addResourceFn}>
                  <div className="iconcontainer">
                    <span></span>
                    <span></span>
                  </div>
                  <p>Add a course</p>
                </div>
              </div>
            ) : (
              searchQuery.map((e, i) => (
                <div
                  className={`card ${
                    e.wishList ? (e.wishList.state ? "light" : "") : "light"
                  }`}
                  key={i}
                >
                  <div className="img">
                    <img
                      src={
                        e.image
                          ? e.image
                          : `https://trailbucket12345.s3.us-east-2.amazonaws.com/content/Content+Images-${setValue(
                              i + 1
                            )}.png`
                      }
                      alt="course-cover"
                    />
                  </div>
                  <div className="band">
                    <div className="title">
                      {trunk(capitalize(e.title), 100)}
                      <div className="space"></div>
                      <a
                        className="btn"
                        target="_blank"
                        rel="noreferrer"
                        href={e.link}
                      >
                        <div className="arrow">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </a>
                    </div>
                    <div className="info">
                      <div className="mskill">
                        {e.microSkill.length > 0
                          ? trunk(capitalize(e.microSkill[0]), 17)
                          : trunk(capitalize(e.offeredBy), 17)}
                      </div>
                      <div className="date">{setDate(e.createdAt)}</div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {tutStep === 1 && (
        <>
          <div className="highlight"></div>
          <div className="inner-tut">
            <h3>Add a learning resource manually from any of your devices</h3>
            <p>
              To add a learning resource manually from your phone, tablet or
              laptop, simply click on this button and fill up the form that pops
              up.
            </p>
            <div className="btn-group">
              <div className="skip" onClick={skipFn}>
                Skip all
              </div>
              <div className="start" onClick={tutStepOne}>
                Next
              </div>
            </div>
          </div>
        </>
      )}
      <div className="down">
        <div className="bottom">
          <div className={`back-active ${mobileFilter}`}></div>
          <div
            className="sm-filter"
            onClick={() => this.setState({ mobileFilter: "" })}
          >
            All
          </div>
          <div
            className="sm-filter"
            onClick={() => this.setState({ mobileFilter: "vid" })}
          >
            Videos
          </div>
          <div
            className="sm-filter"
            onClick={() => this.setState({ mobileFilter: "cor" })}
          >
            Courses
          </div>
          <div
            className="sm-filter"
            onClick={() => this.setState({ mobileFilter: "art" })}
          >
            Articles
          </div>
          <div className="addResource" onClick={mobileAddResourceFn}>
            <div className="iconcontainer">
              <span></span>
              <span></span>
            </div>
            <p>Add a course</p>
          </div>
        </div>
        <div className="profile-img">
          <img
            src={
              imgStr
                ? imgStr
                : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
            }
            alt="profile pic"
          />
        </div>
      </div>
      <div className="divide"></div>
    </div>
  );
};

export default Search;
