import React, {useEffect, useState} from "react";
import styles from "../Dashboard_v1_business/styles/dash-learningPlan.css";
import ProgressBar from "../ProgressBar";
import ProfilePicture from "../Dashboard_v1_business/assets/ProfilePicture.png";
import RightArrow from "../Dashboard_v1_business/assets/arrowRight.js";
import Modal from "../Modal/modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackIcon from "../Dashboard_v1_business/assets/BackIcon";
import TwitterIcon from "../Dashboard_v1_business/assets/twitter";
import FacebookIcon from "../Dashboard_v1_business/assets/facebook";
import InstagramIcon from "../Dashboard_v1_business/assets/instagram";
import InstagramIcon2 from "../Dashboard_v1_business/assets/InstagramIcon";
import Dropdown from "../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { removeError } from "../../store/actions/errors";



const Learning = ({
  display,
  AllLearningPlanData,
  GetLearningPlan,
  currentUser,
  imgStr,
  individualLearningPlanData,
  addLearningPlan
}) => {
  
  const dispatch = useDispatch();

  const {errorMessage} = useSelector((state) => ({
    errorMessage: state.errors
  }))
  const isMulti = false;
  
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);

  const options = [
    {
      label: "Medium",
      value: "medium.com"
    },
    {
      label: "Udemy",
      value: "udemy.com"
    },
    {
      label: "Udacity",
      value: "udacity.com"
    }
  ];

  useEffect(() => {
    setViewLearningPlan(false);
  }, [display])

  useEffect(() => {
    if(errorMessage?.message !== null) {
      toast.error(
        <div>
          <p> There seems to be an error : {errorMessage?.message?.error}</p>
        </div>
      );
    
      dispatch(removeError());
    } 
  }, [errorMessage]);

  const [showModal, setShowModal] = React.useState(false);
  const [shareModal, setShareModal] = React.useState(false);
  const [viewLearningPlan, setViewLearningPlan] = React.useState(false);
  const [learningPlans, setLearningPlans] = React.useState([]);
  const [sharingLink, setSharingLink] = React.useState(
   `${window.location.href}learning/${individualLearningPlanData?.slug}?user=${currentUser?.user?.id}&name=${currentUser?.user?.firstName}`
  );

  const [title, setTitle] = React.useState("");
  const [nameOfCourse, setNameOfCourse] = React.useState("");
  const [medium, setMedium] = React.useState({});
  const [linkToCourse, setLinkToCourse] = React.useState("");
  const [time, setTime] = React.useState("0");

  const truncate = (str) => {
    return str.length > 30 ? str.substring(0, 30) + "..." : str;
  };
  return (
    <div className={`plan ${display === "dahmen" ? "" : "hide"}`} id="plan">
      <ToastContainer />
      {!viewLearningPlan ? (
        <>
          <Modal show={showModal} handleClose={setShowModal}>
            <>
              <div className="addLearningPlanModal">
                <h2>Add a New Learning Plan</h2>

                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    setShowModal(false);
                  }}
                >

                  <div className="addLearningPlanFormElements">
                    <label>Title</label>
                    <input
                      type="text"
                      placeholder="Enter Title of Learning Plan"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name Of Course"
                      value={nameOfCourse}
                      onChange={(e) => setNameOfCourse(e.target.value)}
                    />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Learning Medium</label>
                    <Dropdown

                    options={options}

                    isSearchable
                    placeHolder={"Select Medium"}
                    onChange={(values) => setMedium(values)}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}

                    />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Course Link</label>
                    <input
                      type="text"
                      placeholder="Enter Course Link"
                      value={linkToCourse}
                      onChange={(e) => setLinkToCourse(e.target.value)}
                    />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Time Spent So Far</label>
                    <input
                      type="text"
                      placeholder="Enter Time Spent So Far"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </div>

                  <div
                    className="addButton"
                    onClick={(event) => {
                      event.preventDefault();

                      setLearningPlans([
                        ...learningPlans,
                        {
                          course: nameOfCourse,
                          medium: medium.value,
                          link: linkToCourse,
                          time: time
                        },
                      ]);

                      setNameOfCourse("");
                      setLinkToCourse("");
                      setMedium({});
                      setTime("0");
                      setSelectedValue(null);
                    }}
                  >
                    <button>Add</button>
                  </div>
                </form>

                {learningPlans.length != 0 ? (
                  <div className="saveLearningPlan">
                    {learningPlans.map((item, index) => (
                      <div className="addedLearningPlan" key={index}>
                        <div className="learningPlanDetails">
                          <p>
                            <strong>{item.course}</strong>
                          </p>

                          <p>{truncate(item.link)}</p>

                          <p>
                            <strong>{item.medium}</strong>
                          </p>

                          <p>
                            Time spent so far: <strong>{item.time}</strong>
                          </p>
                        </div>

                        <img src={ProfilePicture} width="32" height="32" />
                      </div>
                    ))}

                    <div
                      className="addButton"
                      onClick={(event) => {
                        event.preventDefault();
                        addLearningPlan(
                          {
                            title: title,
                            individuals: [`${currentUser?.user?.id}`],
                            resources: learningPlans?.map((plan) => {
                              return {
                                title: plan?.course,
                                link: plan?.link,
                                offeredBy: plan?.medium,
                                time: plan?.time
                              }
                            })
                          }
                        );
                        setLearningPlans([]);
                        setTitle("");
                        setShowModal(false);
                      }}
                    >
                      <button>Save</button>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          </Modal>
          <div className={`learningPlanContainer`}>
            <div className={`buttonDiv`}>
              <div></div>
              <button
                className={`createPlanButton`}
                onClick={() => setShowModal(true)}
              >
                Create New Plan
              </button>
            </div>

            <div className={`learningPlanBody`}>
              <div className="learningSummaryHeader">
                <p>Learning Plans</p>

                <select name="duration" id="time-periods">
                  <option value="volvo">This Week</option>
                  <option value="saab">This Month</option>
                  <option value="opel">6 Months</option>
                  <option value="audi">This Year</option>
                </select>
              </div>

              <div className={`learningPlanGrid`}>
                {AllLearningPlanData?.map((plan, index) => (
                  <div className={`individualLearningPlan`} key={plan?._id}>
                    <div className={`courseAndView`}>
                      <p>{plan?.title}</p>

                      <div>
                        <span
                          onClick={async () => {
                            setViewLearningPlan(true);
                            GetLearningPlan(
                              plan?._id,
                              currentUser?.user?.id
                            );
                          }}
                        >
                          View
                        </span>
                        <span>
                          <RightArrow />
                        </span>
                      </div>
                    </div>

                    <ProgressBar percentage={"50"} fillColor={"#FF3A29"} />

                    <div className="skillsDetails">
                      <img
                        className="skillsImage"
                        src={
                          imgStr
                            ? imgStr
                            : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                        }
                      />
                      <div>
                        <p className="skillsName">No Of Courses</p>
                        <p className="skillsRole">
                          {`${plan?.resources?.length} `}{" "}
                          COURSES
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal show={shareModal} handleClose={setShareModal}>
            <>
              <div className="addLearningPlanModal">
                <h2>Share Learning Plan</h2>

                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  
                  }}
                >
                  <div className="addLearningPlanFormElements">
                    <label>Email</label>
                    <input type="text" />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Message</label>
                    <textarea placeholder="Customize my reminders"></textarea>
                  </div>

                  <div className="addButton multipleShare">
                    <InstagramIcon
                      className={"sm-icon"}
                      onClick={(event) => {
                        event.preventDefault();
                        navigator.clipboard.writeText(sharingLink);
                       
                        setTimeout(() => {
                          window
                            .open("https://instagram.com/", "_blank")
                            .focus();
                        }, 2000);
                      }}
                    />
                    <FacebookIcon
                      className={"sm-icon"}
                      onClick={(event) => {
                        event.preventDefault();
                        navigator.clipboard.writeText(sharingLink);
                     
                        setTimeout(() => {
                          window
                            .open("https://facebook.com/", "_blank")
                            .focus();
                        }, 2000);
                      }}
                    />
                    <TwitterIcon
                      className={"sm-icon"}
                      onClick={(event) => {
                        event.preventDefault();
                        navigator.clipboard.writeText(sharingLink);

                        toast.success("Copied successfuly.")
                
                        setTimeout(() => {
                          window.open("https://twitter.com/", "_blank").focus();
                        }, 2000);
                      }}
                    />
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                     
                      }}
                    >
                      Share
                    </button>
                  </div>
                </form>

                <div className="sharingLinksFormsDivider">
                  <div></div>

                  <p>OR</p>
                  <div></div>
                </div>

                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  
                  }}
                >
                  <p className="personalLink">Share my personal link</p>
                  <div className="addLearningPlanFormElements">
                    <input
                      type="text"
                      value={sharingLink}
                      className="sharingLinkText"
                    />
                  </div>

                  <div
                    className="addButton"
                    onClick={(event) => {
                      event.preventDefault();
                      navigator.clipboard.writeText(sharingLink);
                      toast.success("Copied successfuly.")
                   
                    }}
                  >
                    <button>Copy</button>
                  </div>
                </form>
              </div>
            </>
          </Modal>
          <div className={`learningPlanBody`}>
            <div className="learningSummaryHeader">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  className="backButton"
                  onClick={() => { 
                    setViewLearningPlan(false)
                  }}
                >
                  <BackIcon stroke={"#434F61"} fill={"#434F61"} />
                  <p
                    style={{
                      color: "#434F61",
                    }}
                  >
                    Back
                  </p>
                </div>
                <p>{`${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`}'s' Learning Plan</p>
              </div>

              <div className="multipleShare">
                <button
                  className="multipleShareButton"
                  onClick={() => setShareModal(true)}
                >
                  Share
                </button>

                <InstagramIcon2
                  className={"sm-icon"}
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(sharingLink);
                   
                    setTimeout(() => {
                      window.open("https://instagram.com/", "_blank").focus();
                    }, 2000);
                  }}
                />
                <FacebookIcon
                  className={"sm-icon"}
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(sharingLink);
                   
                    setTimeout(() => {
                      window.open("https://facebook.com/", "_blank").focus();
                    }, 2000);
                  }}
                />
                <TwitterIcon
                  className={"sm-icon"}
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(sharingLink);
                
                    setTimeout(() => {
                      window.open("https://twitter.com/", "_blank").focus();
                    }, 2000);
                  }}
                />
              </div>
            </div>

            {Object.keys(individualLearningPlanData).length === 0 ? (
                <div style={{ 
                  textAlign: "center", 
                  marginTop: "20px", 
                  marginBottom: "20px" 
                }}>
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
            ) : (
              <div className={`learningPlanGrid individualPlan`}>
                {individualLearningPlanData?.resources.map((plan, index) => (
                  <div className={`individualLearningPlan`} key={index}
                  onClick={() => 
                    window.open(plan?.link, "_blank").focus()
                  }
                  >
                    <div className={`courseAndView`}>
                      <p>{`${index + 1}.`} {truncate(plan?.title)}</p>

                      <span className="timeSpent">{plan?.time} minutes</span>
                    </div>

                    <ProgressBar percentage={plan?.progress} fillColor={"#FFB200"} />

                    {currentUser?.user?.role === "user" ? 

                    null 
                    :
                    <div className="skillsDetails">
                      <img className="skillsImage" src={ProfilePicture} />
                      <div>
                        <p className="skillsName">Utiva</p>
                        <p className="skillsRole">Name of personnel</p>
                      </div>
                    </div>
                    }
                    
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};


const LearningPlan = ({
  display,
  GetLearningPlan,
  currentUser,
  learningPlanData,
  imgStr,
  individualLearningPlanData,
  addLearningPlanFn
}) => {
  
  return (
    <Learning display={display} AllLearningPlanData={learningPlanData} GetLearningPlan={GetLearningPlan} currentUser={currentUser} imgStr={imgStr}
    individualLearningPlanData={individualLearningPlanData}
    addLearningPlan={
      addLearningPlanFn
    }
    />
  );
};

export default LearningPlan;
