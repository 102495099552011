import React, { useState, useEffect } from "react";
import styles from "./styles/dash-learningPlan.css";
import ProgressBar from "../ProgressBar";
import ProfilePicture from "./assets/ProfilePicture.png";
import RightArrow from "./assets/arrowRight.js";
import Modal from "../Modal/modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackIcon from "./assets/BackIcon";
import TwitterIcon from "./assets/twitter";
import FacebookIcon from "./assets/facebook";
import InstagramIcon from "./assets/instagram";
import InstagramIcon2 from "./assets/InstagramIcon";
import Dropdown from "../Dropdown/Dropdown";
import {
  GetAllLearningPlan,
  GetLearningPlan,
  addLearningPlan,
} from "../../store/actions/learning";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/actions/users";
import { getAllEmployeesFn } from "../../store/actions/admin/employees";
import { getAllDepartmentsFn } from "../../store/actions/admin/departments";
import _ from "lodash";

const LearningPlan = ({ display, currentUser, imgStr }) => {
  const dispatch = useDispatch();

  const { AllLearningPlanInfo, allEmployees, businessUser , allDepartments, addPlan} = useSelector(
    (state) => ({
      AllLearningPlanInfo: state.learning.allLearningPlan,
      allEmployees: state?.employees?.learningPlanEmployees,
      businessUser: state?.currentUser,
      allDepartments: state?.departments?.allDepartments,
      addPlan: state.learning.addPlan
    })
  );

  useEffect(() => {
    dispatch(getUser(currentUser?.user?.id, "company", "user"));
  }, []);

  const [learningPlansLoading, setLearningPlansLoading] = useState(true);

  useEffect(() => {
    if(businessUser?.user?.company) {
    dispatch(GetAllLearningPlan(null, businessUser.user.company ))
    .then(() => {
      // API call is done being made
      console.log('API call completed');
      setLearningPlansLoading(false);
    })
    .catch((error) => {
      // Handle any errors that occurred during the API call
      console.error('API call error:', error);
      setLearningPlansLoading(false);
    });
    }
  }, [businessUser]);

  const [AllLearningPlanData, setAllLearningPlanData] = useState([]);

  useEffect(() => {
    setAllLearningPlanData(AllLearningPlanInfo);
  }, [AllLearningPlanInfo]);

  useEffect(() => {
    if(Object.keys(addPlan).length !== 0) {
      setAllLearningPlanData([...AllLearningPlanData, {
        title: addPlan?.title,
        _id: addPlan?._id,
        resources: addPlan?.resources
      }]);
    }
  }, [addPlan]);

  useEffect(() => {
    if (businessUser?.user?.company) {
      dispatch(getAllEmployeesFn(businessUser.user.company));
      dispatch(getAllDepartmentsFn(businessUser.user.company));
    }
  }, [businessUser, dispatch]);

  const { individualLearningPlanData } = useSelector((state) => {
    return {
      individualLearningPlanData: state.learning.individualLearningPlan,
    };
  });

  const isMulti = false;

  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);

  const options = [
    {
      label: "Medium",
      value: "medium.com",
    },
    {
      label: "Udemy",
      value: "udemy.com",
    },
    {
      label: "Udacity",
      value: "udacity.com",
    },
  ];

  const [assignedToLabel, setAssignedToLabel] = useState(isMulti ? [] : null);

  const [assignedTo, setAssignedTo] = useState({});

  const assignedToOptions = [
    {
      label: "All",
      value: "All",
    },

    {
      label: "Team",
      value: "team",
    },
    {
      label: "Individual",
      value: "individuals",
    },
  ];

  const [employeeOption, setEmployeesOption] = useState([]);

  const [deparmentOption, setDepartmentOption] = useState([]);

  useEffect(() => {
    setEmployeesOption(allEmployees?.map(({ id, firstName, lastName }) => ({ label: `${_.capitalize(firstName)} ${_.capitalize(lastName)}`, value: id }))
    );
    setDepartmentOption(allDepartments?.map(({ _id, name }) => ({ label: `${_.capitalize(name)}`, value: _id }))
    );
  }, [allEmployees, allDepartments]);


  const [selectedLearningPlanIDs, setSelectedLearningPlanIDs] = useState([]);


  const [allocatedLearningPlanIDs, setAllocatedLearningPlanIDs] = useState([]);

  useEffect(() => {
    setViewLearningPlan(false);
  }, [display]);

  const [showModal, setShowModal] = React.useState(false);
  const [shareModal, setShareModal] = React.useState(false);
  const [viewLearningPlan, setViewLearningPlan] = React.useState(false);
  const [learningPlans, setLearningPlans] = React.useState([]);
  const [sharingLink, setSharingLink] = React.useState(
    `${window.location.href}learning/${individualLearningPlanData?.slug}?user=${currentUser?.user?.id}&name=${currentUser?.user?.firstName}`
  );

  const [title, setTitle] = React.useState("");
  const [nameOfCourse, setNameOfCourse] = React.useState("");
  const [medium, setMedium] = React.useState({});
  const [linkToCourse, setLinkToCourse] = React.useState("");
  const [time, setTime] = React.useState("0");

  const truncate = (str) => {
    return str.length > 30 ? str.substring(0, 30) + "..." : str;
  };
  return (
    <div className={`plan ${display === "dahmen" ? "" : "hide"}`} id="plan">
      <ToastContainer />
      {!viewLearningPlan ? (
        <>
          <Modal show={showModal} handleClose={setShowModal}>
            <>
              <div className="addLearningPlanModal">
                <h2>Add a New Learning Plan</h2>

                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    setShowModal(false);
                  }}
                >
                  <div className="addLearningPlanFormElements">
                    <label>Title</label>
                    <input
                      type="text"
                      placeholder="Enter Title Of Learning Plan"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Name</label>
                    <input
                      type="text"
                      placeholder="Enter Name Of Course"
                      value={nameOfCourse}
                      onChange={(e) => setNameOfCourse(e.target.value)}
                    />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Learning Medium</label>
                    <Dropdown
                      options={options}
                      isSearchable
                      placeHolder={"Select Medium"}
                      onChange={(values) => setMedium(values)}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                    />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Course Link</label>
                    <input
                      type="text"
                      placeholder="Enter Course Link"
                      value={linkToCourse}
                      onChange={(e) => setLinkToCourse(e.target.value)}
                    />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Time Spent So Far</label>
                    <input
                      type="text"
                      placeholder="Enter Time Spent So Far"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Assign To</label>
                    <Dropdown
                      options={assignedToOptions}
                      isSearchable
                      placeHolder={"Who's this plan for?"}
                      onChange={(values) => setAssignedTo(values)}
                      selectedValue={assignedToLabel}
                      setSelectedValue={setAssignedToLabel}
                    />
                  </div>

                  {Object.keys(assignedTo).length !== 0 &&
                  assignedTo?.value !== "All" ? (
                    <div className="addLearningPlanFormElements">
                      <label>Enter {assignedTo.value}'s ID</label>
                      <Dropdown
                      options={assignedTo?.value === "team" ? deparmentOption : employeeOption }
                      isSearchable
                      placeHolder={`Choose relevant ${assignedTo.label}'s ID(s)`}
                      onChange={(values) => {
                        setSelectedLearningPlanIDs( values?.map(({value}) => value))
                      }
                    }
                      selectedValue={allocatedLearningPlanIDs}
                      setSelectedValue={setAllocatedLearningPlanIDs}
                      isMulti={true}
                    />
                    </div>
                  ) : null}

                  <div
                    className="addButton"
                    onClick={(event) => {
                      event.preventDefault();

                      setLearningPlans([
                        ...learningPlans,
                        {
                          course: nameOfCourse,
                          medium: medium.value,
                          link: linkToCourse,
                          time: time,
                        },
                      ]);

                      setNameOfCourse("");
                      setLinkToCourse("");
                      setMedium({});

                      setTime("0");
                      setSelectedValue(null);
                    }}
                  >
                    <button>Add</button>
                  </div>
                </form>

                {learningPlans.length !== 0 ? (
                  <div className="saveLearningPlan">
                    {learningPlans.map((item, index) => (
                      <div className="addedLearningPlan" key={index}>
                        <div className="learningPlanDetails">
                          <p>
                            <strong>{item.course}</strong>
                          </p>

                          <p>{truncate(item.link)}</p>

                          <p>
                            <strong>{item.medium}</strong>
                          </p>

                          <p>
                            Time spent so far: <strong>{item.time}</strong>
                          </p>
                        </div>

                        <img src={ProfilePicture} width="32" height="32" />
                      </div>
                    ))}

                    <div
                      className="addButton"
                      onClick={(event) => {
                        event.preventDefault();
                        dispatch(
                          addLearningPlan({
                            title: title,
                            resources: learningPlans?.map((plan) => {
                              return {
                                title: plan?.course,
                                link: plan?.link,
                                offeredBy: plan?.medium,
                                time: plan?.time,
                              };
                            }),
                            ...(Object.keys(assignedTo).length !== 0 &&
                              assignedTo?.value !== "All" && {
                                [assignedTo?.value.toLowerCase()]: selectedLearningPlanIDs,
                              }),
                          })
                        );
                        setLearningPlans([]);
                        setTitle("");
                        setShowModal(false);
                        setAssignedTo({});
                        setAssignedToLabel(null);
                      }}
                    >
                      <button>Save</button>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          </Modal>
          <div className={`learningPlanContainer`}>
            <div className={`buttonDiv`}>
              <div></div>
              <button
                className={`createPlanButton`}
                onClick={() => setShowModal(true)}
              >
                Create New Plan
              </button>
            </div>

            <div className={`learningPlanBody`}>
              <div className="learningSummaryHeader">
                <p>Learning Plans</p>

                <select name="duration" id="time-periods">
                  <option value="volvo">This Week</option>
                  <option value="saab">This Month</option>
                  <option value="opel">6 Months</option>
                  <option value="audi">This Year</option>
                </select>
              </div>

              {AllLearningPlanData?.length > 0 ? (
                <div className={`learningPlanGrid`}>
                  {AllLearningPlanData?.map((plan, index) => (
                    <div className={`individualLearningPlan`} key={plan?._id}>
                      <div className={`courseAndView`}>
                        <p>{plan?.title}</p>

                        <div>
                          <span
                            onClick={async () => {
                              setViewLearningPlan(true);
                              dispatch(
                                GetLearningPlan(
                                  plan?._id,
                                  currentUser?.user?.id
                                )
                              );
                            }}
                          >
                            View
                          </span>
                          <span>
                            <RightArrow />
                          </span>
                        </div>
                      </div>

                      <ProgressBar percentage={"50"} fillColor={"#FF3A29"} />

                      <div className="skillsDetails">
                        <img
                          className="skillsImage"
                          src={
                            imgStr
                              ? imgStr
                              : "https://pngimg.com/uploads/avatar/avatar_PNG47.png"
                          }
                        />
                        <div>
                          <p className="skillsName">No Of Courses</p>
                          <p className="skillsRole">
                            {`${plan?.resources?.length} `} COURSES
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : learningPlansLoading ? (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) :

              null
            
            
            }
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal show={shareModal} handleClose={setShareModal}>
            <>
              <div className="addLearningPlanModal">
                <h2>Share Learning Plan</h2>

                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <div className="addLearningPlanFormElements">
                    <label>Email</label>
                    <input type="text" />
                  </div>

                  <div className="addLearningPlanFormElements">
                    <label>Message</label>
                    <textarea placeholder="Customize my reminders"></textarea>
                  </div>

                  <div className="addButton multipleShare">
                    <InstagramIcon
                      className={"sm-icon"}
                      onClick={(event) => {
                        event.preventDefault();
                        navigator.clipboard.writeText(sharingLink);

                        setTimeout(() => {
                          window
                            .open("https://instagram.com/", "_blank")
                            .focus();
                        }, 2000);
                      }}
                    />
                    <FacebookIcon
                      className={"sm-icon"}
                      onClick={(event) => {
                        event.preventDefault();
                        navigator.clipboard.writeText(sharingLink);

                        setTimeout(() => {
                          window
                            .open("https://facebook.com/", "_blank")
                            .focus();
                        }, 2000);
                      }}
                    />
                    <TwitterIcon
                      className={"sm-icon"}
                      onClick={(event) => {
                        event.preventDefault();
                        navigator.clipboard.writeText(sharingLink);

                        toast.success("Copied successfuly.");

                        setTimeout(() => {
                          window.open("https://twitter.com/", "_blank").focus();
                        }, 2000);
                      }}
                    />
                    <button
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                    >
                      Share
                    </button>
                  </div>
                </form>

                <div className="sharingLinksFormsDivider">
                  <div></div>

                  <p>OR</p>
                  <div></div>
                </div>

                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <p className="personalLink">Share my personal link</p>
                  <div className="addLearningPlanFormElements">
                    <input
                      type="text"
                      value={sharingLink}
                      className="sharingLinkText"
                    />
                  </div>

                  <div
                    className="addButton"
                    onClick={(event) => {
                      event.preventDefault();
                      navigator.clipboard.writeText(sharingLink);
                      toast.success("Copied successfuly.");
                    }}
                  >
                    <button>Copy</button>
                  </div>
                </form>
              </div>
            </>
          </Modal>
          <div className={`learningPlanBody`}>
            <div className="learningSummaryHeader">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  className="backButton"
                  onClick={() => {
                    setViewLearningPlan(false);
                  }}
                >
                  <BackIcon stroke={"#434F61"} fill={"#434F61"} />
                  <p
                    style={{
                      color: "#434F61",
                    }}
                  >
                    Back
                  </p>
                </div>
                <p>
                  {`${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`}
                  's' Learning Plan
                </p>
              </div>

              <div className="multipleShare">
                <button
                  className="multipleShareButton"
                  onClick={() => setShareModal(true)}
                >
                  Share
                </button>

                <InstagramIcon2
                  className={"sm-icon"}
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(sharingLink);

                    setTimeout(() => {
                      window.open("https://instagram.com/", "_blank").focus();
                    }, 2000);
                  }}
                />
                <FacebookIcon
                  className={"sm-icon"}
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(sharingLink);

                    setTimeout(() => {
                      window.open("https://facebook.com/", "_blank").focus();
                    }, 2000);
                  }}
                />
                <TwitterIcon
                  className={"sm-icon"}
                  onClick={(event) => {
                    event.preventDefault();
                    navigator.clipboard.writeText(sharingLink);

                    setTimeout(() => {
                      window.open("https://twitter.com/", "_blank").focus();
                    }, 2000);
                  }}
                />
              </div>
            </div>

            {Object.keys(individualLearningPlanData).length === 0 ? (
              <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "20px"
              }}
              >
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              </div>
            ) : (
              <div className={`learningPlanGrid individualPlan`}>
                {individualLearningPlanData?.resources?.map((plan, index) => (
                  <div
                    className={`individualLearningPlan`}
                    key={index}
                    onClick={() => window.open(plan?.link, "_blank").focus()}
                  >
                    <div className={`courseAndView`}>
                      <p>
                        {`${index + 1}.`} {truncate(plan?.title)}
                      </p>

                      <span className="timeSpent">{plan?.time} minutes</span>
                    </div>

                    <ProgressBar
                      percentage={plan?.progress}
                      fillColor={"#FFB200"}
                    />

                    {currentUser?.user?.role === "user" ? null : (
                      <div className="skillsDetails">
                        <img className="skillsImage" src={ProfilePicture} />
                        <div>
                          <p className="skillsName">Utiva</p>
                          <p className="skillsRole">Name of personnel</p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LearningPlan;
